<template>
  <div>
    <div class="top-bg">
      <Header class="header" />
      <div class="content">
        <div class="top-text">智慧多摄</div>
        <div class="top-sub-text">
          包括双摄虚化、广角畸变矫正、光学变焦等算法SDK和解决方案。
        </div>
        <div class="top-btn">
          <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
          <el-button class="coop-btn" round @click="consultClick">合作咨询</el-button>
        </div>
      </div>
    </div>

    <div class="content item-header">
      <div class="item-title">大光圈虚化</div>
      <div class="item-text">可变光圈，支持F1.0-F16背景虚实变化调节</div>
    </div>
    <div class="content" style="margin-top: 60px">
      <img src="../../img/aiImage/phone/camera_1.png" alt="">
    </div>

    <div class="content item-header">
      <div class="item-title">拍照后对焦</div>
      <div class="item-text">可支持48个层级分割，与google photos兼容</div>
    </div>
    <div class="content" style="margin-top: 60px">
      <img src="../../img/aiImage/phone/camera_2.png" alt="">
    </div>

    <div class="content item-header">
      <div class="item-title">光学变焦</div>
      <div class="item-text">无缝软切换，便捷方案，无需标定</div>
    </div>
    <div class="content" style="margin-top: 60px">
      <img src="../../img/aiImage/phone/camera_3.png" alt="">
    </div>

    <div class="content item-header">
      <div class="item-title">梦幻光斑</div>
      <div class="item-text">多种梦幻光斑效果，手机也能拍出艺术照</div>
    </div>
    <div class="content" style="margin-top: 60px">
      <img src="../../img/aiImage/phone/camera_4.png" alt="">
    </div>

    <div class="content item-header">
      <div class="item-title">方案优势</div>
    </div>
    <div class="content clear solution" style="margin-top: 60px">
      <div class="solution-wrap">
        <div class="solution-img">
          <img class="img-top" src="../../img/aiImage/phone/camera_5.png" alt="">
          <div class="title">极速</div>
          <div style="margin-left: 82px;">
            <div class="item">
              <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
              <span class="text">Bokeh、Preview、Boken</span>
            </div>
            <div class="item">
              <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
              <span class="text">Optical zoom</span>
            </div>
            <div class="item">
              <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
              <span class="text">Refocus</span>
            </div>
            <div class="item">
              <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
              <span class="text">梦幻光斑，多光斑可选</span>
            </div>
            <div class="item">
              <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
              <span class="text">48级梯度虚化</span>
            </div>
            <div class="item">
              <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
              <span class="text">F1.0~F16光圈可调</span>
            </div>
            <div class="item">
              <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
              <span class="text">符合Google Depthmap规范，</span>
            </div>
            <div class="item" style="margin-left:26px;">
              <span class="text">兼容Googel Photos</span>
            </div>
          </div>
        </div>

          <div class="solution-img" style="margin-left:40px;">
            <img class="img-top" src="../../img/aiImage/phone/camera_5.png" alt="">
            <div class="title">易用</div>
            <div style="margin-left:82px;">
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">最低可支持MT6750和SDM450</span>
              </div>
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">支持B+B、B+M、W+T方案</span>
              </div>
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">分辨率最低8M+2M</span>
              </div>
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">硬件模组光轴旋转和偏移误差2</span>
              </div>
              <div class="item" style="margin-left:26px;">
                <span class="text">度以内</span>
              </div>
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">无支架方案3.5度以内</span>
              </div>
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">Sensor型号无要求</span>
              </div>
            </div>
          </div>
          <div class="solution-img" style="margin-left:40px;">
            <img class="img-top" src="../../img/aiImage/phone/camera_6.png" alt="">
            <div class="title">安全</div>
            <div style="margin-left: 82px;">
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">模组端标定和整机标定灵活可选</span>
              </div>
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">静态标定和动态自标定相结合，</span>
              </div>
              <div class="item" style="margin-left:26px;">
                <span class="text">自适应性更强</span>
              </div>
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">单台标定耗时10s以内，为业内</span>
              </div>
              <div class="item" style="margin-left:26px;">
                <span class="text">最快</span>
              </div>
            </div>
          </div>
      </div>
    </div>

    <div class="content item-header" style="margin-bottom: 120px;">
      <contact></contact>
    </div>
    <dialog-consult ref="consult"></dialog-consult>
    <dialog-apply ref="apply"></dialog-apply>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import contact from "./components/contact"
import DialogConsult from './components/dialogConsult.vue';
import DialogApply from './components/dialogApply'
export default {
  components: {
    Header,
    Footer,
    contact,
    DialogConsult,
    DialogApply
  },

  data() {
    return {
    }
  },

  methods: {
    consultClick () {
      this.$refs.consult.show()
    },
    applyClick () {
      this.$refs.apply.show()
    }
  },

  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
.top-bg {
  position: relative;
  background-image: url("../../img/aiImage/phone/banner_camera.jpg");
  background-position: center;
  .header {
    position: absolute;
    top: 0;
    z-index: 100;
    height: 64px;
    width: 100%;
  }
  .top-text {
    font-size: 64px;
    color: #ffffff;
    padding-top: 220px;
    padding-left: 60px;
    box-sizing: border-box;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-sub-text {
    width: 600px;
    height: 72px;
    line-height: 36px;
    margin-top: 40px;
    font-size:18px;
    color: #ffffff;
    padding-left: 60px;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-btn {
    padding-left: 60px;
    margin-top: 72px;
    .apply-btn {
      font-size: 18px;
      color: #fff;
      padding: 14px 37px;
      background-color: #007aff;
      border: none;
      border-radius: 25px;
    }
    .coop-btn {
      font-size: 18px;
      color: #0059FF;
      padding: 14px 37px;
      margin-left: 72px;
      border: none;
      border-radius: 25px;
    }
  }
}

.solution {
  .solution-wrap {
    display: flex;
    .solution-img {
      background-color: #fff;
      width: 360px;
      height: 480px;
      .img-top {
        margin: 50px 0 0 120px;
      }
      .title {
        margin-top: 30px;
        margin-bottom: 10px;
        font-size: 24px;
        color: #1F1F1F;
        letter-spacing: 0;
        text-align: center;
        font-weight: bold;
        font-family: "Microsoft YaHei","PingFangSC-Regular";
      }
      .item {
        line-height: 24px;
        height: 24px;
        vertical-align: middle;
        width: 222px;
        .check-img {
          margin-right: 10px;
          vertical-align: middle;
        }
        .text {
          font-size: 14px;
          color: #1F1F1F;
          letter-spacing: 0;
          line-height: 24px;
          vertical-align: middle;
          font-family: "Microsoft YaHei","PingFangSC-Regular";
        }
      }
    }
  }
}

</style>
