<template>
  <div>
    <div class="top-bg">
      <Header class="header" />
      <div class="content">
        <div class="top-text" style="padding-top: 180px;">奇络AI智控</div>
        <div class="top-sub-text">
          非生产性通用工业设备智能化解决方案
        </div>
        <div class="top-btn">
          <el-button class="contact-btn" round @click="contactUs">联系我们</el-button>
          <div class="btn-text">合作伙伴招募，携手掘金工业设备产业AIoT新时代</div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="part-title">奇络物联通用工业设备物联网解决方案</div>
      <div class="part-sub-title">针对非生产性通用工业设备智能化转型需求推出的一站式智能化解决方案。面向工业物联生产碎片化痛点，基于极简部署、智慧运维、安全可信的工业物联引擎，利用大数据分析和工业机理建模能力，实现“云、边、网”一站式服务与管理，帮助用户提高资源配置效率、提升运营服务水平，搭 建面向未来的智能化生产模式，助力企业加速数字化转型。</div>
    </div>
    <div class="content">
      <img src="../img/internet/zhilian_1.png" >
    </div>

    <div class="content item-header">
      <div class="reason-title">服务工业产业三大合作伙伴</div>
    </div>
    <div class="content">
      <img src="../img/internet/zhilian_2.png" >
    </div>

    <div class="content item-header">
      <div class="reason-title">中央空调物联网平台</div>
      <div class="part-sub-title">通过连接数据采集器与空调控制器，借助工业物联引擎建立设备模型和能耗模型，实现中央空调远程在线的主动式运维，<br />有效提升运营效率、降低运营成本，为行业用户提供提供一站式空调后台IT运维服务。</div>
    </div>
    <div class="content">
      <img src="../img/internet/zhilian_3.png" >
    </div>

    <div class="content item-header">
      <div class="reason-title">通用设备物联网平台</div>
      <div class="part-sub-title">通用设备物联网解决方案，针对各类中小型通用设备的数字化改造需求，通过对设备数据7*24小时实时监测，<br />实现设备远程调控、故障预测性维护等功能，降低生产和维护成本，帮助传统生产制造型企业实现智能化转型升级 。</div>
    </div>
    <div class="content">
      <img src="../img/internet/zhilian_4.png" >
    </div>
    

    <div class="content item-header">
      <div class="part-title">核心能力</div>
    </div>
    <div class="content clear" style="margin-top: 60px;margin-bottom: 60px;display: flex;">
      <div class="advantage">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../img/internet/zhilian_5.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">设备全生命周期管理</div>
            <div class="advantage-text-item">
              设备上云 / 实时监测 / 告警管理  <br />
              历史数据 / 设备机能模型 / 位置管理 <br />
              预测性维护 / 告警追溯
            </div>
          </div>
        </div>
      </div>
      <div class="advantage" style="margin-left:40px;">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../img/internet/zhilian_6.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">能耗管控</div>
            <div class="advantage-text-item">
              能耗监测 / 能耗评估 / 耗电量预测<br />
              能耗对比 / 节能模型 / 运维操作建议<br />
              节能效果对比
            </div>
          </div>
        </div>
      </div>
      <div class="advantage" style="margin-left:40px;">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../img/internet/zhilian_7.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">企业经营服务</div>
            <div class="advantage-text-item">
              客户关系管理 / 远程在线运维 / 巡检自动化<br />
              计划性保养 / 备品备件预测 / 后市场追溯
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="sence-wrap">
      <div class="content">
         <div class="part-title" style="margin: 93px 0 57px 10px;">成功案例</div>
      </div>
      <div class="content" style="margin-bottom:20px;">
        <div class="sence-title1">中央空调物联网平台</div>
      </div>
      <div class="content img-wrap">
        <div class="img marginLeft">
          <img src="../img/internet/zhilian_8.png" alt="">
          <div class="text">青岛国际会展中心</div>
        </div>
        <div class="img marginLeft">
          <img src="../img/internet/zhilian_9.png" alt="">
          <div class="text">河源电信大厦</div>
        </div>
        <div class="img marginLeft">
          <img src="../img/internet/zhilian_10.png" alt="">
          <div class="text">广州太古汇</div>
        </div>
        <div class="img marginTop marginLeft">
          <img src="../img/internet/zhilian_11.png" alt="">
          <div class="text">东莞沃尔玛</div>
        </div>
        <div class="img marginLeft marginTop">
          <img src="../img/internet/zhilian_12.png" alt="">
          <div class="text">济南银丰财富广场</div>
        </div>
        <div class="img marginLeft marginTop">
          <img src="../img/internet/zhilian_13.png" alt="">
          <div class="text">大疆天空之城</div>
        </div>
      </div>

      <div class="content" style="margin-top:40px;">
        <div class="sence-title2">通用设备物联网平台</div>
      </div>
      <div class="content img-wrap" style="margin-top:20px;">
        <div class="img marginLeft">
          <img src="../img/internet/zhilian_14.png" alt="">
          <div class="text">深圳大生体育中心</div>
        </div>
        <div class="img marginLeft">
          <img src="../img/internet/zhilian_15.png" alt="">
          <div class="text">东莞润东方工厂</div>
        </div>
        <div class="img marginLeft">
          <img src="../img/internet/zhilian_16.png" alt="">
          <div class="text">工业设备智能控制</div>
        </div>
      </div>
    </div> -->

    <dialog-consult ref="consult"></dialog-consult>
    <dialog-apply title="联系我们" type="contact" ref="apply"></dialog-apply>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import contact from "./aiImageDetail/components/contact"
import DialogConsult from './aiImageDetail/components/dialogConsult.vue';
import DialogApply from './aiImageDetail/components/dialogApply'
export default {
  components: {
    Header,
    Footer,
    contact,
    DialogConsult,
    DialogApply
  },

  data() {
    return {
      slideIndex: 1
    }
  },

  methods: {
    contactUs () {
      this.$refs.apply.show()
    }
  },

  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
.contact-btn {
  font-size: 18px;
  color: #fff;
  padding: 15px 38px;
  background-color: #007aff;
  border: none;
  border-radius: 25px;
}

.top-bg {
  position: relative;
  background-image: url("../img/internet/banner_zhilian.jpg");
  background-position: center;
  .header {
    position: absolute;
    top: 0;
    z-index: 100;
    height: 64px;
    width: 100%;
  }
  .top-text {
    font-size: 64px;
    color: #ffffff;
    padding-left: 60px;
    box-sizing: border-box;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-sub-text {
    margin-top: 48px;
    line-height: 36px;
    font-size: 24px;
    color: #ffffff;
    padding-left: 60px;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-btn {
    margin-top: 122px;
    padding-left: 60px;
  }
  .btn-text {
    font-size: 18px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 36px;
    margin-top: 36px;
  }
}

.part-title {
  font-family: "Microsoft YaHei","PingFangSC-Regular";
  font-size: 36px;
  color: #000000;
  font-weight: 700;
  margin: 133px 0 48px 0;
  text-align: left;
}
.part-sub-title {
  font-size: 16px;
  color: #1F1F1F;
  letter-spacing: 0;
  line-height: 28px;
  font-weight: 400;
  font-family: "Microsoft YaHei","PingFangSC-Regular";
  text-align: left;
  margin-bottom: 64px;
  width: 920px;
}

.advantage {
  height: 420px;
  width: 360px;
  background-color: #fff;
  .advantage-wrap {
    align-items: center;
  }
  .advantage-img {
    margin-top: 60px;
    text-align: center;
  }
  .advantage-text {
    .advantage-text-title {
      text-align: center;
      margin-top: 40px;
      margin-bottom: 23px;
      font-size: 24px;
      color: #1F1F1F;
      letter-spacing: 0;
      font-weight: bold;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
    .advantage-text-item {
      width: 288px;
      margin: 0 auto;
      font-size: 14px;
      color: #1F1F1F;
      letter-spacing: 0;
      line-height: 24px;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
      text-align: center;
    }
  }
}

.reason-title {
  font-family: "Microsoft YaHei","PingFangSC-Regular";
  font-size: 28px;
  color: #000000;
  text-align: left;
  margin-top: 64px;
  margin-bottom: 37px;
}

.reason-sub-title {
  font-size: 16px;
  color: #1F1F1F;
  letter-spacing: 0;
  line-height: 28px;
  font-weight: 400;
  font-family: "Microsoft YaHei","PingFangSC-Regular";
  text-align: left;
  margin-top: 48px;
}

.sence-wrap {
  margin-top: 120px;
  padding-bottom: 120px;
  overflow: hidden;
  background-color: #fff;
  .img-wrap {
    display: flex;
    flex-wrap: wrap;
    .img {
      position: relative;
    }
    .marginLeft {
      margin-left: 20px;
    }
    .marginTop {
      margin-top: 20px;
    }
    .text {
      position: absolute;
      bottom: 14px;
      text-align: center;
      font-size: 20px;
      color: #FFFFFF;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
      width: 100%;
    }
  }
}

.sence-title1 {
  background-image: linear-gradient(270deg, rgba(29,146,226,0.00) 0%, #6761EB 100%);
  font-size: 24px;
  color: #FFFFFF;
  font-weight: 700;
  line-height: 54px;
  padding-left: 22px;
  width:1100px;
  margin: 0 auto;
}
.sence-title2 {
  background-image: linear-gradient(135deg, #2DB35F 0%, #FFFFFF 100%);
  font-size: 24px;
  color: #FFFFFF;
  font-weight: 700;
  line-height: 54px;
  padding-left: 22px;
  width:1100px;
  margin: 0 auto;
}

</style>
