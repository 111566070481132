<template>
  <el-container class="layout-box">
    <el-aside width="200px" class="position-fixed">
      <Menu />
    </el-aside>
    <el-aside width="200px"></el-aside>
    <el-container class="layout-content">
      <el-header class="layout-header position-fixed">
        <a @click="goOut" class="signOut">退出</a>
      </el-header>
      <el-header class="layout-header"></el-header>
      <el-main class="layout-main">
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import Menu from "./menu";
import api from "@/server/api";
export default {
  components: { Menu },
  data() {
    return {
      user: sessionStorage.getItem("user")
    }
  },
  methods: {
    goOut() {
      this.$ajax.post(api.newsLogout, {
        username: this.user
      }).then(res => {
        this.$router.push('/')
      })
    }
  },
  mounted() {
    this.$ajax.post(api.newsStatus, {
      username: this.user
    }).then(res => {
        if (res.data.data.status == 0) {
          this.$router.push('/login')
        }
    });
  }
};
</script>
<style lang="scss" scoped>
.layout-box {
  min-height: 100vh;
  display: flex;
  .layout-content {
    background-color: #ddd;
  }
  .layout-header {
    width: calc(100vw - 200px);
    background-color: #fff;
    padding: 0;
    -webkit-box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    // right: 0;
  }
  .layout-main {
    // background-color: #ffffff;
  }
  .position-fixed {
    position: fixed;
    z-index: 999;
  }
  .signOut {
    float: right;
    line-height: 60px;
    margin-right: 20px;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
