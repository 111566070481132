<template>
    <div id='loginDiv'>
        <el-form ref="form" :model="form" :rules="rules">
            <el-form-item label="域账号账号登录" class="tit">
            </el-form-item>
            <el-form-item prop="username">
                <el-input v-model="form.username" placeholder="请输入域账号"></el-input>
            </el-form-item>
            <el-form-item prop="password">
                <el-input type="password" v-model="form.password" placeholder="请输入密码"></el-input>
            </el-form-item>
            <el-form-item prop="code" class="code">
                <el-input v-model="form.code"  @keyup.enter.native="onSubmit" placeholder="请输入验证码"></el-input>
                <img @click="queryNum = Math.random()" :src="'/news/kaptcha?' + queryNum">
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit" :loading="loading">登录</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
// import sha1 from 'sha1.js'
import CryptoJS from "crypto-js"
import api from "@/server/api"

export default {
    data() {
        return {
            form: {},
            queryNum: 0,
            rules: {
                username: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                code: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
            },
            companyId: '',
            loading: false
        }
    },
    methods: {
        onSubmit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    var obj = JSON.parse(JSON.stringify(this.form));
                    obj.password = this.encrypt(obj.password)
                    this.loading = true
                    this.$ajax.post(api.newsLogin, obj).then(
                        rsp => {
                            if (rsp.data.code == 0) {
                                sessionStorage.setItem('user', obj.username)
                                this.$router.push('/manager')
                            } else {
                                alert(rsp.data.message)
                            }
                            this.loading = false
                        }
                    ).catch((err) => {
                        this.queryNum = Math.random();
                        this.loading = false
                    });
                }
            })
            
        },
        encrypt(word) {
            const key = CryptoJS.enc.Utf8.parse('1234567812345678')
            let encrypted = CryptoJS.AES.encrypt(word, key, { 
                mode: CryptoJS.mode.ECB, 
                padding: CryptoJS.pad.Pkcs7 
            });
            return encrypted.toString()
        }
    },
    mounted() {
        // console.log(this.encrypt('789456123'))
    }
}
</script>

<style lang="scss" scoped>
    #loginDiv {
        width: 100%;
        height: 100%;
        min-height: 700px;
        background-size: cover;
        position: relative;
        .el-form {
            position: absolute;
            right: 15%;
            top: 176px;
            background-color: #fff;
            border: 1px solid #c5c5c5;
            width: 420px;
            box-shadow: 1px 0 5px rgba(0,0,0,.12);
            padding: 36px;
            button {
                background: #1494FB;
                display: block;
                color: #fff;
                width: 100%;
                text-align: center;
                font-size: 14px;
            }
            .otherTip {
                font-size: 12px;
                cursor: pointer;
                text-align: right;
                a {
                    color: #118CEE;
                    margin-left: 30px;
                }
            }
        }
        @media only screen and (max-width: 767px) {
            .el-form {
                left: 50%;
                transform: translateX(-50%);
                width: 90%;
                .el-input {
                    width: 100%;
                }
                .el-form-item {
                    &.code {
                        position: relative;
                        .el-input {
                            padding-right: 100px;
                        }
                        img {
                            position: absolute;
                            top: 0;
                            right: 0;
                        }
                    }
                }
            }
        }
        .footer {
            color: rgba(255,255,255,.5);
            position: absolute;
            bottom: 39px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 12px;
            width: 288px;
            span {
                border-right: 1px solid rgba(255,255,255,0.5);
                padding: 0 5px;
                &:last-child {
                    border: none;
                }
            }
            &>div {
                text-align: center;
            }
        }
    }
    
</style>
