<template>
  <div class="news">
    <div class="top-bg">
      <Header class="header" />
      <div class="top-bg-text">新闻中心</div>
    </div>
    <div class="content">
      <ul class="new-list">
        <li
          v-for="item in list"
          :key="item.id"
          @click="
            $router.push({ path: '/newsDetailsA', query: { id: item.id } })
          "
        >
          <div class="date">
            <div class="date-box">
              <div class="day">
                <div class="m">
                  {{ item.date[5] == "0" ? "" : item.date[5]
                  }}{{ item.date[6] }}月
                </div>
                <div class="d">{{ item.date[8] }}{{ item.date[9] }}</div>
              </div>
              <div class="year">
                {{ item.date[0] }}{{ item.date[1] }}{{ item.date[2]
                }}{{ item.date[3] }}年
              </div>
            </div>
          </div>
          <div class="msg">
            <div class="from">{{ item.source }}</div>
            <div class="title">{{ item.title }}</div>
            <div class="cnt">{{ item.abstr }}</div>
          </div>
          <img class="img2" src="../img/news/2.svg" />
        </li>
      </ul>

      <div v-if="total > pageSize" class="page">
        <img @click="pageLeft" src="../img/news/3.png" alt="" />
        <div>{{ pageNumber() }}</div>
        <img @click="pageRight" src="../img/news/4.png" alt="" />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import api from "@/server/api";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      list: [],
      total: 0,
      pageNum: 1,
      pageSize: 8,
      allpageNum: 0,
    };
  },
  methods: {
    loadNews() {
      this.$ajax
        .post(api.newsList, {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          type: 0,
        })
        .then((res) => {
          this.list = res.data.data;
        });
    },
    pageLeft() {
      if (this.pageNum > 1) {
        this.pageNum--;
        this.loadNews();
      }
    },
    pageRight() {
      if (this.pageNum < this.allpageNum) {
        this.pageNum++;
        this.loadNews();
      }
    },
    pageNumber() {
      let allpage = 0;
      allpage = (this.total - (this.total % this.pageSize)) / this.pageSize + 1;

      this.allpageNum = allpage;
      return this.pageNum + "/" + allpage;
    },
  },
  mounted() {
    this.loadNews();
    let html = document.querySelector("html");
    html.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.newsAbstr {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.page {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 100px;
  > * {
    display: inline-block;
  }
  img {
    cursor: pointer;
  }
  > div {
    line-height: 40px;
    width: 60px;
    margin: 0 10px;
    text-align: center;
    vertical-align: top;
    background: #e3e3e3;
  }
}
.new-list {
  min-height: 800px;
  padding-bottom: 80px;
  li {
    height: 260px;
    display: flex;
    margin-top: 20px;
    cursor: pointer;
    position: relative;
    border-bottom: 1px solid #ebebeb;
    .date {
      color: #a8bace;
      .date-box {
        margin: 40px 40px 0 60px;
        background: url("../img/news/9.png");
        height: 145px;
        .day {
          width: 100px;
          height: 105px;
          .m {
            font-size: 24px;
            line-height: 49px;
            padding-left: 8px;
          }
          .d {
            font-size: 40px;
            text-align: right;
            padding-right: 5px;
          }
        }
        .year {
          margin-top: 8px;
          font-size: 18px;
          text-align: center;
        }
      }
    }
    .msg {
      color: #1f1f1f;
      .from {
        margin-top: 40px;
        line-height: 28px;
        font-size: 16px;
        color: #aeaeae;
        padding-left: 10px;
        border-left: 2px solid rgba(0, 122, 255, 0.2);
      }
      .title {
        width: 880px;
        font-size: 28px;
        margin-top: 12px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
      .title.active {
        color: #007aff;
      }
      .cnt {
        margin-top: 20px;
        font-size: 20px;
        color: #1f1f1f;
        letter-spacing: 0;
        text-align: justify;
        line-height: 36px;
        max-width: 860px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }
  li .img2 {
    display: none;
    position: absolute;
    top: calc(50% - 8px);
    right: 40px;
  }
  li:hover {
    background: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    & > .img2 {
      display: block;
    }
    & .title {
      color: #007aff;
    }
  }
}
.news {
  background: #f8f8f8;
}
.content {
  padding-top: 60px;
}
.top-bg {
  height: 560px;
  position: relative;
  background-image: url("../img/news/1.jpg");
  background-position: center;
  .header {
    position: absolute;
    top: 0;
    z-index: 100;
    height: 64px;
    width: 100%;
  }
  .top-bg-text {
    padding-top: 235px;
    text-align: center;
    font-size: 64px;
    color: #ffffff;
    letter-spacing: 20px;
  }
}
</style>