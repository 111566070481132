<template>
  <div>
    <div class="top-bg">
      <Header class="header" />
      <div class="content">
        <div class="top-text">FaceID</div>
        <div class="top-sub-text">
          亿级人脸数据训练，精准快速识别比对，可应用于智能门锁、考勤机等智能终端。
        </div>
        <div class="top-btn">
          <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
          <el-button class="coop-btn" round @click="consultClick">合作咨询</el-button>
        </div>
      </div>
    </div>

    <div class="content item-header">
      <div class="item-title">方案介绍</div>
    </div>
    <div class="content clear solution" style="margin-top: 60px">
      <div class="solution-wrap">
        <div class="solution-img">
          <img src="../../img/aiImage/iot/solution3_1.png" alt="">
          <div class="solution-icon"><img src="../../img/aiImage/iot/solution3_icon_1.png" alt=""></div>
          <div class="title">极速</div>
          <div style="margin-left: 80px;">
            <div class="item">
              <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
              <span class="text">100～200ms</span>
            </div>
          </div>
        </div>

          <div class="solution-img">
            <img src="../../img/aiImage/iot/solution3_2.png" alt="">
            <div class="solution-icon"><img src="../../img/aiImage/iot/solution3_icon_2.png" alt=""></div>
            <div class="title">易用</div>
            <div style="margin-left:92px;">
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">识误率&lt;0.01%</span>
              </div>
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">拒识率&lt;0.1%</span>
              </div>
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">不受日常妆容变化影响</span>
              </div>
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">暗光和逆光下解锁</span>
              </div>
            </div>
          </div>
          <div class="solution-img">
            <img src="../../img/aiImage/iot/solution3_3.png" alt="">
            <div class="solution-icon"><img src="../../img/aiImage/iot/solution3_icon_3.png" alt=""></div>
            <div class="title">安全</div>
            <div style="margin-left: 92px;">
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">生物活体防伪检测</span>
              </div>
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">可防止电子照片、视频</span>
              </div>
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">或冲印照片的攻击</span>
              </div>
            </div>
          </div>
      </div>
    </div>

    <div class="content item-header">
      <div class="item-title">应用场景</div>
    </div>
    <div class="content clear" style="margin-top: 60px;display:flex;">
      <div class="scene-item">
        <div>
          <img src="../../img/aiImage/iot/sence3_1.png" alt="">
        </div>
        <div class="scene-title">人脸考勤</div>
        <div class="scene-text">利用高精度的人脸识别、对比能力搭建智能考勤系统，可应用于手机、摄像头、考勤一体机等各类考勤终端。</div>
      </div>
      <div class="scene-item" style="margin-left:40px;">
        <div>
          <img src="../../img/aiImage/iot/sence3_2.png" alt="">
        </div>
        <div class="scene-title">智能门禁</div>
        <div class="scene-text">经过亿级人脸数据训练，精准快速识别人脸并进行身份判断，可应用于智能门锁、可视门铃等门禁智能设备。</div>
      </div>
    </div>

    <div class="content item-header" style="margin-bottom: 120px;">
      <contact></contact>
    </div>
    <dialog-consult ref="consult"></dialog-consult>
    <dialog-apply ref="apply"></dialog-apply>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import contact from "./components/contact"
import DialogConsult from './components/dialogConsult.vue';
import DialogApply from './components/dialogApply'
export default {
  components: {
    Header,
    Footer,
    contact,
    DialogConsult,
    DialogApply
  },

  data() {
    return {
    }
  },

  methods: {
    consultClick () {
      this.$refs.consult.show()
    },
    applyClick () {
      this.$refs.apply.show()
    }
  },

  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
.top-bg {
  position: relative;
  background-image: url("../../img/aiImage/iot/banner_face.jpg");
  background-position: center;
  .header {
    position: absolute;
    top: 0;
    z-index: 100;
    height: 64px;
    width: 100%;
  }
  .top-text {
    font-size: 64px;
    color: #ffffff;
    padding-top: 220px;
    padding-left: 60px;
    box-sizing: border-box;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-sub-text {
    width: 600px;
    height: 72px;
    line-height: 36px;
    margin-top: 40px;
    font-size:18px;
    color: #ffffff;
    padding-left: 60px;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-btn {
    padding-left: 60px;
    margin-top: 72px;
    .apply-btn {
      font-size: 18px;
      color: #fff;
      padding: 14px 37px;
      background-color: #007aff;
      border: none;
      border-radius: 25px;
    }
    .coop-btn {
      font-size: 18px;
      color: #0059FF;
      padding: 14px 37px;
      margin-left: 72px;
      border: none;
      border-radius: 25px;
    }
  }
}

.solution {
  background-color: #fff;
  width: 1160px;
  height: 610px;
  .solution-wrap {
    display: flex;
    .solution-img {
      width: 300px;
      height: 600px;
      padding-top: 30px;
      margin-left: 65px;
      .solution-icon {
        text-align: center;
        margin-top: 12px;
      }
      .title {
        margin: 8px 0 10px 0;
        font-size: 24px;
        color: #1F1F1F;
        letter-spacing: 0;
        text-align: center;
        font-weight: bold;
        font-family: "Microsoft YaHei","PingFangSC-Regular";
      }
      .item {
        line-height: 24px;
        height: 24px;
        vertical-align: middle;
        .check-img {
          margin-right: 10px;
          vertical-align: middle;
        }
        .text {
          font-size: 14px;
          color: #1F1F1F;
          letter-spacing: 0;
          line-height: 24px;
          vertical-align: middle;
          font-family: "Microsoft YaHei","PingFangSC-Regular";
        }
      }
    }
  }
}

.scene-item {
  width: 560px;
  height: 560px;
  background-color: #fff;
  .scene-title {
    font-size: 32px;
    color: #1F1F1F;
    letter-spacing: 0;
    text-align: center;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .scene-text {
    width: 420px;
    font-size: 16px;
    color: #1F1F1F;
    letter-spacing: 0;
    text-align: center;
    margin: 30px 0 0 70px;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
}

</style>
