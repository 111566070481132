<template>
  <div class="line">
    <div class="in-line" v-bind:style="{ width: d + '%' }"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      d: 10,
      c: true
    }
  },
  methods: {
    start() {
      setTimeout(() => {
        this.d++
        if (c) {
          this.start()
        }
      }, 200)
    },
    stop() {
      this.c = false
      this.d = 0
    }
  },
  mounted() {
    
  }
}
</script>

<style lang="scss" scoped>
.line {
  background: #99CAFF;
  height: 10px;
  .in-line {
    height: 10px;
    background: #007AFF;
  }
}
</style>