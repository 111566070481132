<template>
  <div>
    <div class="top-bg">
      <Header class="header" />
      <div class="content">
        <div class="top-text">AI证件照</div>
        <div class="top-sub-text">
          智能检测抠图，支持自动换装、背景替换、高级美颜等全套解决方案。
        </div>
        <div class="top-btn">
          <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
          <el-button class="coop-btn" round @click="consultClick">合作咨询</el-button>
        </div>
      </div>
    </div>

    <div class="content item-header">
      <div class="item-title">方案介绍</div>
      <div class="item-text">智能识别贴合人像换装，同时支持人脸/饰品/遮挡检测、自动抠图、背景替换、高级美颜等功能。</div>
    </div>
    <div class="content clear" style="margin-top: 60px">
      <img src="../../img/aiImage/onlineEdu/fun/photo_1.png" >
    </div>

    <div class="content item-header">
      <div class="item-title">核心技术</div>
    </div>
    <div class="content clear" style="margin-top: 60px;">
      <div class="code-item">
        <div class="code-img">
          <img src="../../img/aiImage/onlineEdu/fun/photo_2.png" alt="">
        </div>
        <div class="code-text">
          <div class="code-text-title">人像检测</div>
          <div class="code-text-sub-title">按证件照要求检测人像，排除饰品、遮挡等情况 </div>
        </div>
        <div class="code-btn">
          <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
        </div>
        <div class="bottom-line"></div>
      </div>
      <div class="code-item">
        <div class="code-img">
          <img src="../../img/aiImage/onlineEdu/fun/photo_3.png" alt="">
        </div>
        <div class="code-text">
          <div class="code-text-title">人像分割</div>
          <div class="code-text-sub-title">基于头部+颈部分割方案，进行背景替换和换装适配</div>
        </div>
        <div class="code-btn">
          <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
        </div>
        <div class="bottom-line"></div>
      </div>
      <div class="code-item">
        <div class="code-img">
          <img src="../../img/aiImage/onlineEdu/fun/photo_4.png" alt="">
        </div>
        <div class="code-text">
          <div class="code-text-title">人像美颜</div>
          <div class="code-text-sub-title">支持皮肤美化及五官微调，包括磨皮美白、大眼、亮眼、瘦脸、隆鼻、去黑眼圈、去法令纹等</div>
        </div>
        <div class="code-btn">
          <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
        </div>
        <div class="bottom-line"></div>
      </div>
    </div>

    <div class="content item-header">
      <div class="item-title">方案优势</div>
    </div>
    <div class="content clear" style="margin-top: 60px;">
      <div class="advantage">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../../img/aiImage/onlineEdu/fun/photo_5.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">全栈式方案</div>
            <div class="advantage-text-sub-title">满足证件照所需完整方案，一步搞定</div>
          </div>
        </div>
      </div>
      <div class="advantage" style="margin-left:40px;">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../../img/aiImage/onlineEdu/fun/photo_6.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">精准分割</div>
            <div class="advantage-text-sub-title">高精度边缘处理，融合效果更自然</div>
          </div>
        </div>
      </div>
    </div>

    <div class="content item-header" style="margin-bottom: 120px;">
      <contact></contact>
    </div>
    <dialog-consult ref="consult"></dialog-consult>
    <dialog-apply ref="apply"></dialog-apply>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import contact from "./components/contact"
import DialogConsult from './components/dialogConsult.vue';
import DialogApply from './components/dialogApply'
export default {
  components: {
    Header,
    Footer,
    contact,
    DialogConsult,
    DialogApply
  },

  data() {
    return {
    }
  },

  methods: {
    consultClick () {
      this.$refs.consult.show()
    },
    applyClick () {
      this.$refs.apply.show()
    }
  },

  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
.top-bg {
  position: relative;
  background-image: url("../../img/aiImage/onlineEdu/fun/header_3.jpg");
  background-position: center;
  .header {
    position: absolute;
    top: 0;
    z-index: 100;
    height: 64px;
    width: 100%;
  }
  .top-text {
    font-size: 64px;
    color: #ffffff;
    padding-top: 220px;
    padding-left: 60px;
    box-sizing: border-box;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-sub-text {
    width: 600px;
    height: 72px;
    line-height: 36px;
    margin-top: 40px;
    font-size:18px;
    color: #ffffff;
    padding-left: 60px;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-btn {
    padding-left: 60px;
    margin-top: 72px;
    .apply-btn {
      font-size: 18px;
      color: #fff;
      padding: 14px 37px;
      background-color: #007aff;
      border: none;
      border-radius: 25px;
    }
    .coop-btn {
      font-size: 18px;
      color: #0059FF;
      padding: 14px 37px;
      margin-left: 72px;
      border: none;
      border-radius: 25px;
    }
  }
}

.advantage {
  height: 240px;
  width: 560px;
  background-color: #fff;
  display: inline-block;
  .advantage-wrap {
    display: flex;
    align-items: center;
  }
  .advantage-img {
    >img {
      margin: 60px 40px 60px 60px;
    }
  }
  .advantage-text {
    .advantage-text-title {
      font-size: 24px;
      color: #1F1F1F;
      letter-spacing: 0;
      font-weight: bold;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
    .advantage-text-sub-title {
      font-size: 14px;
      color: #1F1F1F;
      letter-spacing: 0;
      margin-top: 10px;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
  }
}
.code-item {
  width: 100%;
  height: 240px;
  background-color: #fff;
  display: flex;
  align-items: center;
  position: relative;
  .bottom-line {
    position: absolute;
    bottom: 0;
    left: 70px;
    border-top: 1px solid #d8d7d7;
    width: 1020px;
  }
  .code-img {
    margin-left: 60px;
  }
  .code-text {
    width: 660px;
    margin-left: 40px;
    .code-text-title {
      font-weight: bold;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
      font-size: 24px;
      color: #1F1F1F;
      letter-spacing: 0;
    }
    .code-text-sub-title {
      font-size: 16px;
      color: #1F1F1F;
      letter-spacing: 0;
      line-height: 24px;
      margin-top: 10px;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
  }
  .code-btn {
    margin-left: 60px;
    .apply-btn {
      font-size: 18px;
      color: #2d2d2d;
      padding: 14px 37px;
      background-color: #fff;
      border: 1px solid #2d2d2d;
      border-radius: 25px;
      &:hover {
        border: 1px solid #0d81ff;
        color: #0d81ff;
      }
    }
  }
}

</style>
