<template>
  <div class="query">
    <div class="query-btn">
      <el-button class="apply-btn" @click="applyClick">试用申请</el-button>
    </div>
    <div class="query-text">
      <div class="query-text-title">快速咨询</div>
      <div class="query-text-sub-title">扫码联系顾问获取报价和demo >></div>
    </div>
    <div class="query-img">
      <img src="../../../img/aiImage/onlineEdu/weixin.png" alt="">
    </div>
    <dialog-apply ref="apply"></dialog-apply>
  </div>
</template>

<script>
import DialogApply from './dialogApply'

export default {
  components: {
    DialogApply
  },

  data() {
    return {
    }
  },

  methods: {
    applyClick () {
      /* 试用申请 */
      this.$refs.apply.show()
    }
  },

  mounted() {
    
  }
}
</script>

<style lang="scss" scoped>
.query {
  width: 100%;
  height: 240px;
  background-color: #fff;
  display: flex;
  align-items: center;
  .query-btn {
    margin-left: 116px;
    .apply-btn {
      background: #007AFF;
      border-radius: 6px;
      font-size: 24px;
      color: #FFFFFF;
      padding: 23px 71px;
    }
  }
  .query-text {
    padding-left: 88px;
    margin-left: 116px;
    height: 160px;
    width: 340px;
    border-left: 1px solid rgba(0,0,0,0.15);
    .query-text-title {
      font-size: 24px;
      color: #000000;
      letter-spacing: 0;
      text-align: left;
      margin-top: 41px;
      font-weight: bold;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
    .query-text-sub-title {
      font-size: 16px;
      margin-top: 20px;
      color: #000000;
      letter-spacing: 0;
      line-height: 24px;
      text-align: left;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
  }
}

</style>
