<template>
    <div id="app">
        <div class="header">
            <a href="/"><img src="@/img/logo_blue.svg" alt=""></a>
        </div>
        <div class="text-content">
            <div class="title">用户体验改进计划</div>
            <pre>
                欢迎您加入“用户体验改进计划”，为了改善用户体验，西安欧思奇软件有限公司（以下简称 “我们”）会根据需要对系统的各项功能使用情况进行统计，通过分析统计数据，持续不断地提升系统的操作体验、运行性能、改善功能设计等，及时为您提供提升用户体验和个性化服务。在统计时，我们只对自身服务进行统计，绝不涉及您的个人隐私数据，被收集和处理的用户数据无法用来识别、追踪您的个人身份。
            </pre>
            <div class="title2">一、系统功能使用统计</div>
            <pre>
                我们为了提高用户体验、增强用户使用感，最大程度满足大多数用户的需求，我们会持续改善系统功能，据此我们需要了解系统功能的使用情况，以便我们能够对系统功能的重要性做出正确判断，进而指导我们调整功能研发的优先顺序。在此过程中，我们只统计系统功能的使用情况，不涉及用户个人隐私。
            </pre>
            <div class="title2">二、加入用户体验改进计划的范围</div>
            <pre>
                我们希望通过您反馈的信息，在最大程度上提升系统的易用性、稳定性、实用性。具体包括：
                1、及时将系统更新至最新版本，及时修复可能出现的系统BUG；
                2、让您更加安全、安心地使用数据云端备份功能；
                3、使您更加便捷地使用设备各项开关、设置项及常用功能；
                4、提升系统的易用性，使您操作更简便流畅；
                5、提供系统在不同设备环境中的稳定性、兼容性、美观度和功能体验等。
            </pre>
            <div class="title2">三、参加用户体验改进计划不会侵犯您的隐私</div>
            <pre>
                我们严格遵守国家有关的法律法规，本计划对相关数据信息的收集是完全匿名的，我们不会将收集的信息与其他任何个人信息进行匹配存储，亦不会向其他第三方提供。我们仅为了改善您的用户体验才予以搜集相关信息，不会向您推送任何无关信息。有关隐私保护的规定，具体阅读《个人隐私保护政策》。
            </pre>
            <div class="title2">四、确认加入“用户体验改进计划”</div>
            <pre>
                您可以通过“搜索栏>用户改进计划”，将“加入用户改进计划”项的选择调整为“开启”，表示您选择参与了用户体验改进计划。
            </pre>
            <div class="title2">五、退出计划</div>
            <pre>
                根据您的意愿，通过“搜索栏”将“加入用户改进计划”项的选择调整为“关闭”即可。如您退出计划，则不会收集您的上述信息。
                根据系统的升级，我们可能会对用户体验改进计划进行修改，我们会在系统的显著位置发布相关信息并向您明示，以便及时通知到您。如果您选择继续使用，即表示您同意接受这些修改。
            </pre>
        </div>
        <Footer />
    </div>
</template>

<script>
import Footer from '@/components/footer'

export default {

    components:{
        Footer
    },
    data() {
        return {
        }
    },
}
</script>

<style lang="scss" scoped>
pre {
    white-space: pre-line;
    margin-top: 0;
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 28px;
}
.header {
    width: 1160px;
    margin: 0 auto;
    img {
        padding: 22px 0;
    }
    a {
        display: inline-block;
    }
}
.text-content {
    width: 760px;
    margin: 0 auto;
    color: #1F1F1F;
    .title {
        font-size: 48px;
        text-align: center;
        padding-top: 80px;
        padding-bottom: 60px;
    }
    .title2 {
        font-weight: bold;
        font-size: 16px;
        padding-bottom: 10px;
    }
}
</style>

