<template>
  <footer>
    <div class="content">
      <div class="footer-box">
          <div class="address">
            <ul>
              <li class="title">联系地址</li>
              <li class="">北京：北京市朝阳区酒仙桥路360大厦</li>
              <li ><div style="float: left;">深圳：</div><div style="margin-left: 38px;">广东省深圳市南山区学苑大道1001号南山智园A2栋8楼</div></li>
              <li ><div style="float: left;">西安：</div><div style="margin-left: 38px;">陕西省西安市高新区唐延路51号人寿壹中心B座7层713室</div></li>
            </ul>
          </div>
          <div class="border"></div>
          <div class="address">
            <ul>
              <li class="title">联系方式</li>
              <li>市场合作：<a>marketing-os@360os.com</a></li>
              <li>OS生态合作：<a>OSbusiness@360os.com</a></li>
              <li>AI智能影像合作：<a>AIbusiness@360os.com</a></li>
              <li>产业物联网合作：<a>IOTbusiness@360os.com</a></li>
              <li>AI监测商务合作：<a>AIM@360os.com</a></li>
               <li>联系电话：<a>400-832-3666</a></li>
            </ul>
          </div>
          <div class="border"></div>
          <div class="protocol">
            <ul>
              <li class="title">用户协议</li>
              <li><a href="/index.html#/improve" target="_blank">用户体验改进计划</a></li>
              <li><a href="/index.html#/protection" target="_blank">个人隐私保护政策</a></li>
              <li><a href="/index.html#/license" target="_blank">用户许可及服务协议</a></li>
            </ul>
          </div>
      </div>
      <div style="margin-bottom: 40px;font-size: 12px;line-height: 20px;">
        全国涉黑涉恶违法犯罪线索举报平台：<br /><a>https://www.12389.gov.cn/clue_evilNotice.action</a>
      </div>
      <div class="copyright">
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61019002001604" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;padding-right:20px;"><img src="../img/record_icon.png" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px;">陕公网安备 61019002001604号</p></a>
        <a href="https://beian.miit.gov.cn/">陕ICP备18011143号-1 | 许可证编号 陕B2-20180114 | CopyRight@2017</a>
        <span style="vertical-align: middle;"> 西安欧思奇软件有限公司 版权所有</span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.border {
  width: 1px;
  opacity: 0.1;
  background: #FFFFFF;
  flex: none !important;
  margin: 0 59px;
}
.footer-box {
  display: flex;
  >div {
    flex: 1.1;
  }
  margin-top: 80px;
  margin-bottom: 40px;
}
.copyright {
  font-size: 12px;
  opacity: 0.4;
  padding-bottom: 34px !important;
  vertical-align: middle;
  height: 20px;
  line-height: 20px;
  a {
    color: #fff !important;
    text-decoration: none;
    vertical-align: middle;
  }
}
footer {
  background: #343949;
  color: #fff;
  .content {
    overflow: hidden;
    font-size: 14px;
    > div {
      padding: 0 60px;
      // float: left;
    }
    .address {
      
    }
    li {
        padding-top: 20px;
        font-size: 12px;
        line-height: 17px;
        text-align: justify;
      }
      .title {
        padding-top: 0;
        padding-bottom: 20px;
        font-size: 20px;
        height: 28px;
        line-height: 28px;
      }
      a {
        font-size: 12px;
        color: #41DEA3;
      }
    .protocol {
      flex: 0.5;
      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }
}

</style>