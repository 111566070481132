<template>
  <div class="weixin">
    0000
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.weixin {
  
}
</style>