import Vue from 'vue'
import VueRouter from 'vue-router'
const { NODE_ENV } = process.env;

Vue.use(VueRouter)

const routes = [
  {
    path: '/', // 主页
    name: 'home',
    component: () => import('@/views/home.vue')
  },
  {
    path: '/industry',  // 产业物联网
    name: 'industry',
    component: () => import('@/views/industry.vue')
  },
  {
    path: '/ecology', // OS生态
    name: 'ecology',
    component: () => import('@/views/ecology.vue')
  },
  {
    path: '/aiImage', // AI影像
    name: 'aiImage',
    component: () => import('@/views/aiImage.vue')
  },
  {
    path: '/aiImage/assistant', // AI影像-网课助手
    name: 'assistant',
    component: () => import('@/views/aiImageDetail/onlineEduAssistant.vue')
  },
  {
    path: '/aiImage/immerse', // AI影像-沉浸式课件
    name: 'immerse',
    component: () => import('@/views/aiImageDetail/onlineEduImmerse.vue')
  },
  {
    path: '/aiImage/clear', // AI影像-超清拍题
    name: 'clear',
    component: () => import('@/views/aiImageDetail/onlineEduClear.vue')
  },
  {
    path: '/aiImage/face', // AI影像-人脸考勤
    name: 'face',
    component: () => import('@/views/aiImageDetail/onlineEduFace.vue')
  },
  {
    path: '/aiImage/portrait', // AI影像-人像美化
    name: 'portrait',
    component: () => import('@/views/aiImageDetail/funPortrait.vue')
  },
  {
    path: '/aiImage/special', // AI影像-趣味特效
    name: 'special',
    component: () => import('@/views/aiImageDetail/funSpecial.vue')
  },
  {
    path: '/aiImage/photo', // AI影像-证件照
    name: 'photo',
    component: () => import('@/views/aiImageDetail/funPhoto.vue')
  },
  {
    path: '/aiImage/monitor', // AI影像-iot-安防监护
    name: 'monitor',
    component: () => import('@/views/aiImageDetail/iotMonitor.vue')
  },
  {
    path: '/aiImage/analysis', // AI影像-iot-智能辅助分析
    name: 'analysis',
    component: () => import('@/views/aiImageDetail/iotAnalysis.vue')
  },
  {
    path: '/aiImage/faceId', // AI影像-iot-FaceID
    name: 'faceId',
    component: () => import('@/views/aiImageDetail/iotFace.vue')
  },
  {
    path: '/aiImage/multiCamera', // AI影像-智慧手机-智慧多摄 
    name: 'multiCamera',
    component: () => import('@/views/aiImageDetail/phoneMultiCamera.vue')
  },
  {
    path: '/aiImage/multiFrame', // AI影像-智慧手机-智慧多帧画质  
    name: 'multiFrame',
    component: () => import('@/views/aiImageDetail/phoneMultiFrame.vue')
  },
  {
    path: '/aiImage/videoQuality', // AI影像-智慧手机-视频画质   
    name: 'videoQuality',
    component: () => import('@/views/aiImageDetail/phoneVideoQuality.vue')
  },
  {
    path: '/aiImage/scene', // AI影像-智慧手机-场景识别    
    name: 'scene',
    component: () => import('@/views/aiImageDetail/phoneScene.vue')
  },
  {
    path: '/internetThings', // 奇络物联
    name: 'internetThings',
    component: () => import('@/views/internetThings.vue')
  },
  {
    path: '/aiMonitor', // AI监测
    name: 'aiMonitor',
    component: () => import('@/views/aiMonitor.vue')
  },
  {
    path: '/aiZhilian', // AI智联
    name: 'aiZhilian',
    component: () => import('@/views/aiZhilian.vue')
  },
  {
    path: '/about', // 关于我们
    name: 'about',
    component: () => import('@/views/about.vue')
  },
  {
    path: '/news', // 新闻中心
    name: 'news',
    component: () => import('@/views/news.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue')
  },
  // {
  //   path: '/mall',
  //   name: 'mall',
  //   component: () => import('@/views/mall/index.vue')
  // },
  // {
  //   path: '/mall/participate',
  //   name: 'participate',
  //   component: () => import('@/views/mall/participate.vue')
  // },
  // {
  //   path: '/mall/details',
  //   name: 'mallDetails',
  //   component: () => import('@/views/mall/details.vue')
  // },
  // {
  //   path: '/mall/release',
  //   name: 'mallRelease',
  //   component: () => import('@/views/mall/release.vue')
  // },
  // {
  //   path: '/mall/popular',
  //   name: 'mallPopular',
  //   component: () => import('@/views/mall/popular.vue')
  // },
  {
    path: '/newsDetailsA',
    name: 'newsDetailsA',
    // component: () => import('@/views/news.vue')
    component: () => import('@/views/newsDetailsA.vue')
  },
  {
    path: '/newsAdd',
    name: 'newsAdd',
    component: () => import('@/views/newsAdd.vue')
  },
  {
    path: '/improve',
    name: 'improve',
    component: () => import('@/views/protocol/improve.vue')
  },
  {
    path: '/license',
    name: 'license',
    component: () => import('@/views/protocol/license.vue')
  },
  {
    path: '/protection',
    name: 'protection',
    component: () => import('@/views/protocol/protection.vue')
  },
  {
    path: '/third-sdk',
    name: 'third-sdk',
    component: () => import('@/views/protocol/third-sdk.vue')
  },
  {
    path: '/manager',
    name: 'manager',
    component: () => import('@/views/layout/index.vue'),
    children: [
      {
        path: "/manager",
        redirect: '/manager/list'
      },
      {
        path: '/manager/edit',
        name: 'managerEdit',
        // props: true,
        component: () => import('@/views/newsManager/createAndEdit.vue')
      },
      {
        path: '/manager/edit/:id',
        name: 'managerEditId',
        props: true,
        component: () => import('@/views/newsManager/createAndEdit.vue')
      },
      {
        path: '/manager/list',
        name: 'newsList',
        component: () => import('@/views/newsManager/newsList.vue')
      },
      {
        path: '*',
        name: '404',
        component: () => import('@/views/notFound/index.vue')
      }
    ]
  },
  {
    path: '/newsManager',
    name: 'newsManager',
    component: () => import('@/views/newsManager/createAndEdit.vue')
  },
  {
    path: '/weixin',
    name: 'weixin',
    component: () => import('@/views/weixin/index.vue')
  },
]

const router = new VueRouter({
  routes
})

if (NODE_ENV != 'development') {
  router.beforeEach((to, from, next) => {

    // if (location.protocol == "http:") {
    //   location.assign("https://www.360os.com/index.html")
    //   return
    // }
    if (location.pathname.includes('index.html') == false) {
      location.assign("/index.html")
      // location.assign("https://www.360os.com/index.html")
      return
    }
    next()
  })
}


export default router
