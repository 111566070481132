<template>
  <div>
    <div class="top-bg">
      <Header class="header" />
      <div class="content">
        <div class="top-text">网课助手</div>
        <div class="top-sub-text">
          通过姿态识别、情绪分析、距离检测等维度分析学生上网课的状态，量化课堂效果，进行学情分析和教学质量评估，并协助学生健康管理。
        </div>
        <div class="top-btn">
          <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
          <el-button class="coop-btn" round @click="consultClick">合作咨询</el-button>
        </div>
      </div>
    </div>

    <div class="content item-header">
      <div class="item-title">方案介绍</div>
      <div class="item-text">包含课堂专注度、课堂接受度、疲劳提醒、姿态纠正四大功能，支持实时纠正提醒及课后学情报告输出。</div>
    </div>
    <div class="content clear" style="margin-top: 60px">
      <img src="../../img/aiImage/onlineEdu/assistant/solution.png" >
    </div>

    <div class="content item-header">
      <div class="item-title">方案架构</div>
    </div>
    <div class="content clear" style="margin-top: 60px">
      <img src="../../img/aiImage/onlineEdu/assistant/architecture.png" >
    </div>

    <div class="content item-header">
      <div class="item-title">方案优势</div>
    </div>
    <div class="content clear" style="margin-top: 60px;">
      <div class="advantage">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../../img/aiImage/onlineEdu/assistant/private.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">隐私安全</div>
            <div class="advantage-text-sub-title">采用端计算上传事件，不储存用户敏感信息</div>
          </div>
        </div>
      </div>
      <div class="advantage" style="margin-left:40px;">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../../img/aiImage/onlineEdu/assistant/config.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">配置灵活</div>
            <div class="advantage-text-sub-title">支持自定义指标数量及参数，满足实际应用需求</div>
          </div>
        </div>
      </div>
      <div class="advantage" style="margin-top:40px;">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../../img/aiImage/onlineEdu/assistant/algorithm.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">高精度算法</div>
            <div class="advantage-text-sub-title">根据不同场景匹配训练数据，模型效果市场领先</div>
          </div>
        </div>
      </div>
      <div class="advantage" style="margin-left:40px;margin-top:40px;">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../../img/aiImage/onlineEdu/assistant/scene.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">多场景适用</div>
            <div class="advantage-text-sub-title">支持线上/线下、单人/多人课堂场景</div>
          </div>
        </div>
      </div>
    </div>

    <div class="content item-header">
      <div class="item-title">核心技术</div>
    </div>
    <div class="content clear" style="margin-top: 60px;">
      <div class="code-item">
        <div class="code-img">
          <img src="../../img/aiImage/onlineEdu/assistant/code_1.png" alt="">
        </div>
        <div class="code-text">
          <div class="code-text-title">表情识别</div>
          <div class="code-text-sub-title">识别愤怒、厌恶、恐惧、高兴、平静、悲伤、疑惑及惊讶共8种基本表情</div>
        </div>
        <div class="code-btn">
          <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
        </div>
        <div class="bottom-line"></div>
      </div>
      <div class="code-item">
        <div class="code-img">
          <img src="../../img/aiImage/onlineEdu/assistant/code_2.png" alt="">
        </div>
        <div class="code-text">
          <div class="code-text-title">手势识别</div>
          <div class="code-text-sub-title">识别举手、比心、点赞、OK等14种常用手势</div>
        </div>
        <div class="code-btn">
          <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
        </div>
        <div class="bottom-line"></div>
      </div>
      <div class="code-item">
        <div class="code-img">
          <img src="../../img/aiImage/onlineEdu/assistant/code_3.png" alt="">
        </div>
        <div class="code-text">
          <div class="code-text-title">人脸姿态识别</div>
          <div class="code-text-sub-title">识别闭眼、张嘴、摇头、点头、低头、仰头、歪头、侧脸、打哈欠共9种人脸姿态</div>
        </div>
        <div class="code-btn">
          <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
        </div>
        <div class="bottom-line"></div>
      </div>
      <div class="code-item">
        <div class="code-img">
          <img src="../../img/aiImage/onlineEdu/assistant/code_4.png" alt="">
        </div>
        <div class="code-text">
          <div class="code-text-title">人体姿态识别</div>
          <div class="code-text-sub-title">识别趴着、斜肩、前倾、后仰等不良坐姿</div>
        </div>
        <div class="code-btn">
          <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
        </div>
        <div class="bottom-line"></div>
      </div>
      <div class="code-item">
        <div class="code-img">
          <img src="../../img/aiImage/onlineEdu/assistant/code_5.png" alt="">
        </div>
        <div class="code-text">
          <div class="code-text-title">距离检测</div>
          <div class="code-text-sub-title">监测人脸到屏幕的距离及人头到桌面的距离</div>
        </div>
        <div class="code-btn">
          <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
        </div>
      </div>
    </div>

    <div class="content item-header">
      <div class="item-title">应用场景</div>
    </div>
    <div class="content clear" style="margin-top: 60px;">
      <div class="scene-item">
        <div>
          <img src="../../img/aiImage/onlineEdu/assistant/scene_1.png" alt="">
        </div>
        <div class="scene-title">录播网课</div>
        <div class="scene-text">代替家长进行学习健康管理，全面了解孩子自学状态，助力培养良好坐姿。</div>
      </div>
      <div class="scene-item" style="margin-left:40px;">
        <div>
          <img src="../../img/aiImage/onlineEdu/assistant/scene_2.png" alt="">
        </div>
        <div class="scene-title">互动直播课</div>
        <div class="scene-text">提升老师管教效率，输出个人和班级课堂表现报告，有效评估课堂质量。</div>
      </div>
      <div class="scene-item" style="margin-left:40px;">
        <div>
          <img src="../../img/aiImage/onlineEdu/assistant/scene_3.png" alt="">
        </div>
        <div class="scene-title">教育硬件</div>
        <div class="scene-text">为教育平板、学习台灯等设备提供坐姿监测、智能督学等增值功能。</div>
      </div>
    </div>

    <div class="content item-header" style="margin-bottom: 120px;">
      <contact></contact>
    </div>
    <dialog-consult ref="consult"></dialog-consult>
    <dialog-apply ref="apply"></dialog-apply>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import contact from "./components/contact"
import DialogConsult from './components/dialogConsult.vue';
import DialogApply from './components/dialogApply'
export default {
  components: {
    Header,
    Footer,
    contact,
    DialogConsult,
    DialogApply
  },

  data() {
    return {
      show: false,
      iotShow: false,
      showGif: 'msg1',
      iotName: 'iot1',
      uiName: 'fun1',
      phoneName: 'phone1',
      phoneTimer: null,
      vedioStatus: false,
      videoPlay: null,
      showConsult: false
    }
  },

  methods: {
    onlineClick () {
      /* 在线教育详情点击事件 */
    },
    consultClick () {
      this.$refs.consult.show()
    },
    applyClick () {
      this.$refs.apply.show()
    }
  },

  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
.top-bg {
  position: relative;
  background-image: url("../../img/aiImage/onlineEdu/assistant/header.jpg");
  background-position: center;
  .header {
    position: absolute;
    top: 0;
    z-index: 100;
    height: 64px;
    width: 100%;
  }
  .top-text {
    font-size: 64px;
    color: #ffffff;
    padding-top: 220px;
    padding-left: 60px;
    box-sizing: border-box;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-sub-text {
    width: 600px;
    height: 72px;
    line-height: 36px;
    margin-top: 40px;
    font-size:18px;
    color: #ffffff;
    padding-left: 60px;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-btn {
    padding-left: 60px;
    margin-top: 72px;
    .apply-btn {
      font-size: 18px;
      color: #fff;
      padding: 14px 37px;
      background-color: #007aff;
      border: none;
      border-radius: 25px;
    }
    .coop-btn {
      font-size: 18px;
      color: #0059FF;
      padding: 14px 37px;
      margin-left: 72px;
      border: none;
      border-radius: 25px;
    }
  }
}

.advantage {
  height: 240px;
  width: 560px;
  background-color: #fff;
  display: inline-block;
  .advantage-wrap {
    display: flex;
    align-items: center;
  }
  .advantage-img {
    >img {
      margin: 60px 40px 60px 60px;
    }
  }
  .advantage-text {
    .advantage-text-title {
      font-size: 24px;
      color: #1F1F1F;
      letter-spacing: 0;
      font-weight: bold;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
    .advantage-text-sub-title {
      font-size: 14px;
      color: #1F1F1F;
      letter-spacing: 0;
      margin-top: 10px;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
  }
}
.code-item {
  width: 100%;
  height: 240px;
  background-color: #fff;
  display: flex;
  align-items: center;
  position: relative;
  .bottom-line {
    position: absolute;
    bottom: 0;
    left: 70px;
    border-top: 1px solid #d8d7d7;
    width: 1020px;
  }
  .code-img {
    margin-left: 60px;
  }
  .code-text {
    width: 660px;
    margin-left: 40px;
    .code-text-title {
      font-size: 24px;
      color: #1F1F1F;
      letter-spacing: 0;
      font-weight: bold;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
    .code-text-sub-title {
      font-size: 16px;
      color: #1F1F1F;
      letter-spacing: 0;
      line-height: 24px;
      margin-top: 10px;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
  }
  .code-btn {
    margin-left: 60px;
    .apply-btn {
      font-size: 18px;
      color: #2d2d2d;
      padding: 14px 37px;
      background-color: #fff;
      border: 1px solid #2d2d2d;
      border-radius: 25px;
      &:hover {
        border: 1px solid #0d81ff;
        color: #0d81ff;
      }
    }
  }
}

.scene-item {
  display: inline-block;
  width: 360px;
  height: 500px;
  background-color: #fff;
  .scene-title {
    font-size: 32px;
    color: #1F1F1F;
    letter-spacing: 0;
    text-align: center;
    margin: 38px 0 30px 0;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .scene-text {
    font-size: 16px;
    color: #1F1F1F;
    letter-spacing: 0;
    text-align: center;
    margin: 0 24px;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
}

</style>
