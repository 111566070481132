<template>
  <div>
    <div class="top-bg">
      <Header class="header" />
      <div class="content">
        <div class="top-text">AI影像</div>
        <div class="top-sub-text">
          360OS AI影像专注在线教育、泛娱乐、智能手机、IOT领域，提供包括APP、算法、tuning等全栈式、一体化解决方案，具备极高性价比和极短开发周期优势。
        </div>
      </div>
    </div>

    <div class="content item-header">
      <img src="../img/aiImage/education.png" />
      <div class="item-title">在线教育解决方案</div>
      <div class="item-text">多样智慧教育场景解决方案，深入解决在线教育行业痛点</div>
    </div>
    <div class="content clear" style="margin-top: 52px">
      <div class="edu-img-1 education-img">
        <img src="../img/aiImage/assistant.png" >
      </div>
      <div class="edu-img-2 education-img">
        <div class="video-bg" id="video_wrap">
          <my-video videoUrl="/immerse.mp4" ref="video" videoWidth="560" videoHeight="400" style="margin-top:35px;" @videoEvent="videoEvent"></my-video>
        </div>
      </div>
      <div class="edu-img-3 education-img">
        <div class="clear-bg">
          <div class="normal-bg drag-pic-wrap">
            <div class="js-drag-pic" style="width: 280px;">
              <img style="position: relative;" src="../img/aiImage/normal_clear.png" alt="">
            </div>
            <img style="left: 264px;" class="dragger-clear" src="../img/aiImage/dragger.png" alt="">
          </div>
        </div>
      </div>
      <div class="edu-img-4 education-img">
        <img src="../img/aiImage/face.png" >
      </div>
      <div class="phone-security" style="margin-left: 40px;width: 500px;float: left;">
        <div class="img-box" style="margin-top: 100px;margin-bottom: 60px;">
          <div>
            <img @click="show = !show" class="active" name="msg1" src="../img/aiImage/tab-assistant.png" alt="">
            <img name="msg2" src="../img/aiImage/tab-immerse.png" alt="">
            <img name="msg3" src="../img/aiImage/tab-clear.png" alt="">
            <img name="msg4" src="../img/aiImage/tab-face.png" alt="">
          </div>
          <div class="img-box-line">
            <div class="line-bg">
              <div class="line-move-ai1"></div>
            </div>
          </div>
        </div>
        <div class="phone-security-msg msg1">
          <div class="msg-title">网课助手</div>
          <div class="msg-text">包含课堂专注度、课堂接受度、疲劳提醒、姿态纠正四大功能，量化课堂效果，辅助学生健康管理。</div>
          <el-button class="more" round @click="onlineClick('assistant')">了解更多 <i class="el-icon-arrow-right"></i></el-button>
        </div>
        <div class="phone-security-msg msg2">
          <div class="msg-title">沉浸式课件</div>
          <div class="msg-text">录播课不受时间场地限制，随时随地进行；直播课感受沉浸式体验，互动性更强。</div>
          <el-button class="more" round @click="onlineClick('immerse')">了解更多 <i class="el-icon-arrow-right"></i></el-button>
        </div>
        <div class="phone-security-msg msg3">
          <div class="msg-title">超清拍题</div>
          <div class="msg-text">解决所有拍题模糊问题，包含超分增强、防抖、HDR等综合方案。</div>
          <el-button class="more" round @click="onlineClick('clear')">了解更多 <i class="el-icon-arrow-right"></i></el-button>
        </div>
        <div class="phone-security-msg msg4">
          <div class="msg-title">人脸考勤</div>
          <div class="msg-text">适用线上线下多人无感考勤，急速检测、极小误识，活体防伪。</div>
          <el-button class="more" round @click="onlineClick('face')">了解更多 <i class="el-icon-arrow-right"></i></el-button>
        </div>
      </div>
    </div>

    <div class="content item-header">
      <img src="../img/aiImage/recreation.png" />
      <div class="item-title">泛娱乐解决方案</div>
      <div class="item-text">为社交、短视频、直播、游戏等场景提供多元化的视觉互动能力</div>
    </div>

    <div class="content ui" style="text-align: center;margin-top: 60px;">
      <div>
        <div style="display: inline-block;">
          <a class="ui-tabs-a active" name="fun1">人像美化</a>
          <a class="ui-tabs-a" name="fun2">趣味特效</a>
          <a class="ui-tabs-a" name="fun3">AI证件照</a>
          <div class="ui-box-line">
            <div class="line-bg">
              <div class="line-move-ai2"></div>
            </div>
          </div>
        </div>
      </div>  
    </div>
    <div style="text-align: center;margin-top: 20px;">
      <div class="fun1">
        <img src="../img/aiImage/recreation_1.png" alt="">
        <div class="content recreation-text">
          智能美颜、美体，多种滤镜类型。独家AI补光，不打灯也超美。
        </div>
        <el-button style="margin-top:20px;" class="more" round @click="onlineClick('portrait')">了解更多 <i class="el-icon-arrow-right"></i></el-button>
      </div>
      <div class="fun2" style="width:100%;display:none;">
        <img  src="../img/aiImage/recreation_2.png" alt="">
        <div class="content recreation-text">
          包括AR贴纸、3D面具、AI换脸、AI时光机、漫画脸、简笔画等，娱乐互动趣味无穷。
        </div>
        <el-button style="margin-top:20px;" class="more" round @click="onlineClick('special')">了解更多 <i class="el-icon-arrow-right"></i></el-button>
      </div>
      <div class="fun3" style="width:100%;display:none;">
        <img  src="../img/aiImage/recreation_3.png" alt="">
        <div class="content recreation-text">
          智能检测抠图，同时支持自动换装、背景替换、高级美颜等综合方案。
        </div>
        <el-button style="margin-top:20px;" class="more" round @click="onlineClick('photo')">了解更多 <i class="el-icon-arrow-right"></i></el-button>
      </div>
    </div>

    <div class="content item-header">
      <img src="../img/aiImage/phone.png" />
      <div class="item-title">智慧手机解决方案</div>
      <div class="item-text">为手机行业提供视觉核心产品及解决方案，安全更出色</div>
    </div>
    <div style="background-color:#fff;padding: 120px 0;margin-top:60px;">
      <div class="content">
        <div class="ai-phone-solution clear">
          <div class="ai-img-box">
            <div class="img-div phone1">
              <img src="../img/aiImage/phone_1.png" />
            </div>
            <div class="img-div phone2">
              <img src="../img/aiImage/phone_2.png" />
            </div>
            <div class="img-div phone3">
              <img src="../img/aiImage/phone_3.png" />
            </div>
            <div class="img-div phone4">
              <img src="../img/aiImage/phone_4.png" />
            </div>
          </div>
          <div class="tabs">
            <div class="active" name="phone1">智慧多摄 <i class="el-icon-caret-left"></i></div>
            <div name="phone2">智慧多帧画质 <i class="el-icon-caret-left"></i></div>
            <div name="phone3">视频画质 <i class="el-icon-caret-left"></i></div>
            <div name="phone4">场景识别 <i class="el-icon-caret-left"></i></div>
            <el-button class="more phone-more" round @click="sceneClick">了解更多 <i class="el-icon-arrow-right"></i></el-button>
          </div>
        </div>
      </div>
    </div>

    <div class="content item-header">
      <img src="../img/aiImage/iot.png" />
      <div class="item-title">IOT解决方案</div>
      <div class="item-text">为安防监护、智能分析、人脸识别各类智能终端提供侦测分析能力</div>
    </div>
    <div class="content clear" style="margin-top: 52px">
      <div class="iot-img-1 iot-img">
        <img src="../img/aiImage/iot_1.png" >
      </div>
      <div class="iot-img-2 iot-img">
        <img src="../img/aiImage/iot_2.png" >
      </div>
      <div class="iot-img-3 iot-img">
        <img src="../img/aiImage/iot_3.png" >
      </div>
      <div class="iot" style="margin-left: 40px;width: 500px;float: left;">
        <div class="img-box" style="margin-top: 100px;margin-bottom: 60px;">
          <div>
            <img @click="iotShow = !iotShow" class="active" name="iot1" src="../img/aiImage/tab_iot_1.png" alt="">
            <img name="iot2" src="../img/aiImage/tab_iot_2.png" alt="">
            <img name="iot3" src="../img/aiImage/tab_iot_3.png" alt="">
          </div>
          <div class="img-box-line">
            <div class="line-bg">
              <div class="line-move-ai3"></div>
            </div>
          </div>
        </div>
        <div class="iot-msg iot1">
          <div style="font-size: 36px;">安防监护</div>
          <div class="msg-text">为智能摄像头、巡检机器人等终端提供侦测能力，包括人脸检测、人形侦测、区域入侵、摔倒监测等。</div>
          <el-button class="more" round @click="onlineClick('monitor')">了解更多 <i class="el-icon-arrow-right"></i></el-button>
        </div>
        <div class="iot-msg iot2">
          <div style="font-size: 36px;">智能辅助分析</div>
          <div class="msg-text">包括姿态识别、表情识别、性别年龄识别、疲劳侦测、人流量统计等，辅助智能设备进行场景事件分析。</div>
          <el-button class="more" round @click="onlineClick('analysis')">了解更多 <i class="el-icon-arrow-right"></i></el-button>
        </div>
        <div class="iot-msg iot3">
          <div style="font-size: 36px;">Face ID</div>
          <div class="msg-text">亿级人脸数据训练，精准快速识别对比，可应用于智能门锁、考勤机等智能终端。</div>
          <el-button class="more" round @click="onlineClick('faceId')">了解更多 <i class="el-icon-arrow-right"></i></el-button>
        </div>
      </div>
    </div>

    <div class="content item-header">
      <img src="../img/aiImage/core.png" />
      <div class="item-title">核心技术</div>
      <div class="item-text">提供跨平台SDK，适用全行业多场景</div>
    </div>
    <div class="content" style="margin-top: 60px; margin-bottom:180px;">
      <div class="core-item">
        <div class="core-img">
          <img class="core-img-png" src="../img/aiImage/core_1.png" />
          <div class="core-img-title">检测识别</div>
        </div>
        <div class="core-word">
          <div class="key-word word1" style="margin-top:24px;margin-left:80px;">人脸关键点</div>
          <div class="key-word word1" style="margin-top:24px;">人脸检测</div>
          <div class="key-word word1" style="margin-top:24px;">活体检测</div>
          <div class="key-word word1" style="margin-top:16px;margin-left:80px;">口罩识别</div>
          <div class="key-word word1" style="margin-top:16px;">人体关键点</div>
          <div class="key-word word1" style="margin-top:16px;">人手关键点</div>
        </div>
      </div>
      <div class="core-item">
        <div class="core-img">
          <img class="core-img-png" src="../img/aiImage/core_2.png" />
          <div class="core-img-title">人像美化</div>
        </div>
        <div class="core-word">
          <div class="key-word word2" style="margin-left:80px;">美颜</div>
          <div class="key-word word2">美体</div>
          <div class="key-word word2">补光</div>
          <div class="key-word word2">滤镜</div>
        </div>
      </div>
      <div class="core-item">
        <div class="core-img">
          <img class="core-img-png" src="../img/aiImage/core_3.png" />
          <div class="core-img-title">分割&分析</div>
        </div>
        <div class="core-word">
          <div class="key-word word2" style="margin-left:80px;">人像分割</div>
          <div class="key-word word2">头发分割</div>
          <div class="key-word word2">表情识别</div>
          <div class="key-word word2">手势识别</div>
        </div>
      </div>
      <div class="core-item">
        <div class="core-img">
          <img class="core-img-png" src="../img/aiImage/core_4.png" />
          <div class="core-img-title">趣味特效</div>
        </div>
        <div class="core-word">
          <div class="key-word word1" style="margin-top:24px;margin-left:80px;">AR贴纸</div>
          <div class="key-word word1" style="margin-top:24px;">3D面具</div>
          <div class="key-word word1" style="margin-top:24px;">AI换脸</div>
          <div class="key-word word1" style="margin-top:16px;margin-left:80px;">AI时光机</div>
          <div class="key-word word1" style="margin-top:16px;">漫画脸</div>
          <div class="key-word word1" style="margin-top:16px;">简笔画</div>
        </div>
      </div>
      <div class="core-item">
        <div class="core-img">
          <img class="core-img-png" src="../img/aiImage/core_5.png" />
          <div class="core-img-title">影像技术</div>
        </div>
        <div class="core-word">
          <div class="key-word word3" style="margin-top:24px;margin-left:80px;">场景识别</div>
          <div class="key-word word3" style="margin-top:24px;">畸变矫正</div>
          <div class="key-word word3" style="margin-top:24px;">双摄多摄</div>
          <div class="key-word word3" style="margin-top:24px;">超级夜景</div>
          <div class="key-word word3" style="margin-top:16px;margin-left:80px;">HDR</div>
          <div class="key-word word3" style="margin-top:16px;">降噪</div>
          <div class="key-word word3" style="margin-top:16px;">TopShot</div>
          <div class="key-word word3" style="margin-top:16px;">单摄人像</div>
        </div>
      </div>
      <div class="core-item">
        <div class="core-img">
          <img class="core-img-png" src="../img/aiImage/core_6.png" />
          <div class="core-img-title">安防监护</div>
        </div>
        <div class="core-word">
          <div class="key-word word3" style="margin-top:24px;margin-left:80px;">人形侦测</div>
          <div class="key-word word3" style="margin-top:24px;">人员逗留</div>
          <div class="key-word word3" style="margin-top:24px;">区域入侵</div>
          <div class="key-word word3" style="margin-top:24px;">越界侦测</div>
          <div class="key-word word3" style="margin-top:16px;margin-left:80px;">摔倒监测</div>
          <div class="key-word word3" style="margin-top:16px;">疲劳监测</div>
          <div class="key-word word3" style="margin-top:16px;">人员流量统计</div>
          <div class="key-word word3" style="margin-top:16px;">Face ID</div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import MyVideo from './aiImageDetail/components/myVideo'
export default {
  components: {
    Header,
    Footer,
    MyVideo
  },

  data() {
    return {
      show: false,
      iotShow: false,
      showGif: 'msg1',
      iotName: 'iot1',
      uiName: 'fun1',
      phoneName: 'phone1',
      phoneTimer: null,
      vedioStatus: false
    }
  },

  methods: {
    bindEvent () {
      const _this = this
      $('.phone-security .img-box img').click(function() {
        let name = $(this).attr('name')
        $(this).addClass('active').siblings().removeClass('active')
        _this.showGif = name
        _this.animate1()
      })   

      $('.iot .img-box img').click(function() {
        let name = $(this).attr('name')
        $(this).addClass('active').siblings().removeClass('active')
        _this.iotName = name
        _this.iotAnimate()
      })        

      $('.ui-tabs-a').click(function() {
        _this.uiName = $(this).attr('name')
        $(this).addClass('active').siblings().removeClass('active')
        _this.uiAnimate1()
      })

      $('.ai-phone-solution .tabs > div').click(function() {
        _this.phoneName = $(this).attr('name')
        $(this).addClass('active').siblings().removeClass('active')
        $('.ai-phone-solution .'+ _this.phoneName).fadeIn(1000).siblings('.img-div').hide()
        _this.phoneAnimate()
      })
      // 超清拍题-拖拽效果
      const container = $('.js-drag-pic')
      const draggerPic = $('.dragger-clear')

      let isResizing = false
      let startPosition = container.width()
      let startPositionDragger = container.width() - 16

      $(document).on('mousemove', '.dragger-clear', function(e) {
        e.preventDefault()
        if (!isResizing) return
        const $this = $(this)
        let offsetRight = e.clientX - container.offset().left - container.width()
        startPosition += offsetRight
        startPositionDragger += offsetRight  
        if (startPosition > 0 && startPosition < 560) {    
            container.css("width", startPosition + "px")
            $this.css('left', startPositionDragger + "px")
        }  
        // console.log(container.css("width") + '-------' + $this.css('left'))
      })
      .on('mousemove', '.drag-pic-wrap', function (e) {  
        e.preventDefault()
        if (!isResizing) return
        let offsetRight = e.clientX - container.offset().left - container.width() 
        startPosition += offsetRight
        startPositionDragger += offsetRight
        if (startPosition > 0 && startPosition < 560) {    
            container.css("width", startPosition + "px")  
            draggerPic.css('left', startPositionDragger + "px")
        }  
        // console.log(container.css("width")+'~~~~~~~'+$(this).css('left'))
      })
      .on('mouseup', '.dragger-clear', function (e) {  
          e.preventDefault()  
          isResizing = false
      })
      .on('mousedown', '.dragger-clear', function (e) {  
          e.preventDefault()
          isResizing = true
      })
    },  
    videoEvent (type) {
      if (type === 'end') {
        this.showGif = 'msg2'
        this.vedioStatus = false
        setTimeout(this.animate1(), 1500)
      } else if (type === 'play') {
        this.vedioStatus = true
      } else if (type === 'pause') {
        this.showGif = 'msg2'
        this.vedioStatus = false
        setTimeout(this.animate1(), 1500)
      }
    },
    animate1 () {
      const _this = this
      console.log(this.showGif)
      if (_this.vedioStatus) {
        return
      }
      $('.phone-security .img-box-line .line-move-ai1').stop().css("width", "0px")
      if (this.showGif == 'msg1') {
        $('.phone-security .img-box-line .line-bg').animate({marginLeft: "0px"})
        $('.edu-img-1').fadeIn(1000).siblings('.education-img').hide()
        $('.phone-security .msg1').fadeIn(1000).siblings('.phone-security-msg').hide()
      } else if (this.showGif == 'msg2') {
        _this.$nextTick(() => {
          _this.$refs.video.getVideoPos()
        })
        $('.phone-security .img-box-line .line-bg').animate({marginLeft: "80px"})
        $('.edu-img-2').fadeIn(1000).siblings('.education-img').hide()
        $('.phone-security .msg2').fadeIn(1000).siblings('.phone-security-msg').hide()
        // return
      } else if (this.showGif == 'msg3'){
        $('.phone-security .img-box-line .line-bg').animate({marginLeft: "160px"})
        $('.edu-img-3').fadeIn(1000).siblings('.education-img').hide()
        $('.phone-security .msg3').fadeIn(1000).siblings('.phone-security-msg').hide()
        // return
      } else if (this.showGif == 'msg4') {
        $('.phone-security .img-box-line .line-bg').animate({marginLeft: "240px"})
        $('.edu-img-4').fadeIn(1000).siblings('.education-img').hide()
        $('.phone-security .msg4').fadeIn(1000).siblings('.phone-security-msg').hide()
      }

      $('.phone-security .img-box-line .line-move-ai1').animate({width: "60px"},6000,function(){
        if (_this.showGif == 'msg1') {
          _this.showGif = 'msg2'
        } else if (_this.showGif == 'msg2') {
          _this.showGif = 'msg3'
        } else if (_this.showGif == 'msg3'){
          _this.showGif = 'msg4'
        } else if (_this.showGif == 'msg4') {
            _this.showGif = 'msg1'
        }
        if (_this.vedioStatus) {
          return
        }
        _this.animate1()
      })
    },
    iotAnimate () {
      const _this = this
      $('.iot .img-box-line .line-move-ai3').stop().css("width", "0px")
      if (this.iotName == 'iot1') {
        $('.iot .img-box-line .line-bg').animate({marginLeft: "0px"})
        $('.iot-img-1').fadeIn(1000).siblings('.iot-img').hide()
        $('.iot .iot1').fadeIn(1000).siblings('.iot-msg').hide()
      } else if (this.iotName == 'iot2') {
        $('.iot .img-box-line .line-bg').animate({marginLeft: "80px"})
        $('.iot-img-2').fadeIn(1000).siblings('.iot-img').hide()
        $('.iot .iot2').fadeIn(1000).siblings('.iot-msg').hide()
      } else if (this.iotName == 'iot3'){
        $('.iot .img-box-line .line-bg').animate({marginLeft: "160px"})
        $('.iot-img-3').fadeIn(1000).siblings('.iot-img').hide()
        $('.iot .iot3').fadeIn(1000).siblings('.iot-msg').hide()
      }

      $('.iot .img-box-line .line-move-ai3').animate({width: "60px"},6000,function(){
        if (_this.iotName == 'iot1') {
          _this.iotName = 'iot2'
        } else if (_this.iotName == 'iot2') {
          _this.iotName = 'iot3'
        } else if (_this.iotName == 'iot3'){
          _this.iotName = 'iot1'
        }
        _this.iotAnimate()
      })
    },
    uiAnimate1 () {
      const _this = this
      $('.ui-box-line .line-move-ai2').stop().css("width", "0px")
      if (this.uiName == 'fun1') {
        $('.ui-box-line .line-bg').animate({marginLeft: "32px"})
        $('.fun1').fadeIn(1000).siblings().hide()
      } else if (this.uiName == 'fun2') {
        $('.ui-box-line .line-bg').animate({marginLeft: "162px"})
        $('.fun2').fadeIn(1000).siblings().hide()
      } else if (this.uiName == 'fun3') {
        $('.ui-box-line .line-bg').animate({marginLeft: "292px"})
        $('.fun3').fadeIn(1000).siblings().hide()
      }

      $('.ui-box-line .line-move-ai2').animate({width: "96px"},6000,function(){
        if (_this.uiName == 'fun1') {
          _this.uiName = 'fun2'
          $('.ui-tabs-a').eq(1).addClass('active').siblings().removeClass('active')
        } else if (_this.uiName == 'fun2') {
          _this.uiName = 'fun3'
          $('.ui-tabs-a').eq(2).addClass('active').siblings().removeClass('active')
        } else if (_this.uiName == 'fun3') {
          _this.uiName = 'fun1'
          $('.ui-tabs-a').eq(0).addClass('active').siblings().removeClass('active')
        }
        _this.uiAnimate1()
      })
    },
    /* phoneAnimate () {
      const _this = this
      if (_this.phoneTimer) {
        clearInterval(_this.phoneTimer)
        _this.phoneTimer = null
      }
      _this.phoneTimer = setInterval(() => {
        if (document.visibilityState == 'hidden') {
          clearInterval(_this.phoneTimer)
          _this.phoneTimer = null
          return
        }
        _this.phoneName = $('.ai-phone-solution .tabs > div.active').attr('name')
        if (_this.phoneName == 'phone1') {
          $('.ai-phone-solution .tabs > div').eq(1).click()
        } else if (_this.phoneName == 'phone2') {
          $('.ai-phone-solution .tabs > div').eq(2).click()
        } else if (_this.phoneName == 'phone3') {
          $('.ai-phone-solution .tabs > div').eq(3).click()
        } else if (_this.phoneName == 'phone4') {
          $('.ai-phone-solution .tabs > div').eq(0).click()
        }
      }, 6000)
    }, */
    phoneAnimate () {
      $('.ai-phone-solution .tabs').stop()
      const _this = this
      _this.phoneName = $('.ai-phone-solution .tabs > div.active').attr('name')
      $('.ai-phone-solution .tabs').animate({fontSize: '16px'}, 6000, function() {
        if (_this.phoneName == 'phone1') {
          $('.ai-phone-solution .tabs > div').eq(1).click()
        } else if (_this.phoneName == 'phone2') {
          $('.ai-phone-solution .tabs > div').eq(2).click()
        } else if (_this.phoneName == 'phone3') {
          $('.ai-phone-solution .tabs > div').eq(3).click()
        } else if (_this.phoneName == 'phone4') {
          $('.ai-phone-solution .tabs > div').eq(0).click()
        }
      })
    },
    onlineClick (val) {
      /* 点击跳转事件 */
      if (typeof val === 'object') {
        return
      }
      const routeData = this.$router.resolve({ path: '/aiImage/' + val})
      window.open(routeData.href, '_blank')
      // this.$router.push('/aiImage/' + val)
    },
    sceneClick () {
      if (this.phoneName === 'phone1') {
        this.onlineClick('multiCamera')
      } else if (this.phoneName === 'phone2') {
        this.onlineClick('multiFrame')
      } else if (this.phoneName === 'phone3') {
        this.onlineClick('videoQuality')
      } else if (this.phoneName === 'phone4') {
        this.onlineClick('scene')
      }
    },
    /* setInterval页面切换-优化 */
    onChangePage () {
      document.onvisibilitychange = () => {
        console.log('page status', document.visibilityState)
        if (document.visibilityState == 'visible') {
          this.phoneAnimate()
        } else {
          clearInterval(this.phoneTimer)
        }
      }
    }
  },

  computed: {
    isSafari () {
      const userAgent = navigator.userAgent
      return userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") == -1
    }
  },

  mounted() {
    let html = document.querySelector("html");
    html.scrollTop = 0;
    // this.onChangePage()

    this.bindEvent()

    // 在线教育动画
    this.animate1()

    // 泛娱乐动画
    this.uiAnimate1()

    // 智慧手机动画
    this.phoneAnimate()

    // iot
    this.iotAnimate()
    
    

  }
}
</script>

<style lang="scss" scoped>
.top-bg {
  position: relative;
  background-image: url("../img/aiImage/aiImage.jpg");
  background-position: center;
  .header {
    position: absolute;
    top: 0;
    z-index: 100;
    height: 64px;
    width: 100%;
  }
  .top-text {
    font-size: 64px;
    color: #ffffff;
    padding-top: 220px;
    padding-left: 60px;
    box-sizing: border-box;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-sub-text {
    width: 800px;
    height: 72px;
    line-height: 36px;
    margin-top: 118px;
    font-size:18px;
    color: #ffffff;
    padding-left: 60px;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
}
.item-header {
  text-align: center;
  margin-top: 120px;
  .item-title {
    font-size: 36px;
    font-weight: bold;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .item-text {
    font-size: 16px;
    color: #1F1F1F;
    line-height: 28px;
    margin-top: 20px;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
}
.more {
    font-size: 18px;
    margin-top: 45px;
    border-radius: 30px;
    padding: 14px 26px;
    background: #fff;
  }
.phone-security {
  .phone-security-msg {
    display: none;
    .msg-title {
      font-family: "Microsoft YaHei","PingFangSC-Regular";
      font-size: 36px;
    }
    .msg-text {
      margin-top: 20px;
      font-size: 18px;
      line-height: 32px;
      width:440px;
      text-align: justify;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
  }
  .msg1 {
    display: block;
  }

  .img-box {
    >div {
      overflow: hidden;
    }
    img {
      cursor: pointer;
      padding-bottom: 20px;
      margin-right: 20px;
      float: left;
    }
    img.active {
      border-color: #007AFF;
    }
    .img-box-line {
      height: 2px;
      .line-bg {
        height: 2px;
        width: 60px;
        margin-left: 0;
        background: #99CAFF;
        .line-move-ai1 {
          height: 2px;
          width: 0;
          background: #007AFF;
        }
      }
    }
  }
}

.iot {
  .iot-msg {
    display: none;
    .msg-text {
      margin-top: 20px;
      font-size: 18px;
      line-height: 32px;
      width:440px;
      text-align: justify;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
  }
  .iot1 {
    display: block;
  }

  .img-box {
    >div {
      overflow: hidden;
    }
    img {
      cursor: pointer;
      padding-bottom: 20px;
      margin-right: 20px;
      float: left;
    }
    img.active {
      border-color: #007AFF;
    }
    .img-box-line {
      height: 2px;
      .line-bg {
        height: 2px;
        width: 60px;
        margin-left: 0;
        background: #99CAFF;
        .line-move-ai3 {
          height: 2px;
          width: 0;
          background: #007AFF;
        }
      }
    }
  }
}

.education-img,.iot-img {
  float: left;
  display: none;
  position: relative;
  width: 560px;
  height: 560px;
  img {
    position: absolute;
  }
}
.education-img.edu-img-1,.iot-img-1 {
  display: block
}

.video-bg {
  width: 560px;
  height: 560px;
  padding-top: 40px;
  background-image: url('../img/aiImage/immerse.png');
  position: relative;
}
.clear-bg {
  width: 560px;
  height: 560px;
  background-image: url('../img/aiImage/clear.png');
}
.normal-bg {
  position: relative;
  width: 560px;
  height: 350px;
  top: 105px;
  background-image: url('../img/aiImage/normal.png');
  .dragger-clear {
    position: absolute;
    cursor: e-resize;
  }
  .js-drag-pic {
    overflow: hidden;
    display: inline-block;
    height: 100%;
  }
}

.ui-tabs-a {
  cursor: pointer;
  font-size: 18px;
  color: #1F1F1F;
  margin: 0 30px;
  line-height: 36px;
  padding-bottom: 20px;
  display: inline-block;
  font-family: "Microsoft YaHei","PingFangSC-Regular";
}
.ui-tabs-a.active {
  font-size: 24px;
  color: #007AFF;
}
.ui-box-line {
  height: 2px;
  .line-bg {
    height: 2px;
    width: 96px;
    margin-left: 30px;
    background: #99CAFF;
    .line-move-ai2 {
      height: 2px;
      width: 0;
      background: #007AFF;
    }
  }
}

.recreation-text {
  text-align:center;
  margin-top:20px;
  font-size:18px;
  color: #1F1F1F;
  line-height: 32px;
  font-family: "Microsoft YaHei","PingFangSC-Regular";
}

.ai-phone-solution {
  img {
    display: block;
  }
  .ai-img-box {
    float: left;
    width: 906px;
    height: 513px;
    .img-div {
      width: 906px;
      height: 513px;
      display: none;
      position: relative;
    }
    .phone1 {
      display: block;
    }
  }
  .tabs {
    float: left;
    width: 200px;
    >div {
      cursor: pointer;
      line-height: 36px;
      margin-top: 20px;
      text-align: right;
      i {
        display: none;
        margin-right: -34px;
      }
    }
    .active {
      font-size: 24px;
      color: #0e73ff;
      i {
        display: inline;
      }
    }
    .phone-more {
      margin-left: 80px;
      margin-top: 240px;
    }
  }
}

.core-item {
  background-color: #fff;
  display: flex;
  .core-img {
    width: 200px;
    height: 200px;
    margin: 0 36px;
    text-align: center;
    .core-img-png {
      margin-top: 19px;
    }
    .core-img-title {
      margin-top: 6px;
      font-weight: bold;
      font-size: 24px;
      color: #1F1F1F;
      letter-spacing: 0;
      text-align: center;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
  }
  .core-word {
    margin-top: 20px;
    height: 160px;
    width: 800px;
    background-color: #E2E8EF;
    .key-word {
      background-color: #50A3FE;
      color: #fff;
      border-radius: 6px;
      font-size: 18px;
      text-align: center;
      display: inline-block;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
    .word1 {
      height: 48px;
      width: 180px;
      line-height: 48px;
      margin-left: 50px;
    }
    .word2 {
      width: 140px;
      height: 84px;
      line-height: 84px;
      margin-top: 38px;
      margin-left: 27px;
    }
    .word3 {
      width: 150px;
      height: 48px;
      line-height: 48px;
      margin-left: 13px;
    }
  }
}

</style>
