<template>
  <div id="video">
    <el-dialog :visible.sync="show" :show-close="false" width="960px">
      <div>
        <video width="960"  controls v-if="show">
          <source :src="url" type="video/mp4">
          您的浏览器不支持 HTML5 video 标签。
        </video>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogVisible: false,
    url: ''
  },
  data() {
    return {
      show: false
    }
  },
  mounted() {
    // var myVideo = document.getElementById("video1");
    // console.log(myVideo)
    // myVideo.play();
  },
  watch: {
    dialogVisible: function() {
      this.show = true
      setTimeout(function() {
        var myVideo = document.querySelector("video");
        myVideo.play()
      })
      
    }
  }
}
</script>

<style lang="scss">
#video {
  .el-dialog {
    background: #000;
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-dialog__header {
    padding: 0;
  }
}


</style>

<style lang="scss" scoped>
video {
  display: block;
}
</style>