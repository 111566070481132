<template>
  <div class="video-box" :id="wrapId">
    <img class="vedio-btn vedio-pause" src="../../../img/aiImage/vedio_pause.png" alt="">
    <img class="vedio-btn vedio-play" src="../../../img/aiImage/vedio_play.png" alt="" style="display:inline;">
    <video 
      preload
      :width="videoWidth" 
      :height="videoHeight"
      :id="id"
      class="video" 
      ref="video" 
      @pause="handPlay(2)" 
      @play="handPlay(1)"
      @loadedmetadata="getAudioLength()" 
      @timeupdate="videoTimeUpdate" 
      @click="playVideo">
      <source :src="videoUrl" type="video/mp4">
      该浏览器不支持video
    </video>
    <div class="video_control">
      <div class="progress">
        <el-slider 
          class="commonSlider" 
          :show-tooltip="false" 
          v-model="initVideo.currentTime"
          :max="initVideo.videoLength" 
          @change="changeVideoTime">
        </el-slider>
        <!-- <div class="time">
          <span>{{initVideo.currentTime}}</span>
          <span>{{initVideo.videoLength}}</span>
        </div> -->
      </div>
    </div>
    <div :id="maskId" class="mask" v-if="showMask && notHover && isStop"></div>
</div>
</template>

<script>
export default {
  props: ['videoWidth', 'videoHeight', 'videoUrl', 'showMask'],
  data() {
    const randomId = Math.round(Math.random() * 1000000)
    return {
      initVideo: {
        play: false,//播放还是暂停 true播放中
        videoLength: 0,//时长
        url: "./immerse.mp4",//视频课件Url
        currentTime: 0,//当前播放时间
        lastTime: null,//标记时间戳
        name: "",
      },
      x1: 0,
      x2: 0,
      y1: 0,
      y2: 0,
      id: 'videoPlay' + randomId,
      maskId: 'mask' + randomId,
      wrapId: 'wrap' + randomId,
      notHover: true,
      isStop: true
    }
  },
  methods: {
    getVideoPos () {
      const div = $('#' + this.id)   
      this.y1 = div.offset().top   // 上面两个的点的y值
      this.y2 = this.y1 + div.height()  // 下面两个点的y值
      this.x1 = div.offset().left  // 左边两个的点的x值
      this.x2 = this.x1 + div.width()   // 右边两个点的x的值
      console.log(this.x1, this.x2, this.y1, this.y2)
    },
    bindEvent () {
      const _this = this
      const myVideo = document.getElementById(this.id)
      const playIcon = $( '#' + _this.wrapId + ' .vedio-play')
      const pauseIcon = $('#' + _this.wrapId + ' .vedio-pause')

      myVideo.onended = function () {
        _this.$emit('videoEvent', 'end')
        pauseIcon.hide()
        playIcon.fadeIn(500)
        _this.isStop = true
      }
      let overFlag = false
      _this.getVideoPos()
      $(document)
      .on('mouseenter', '#' + _this.maskId, function(e) {
        _this.notHover = false
      })
      .on('mouseenter', '#' + _this.id, function(e) {
        e.preventDefault()
        _this.notHover = false
        if (overFlag) {
          return
        } else {
          overFlag = true
        }
        console.log('onmouseover')
        if (myVideo.paused) {
          pauseIcon.hide()
          playIcon.stop().fadeIn(1000)
        } else {
          playIcon.hide()
          pauseIcon.stop().fadeIn(1000)
        }
      })
      .on('mouseleave', '#' + _this.id, function(e) {
        const x = e.pageX
        const y = e.pageY
        console.log(x, y)
        e.preventDefault()
        if (x < _this.x1 || x > _this.x2 || y < _this.y1 || y > (_this.y2 - 24)) {
          overFlag = false
          _this.notHover = true
          console.log('mouseleave')
          if (myVideo.paused) {
            pauseIcon.hide()
            playIcon.show()
          } else {
            playIcon.hide()
            pauseIcon.stop().fadeOut(500)
          }
        }
      })
      .on('click', '#' + _this.wrapId + ' .vedio-pause', function(e) {
        e.preventDefault()
        myVideo.pause()
        _this.$emit('videoEvent', 'pause')
        pauseIcon.hide()
        playIcon.stop().fadeIn(1000)
        _this.isStop = true
      })
      .on('click', '#' + _this.wrapId + ' .vedio-play', function(e) {
        e.preventDefault()
        myVideo.play()
        _this.$emit('videoEvent', 'play')
        playIcon.hide()
        pauseIcon.stop().fadeIn(1000)
        _this.isStop = false
      })
    },
    playVideo() {
      //播放视频
      const playIcon = $('#' + this.wrapId + ' .vedio-play')
      const pauseIcon = $('#' + this.wrapId + ' .vedio-pause')
      if (this.initVideo.play) {
        this.$refs.video.pause()
        this.$emit('videoEvent', 'pause')
        pauseIcon.hide()
        playIcon.stop().fadeIn(1000)
        this.isStop = true
      } else {
        Math.abs(this.initVideo.currentTime - this.$refs.video.currentTime) > 2 ? this.$refs.video.currentTime = this.initVideo.currentTime : ''
        this.$refs.video.play()
        this.$emit('videoEvent', 'play')
        playIcon.hide()
        pauseIcon.stop().fadeIn(1000)
        this.$refs.video.muted = false
        this.isStop = false
      }
    },
    videoTimeUpdate() {
    //更新视频时间。节流，每秒触发一次
      let nowTime = Date.now()
      let gapTime = 200
      if (!this.initVideo.lastTime || nowTime - this.initVideo.lastTime > gapTime) {
        if (this.$refs.video) {
          let time = this.$refs.video.currentTime
          if (this.initVideo.videoLength - time < 0.2) {
            this.initVideo.currentTime = this.initVideo.videoLength
          } else {
            this.initVideo.currentTime = time
          }
          // console.log(time)
        }
        this.initVideo.lastTime = nowTime
      }
    },
    changeVideoTime(val) {
      //改变视频时间
      console.log(val, 'video')
      if (this.$refs.video) {
        this.$refs.video.currentTime = val
      }
    },
    getAudioLength() {
      //获取音视频时长
      this.initVideo.videoLength = this.$refs.video.duration
      console.log('获取视频时长', this.initVideo.videoLength)
    },
    handPlay(status) {
      //音视频播放暂停 status 1播放 2暂停
      status == 1 ? this.initVideo.play = true : this.initVideo.play = false
    }
  },
  mounted() {
    this.bindEvent()
  }
}
</script>

<style lang="scss">
  .el-slider__runway {
    margin-bottom: 0;
    background-color: #343434;
    height: 8px;
  }
  .el-slider__bar {
    background-color: #007AFF;
  }
  .el-slider__button {
    width: 10px;
    height: 10px;
  }
  .el-slider__button-wrapper {
    display: none;
  }
  .video-box {
    position: relative;
    .video_control {
      position: absolute;
      bottom: 0;
      z-index: 999;
      width: 100%;
      .commonSlider {
        margin-bottom: 0;
      }
    }
  }
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .vedio-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    display: none;
    z-index: 1000;
  }
  .vedio-play {
    animation: bounce-in 5s infinite;
  }

  @keyframes bounce-in {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
</style>