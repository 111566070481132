<template>
  <div>
    <div class="top-bg">
      <Header class="header" />
      <div class="content">
        <div class="top-text">安防监护</div>
        <div class="top-sub-text">
          360AI算法基于深度学习及海量数据，深度结合安防监护应用场景，为公共安全提供智能、高效的视觉能力。
        </div>
        <div class="top-btn">
          <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
          <el-button class="coop-btn" round @click="consultClick">合作咨询</el-button>
        </div>
      </div>
    </div>

    <div class="content item-header">
      <div class="item-title">方案介绍</div>
      <div class="item-text">为智能摄像头、巡检机器人等终端提供侦测能力，包括身份审核、可疑情况侦查、安全事件报警等</div>
    </div>
    <div class="content clear" style="margin-top: 60px">
      <img src="../../img/aiImage/iot/solution.png" >
    </div>

    <div class="content item-header">
      <div class="item-title">核心技术</div>
    </div>
    <div class="content clear" style="margin-top: 60px">
     <div class="code-item">
       <div class="code-img">
         <img src="../../img/aiImage/iot/code_1.png" alt="">
       </div>
       <div class="code-text">
         <div class="code-title">人脸检测</div>
         <div class="code-content">检测图像中的人脸并标记出人脸坐标，支持多人人脸检测追踪，可应用于安防区域人脸侦测、流量、属性分析等场景。</div>
         <div class="code-btn">
           <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
         </div>
       </div>
     </div>
    </div>
    <div class="content clear" style="margin-top: 60px">
     <div class="code-item">
       <div class="code-img">
         <img src="../../img/aiImage/iot/code_2.png" alt="">
       </div>
       <div class="code-text">
         <div class="code-title">人形侦测</div>
         <div class="code-content">在传统移动侦测基础上增加人形检测过滤，避免因光线变化、物体移动造成的误检警报，可应用于居家防盗、门店迎宾、安全提醒等场景。</div>
         <div class="code-btn">
           <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
         </div>
       </div>
     </div>
    </div>
    <div class="content clear" style="margin-top: 60px">
     <div class="code-item">
       <div class="code-img">
         <img src="../../img/aiImage/iot/code_3.png" alt="">
       </div>
       <div class="code-text">
         <div class="code-title">人员逗留</div>
         <div class="code-content">通过获取监控区域内各个人员的运动轨迹和滞留时间，判断是否发生逗留徘徊行为，为银行、工厂、物业等场景提供可疑人员预警。</div>
         <div class="code-btn">
           <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
         </div>
       </div>
     </div>
    </div>
    <div class="content clear" style="margin-top: 60px">
     <div class="code-item">
       <div class="code-img">
         <img src="../../img/aiImage/iot/code_4.png" alt="">
       </div>
       <div class="code-text">
         <div class="code-title">区域入侵侦测</div>
         <div class="code-content">当有物品或人员进入设定区域时可对其跟踪识别，根据判断结果联动报警，可应用于高危场所、私人住宅、专用场地等区域保护。</div>
         <div class="code-btn">
           <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
         </div>
       </div>
     </div>
    </div>
    <div class="content clear" style="margin-top: 60px">
     <div class="code-item">
       <div class="code-img">
         <img src="../../img/aiImage/iot/code_5.png" alt="">
       </div>
       <div class="code-text">
         <div class="code-title">越界侦测</div>
         <div class="code-content">侦测视频图像中是否有物体跨越指定界限并联动报警，例如河边界限、围墙边界、门口等，减少安全隐患，避免非法越界。</div>
         <div class="code-btn">
           <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
         </div>
       </div>
     </div>
    </div>
    <div class="content clear" style="margin-top: 60px">
     <div class="code-item">
       <div class="code-img">
         <img src="../../img/aiImage/iot/code_6.png" alt="">
       </div>
       <div class="code-text">
         <div class="code-title">摔倒监测</div>
         <div class="code-content">通过人体特征及动作识别监测是否发生摔倒事件，可应用于公共安全、居家安全监测等场景，为老弱者和紧急事故提供及时呼救。</div>
         <div class="code-btn">
           <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
         </div>
       </div>
     </div>
    </div>


    <div class="content item-header">
      <div class="item-title">应用场景</div>
    </div>
    <div class="content clear" style="margin-top: 60px">
      <div class="sence-wrap">
        <div><img src="../../img/aiImage/iot/sence_1.png" alt=""></div>
        <div style="margin-left: 40px;"><img src="../../img/aiImage/iot/sence_2.png" alt=""></div>
        <div style="margin-left: 40px;"><img src="../../img/aiImage/iot/sence_3.png" alt=""></div>
        <div style="margin-top: 40px;"><img src="../../img/aiImage/iot/sence_4.png" alt=""></div>
        <div style="margin: 40px 0 0 40px;"><img src="../../img/aiImage/iot/sence_5.png" alt=""></div>
        <div style="margin: 40px 0 0 40px;"><img src="../../img/aiImage/iot/sence_6.png" alt=""></div>
      </div>
    </div>

    <div class="content item-header" style="margin-bottom: 120px;">
      <contact></contact>
    </div>
    <dialog-consult ref="consult"></dialog-consult>
    <dialog-apply ref="apply"></dialog-apply>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import contact from "./components/contact"
import DialogConsult from './components/dialogConsult.vue';
import DialogApply from './components/dialogApply'
export default {
  components: {
    Header,
    Footer,
    contact,
    DialogConsult,
    DialogApply
  },

  data() {
    return {
    }
  },

  methods: {
    consultClick () {
      this.$refs.consult.show()
    },
    applyClick () {
      this.$refs.apply.show()
    }
  },

  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
.top-bg {
  position: relative;
  background-image: url("../../img/aiImage/iot/banner_monitor.jpg");
  background-position: center;
  .header {
    position: absolute;
    top: 0;
    z-index: 100;
    height: 64px;
    width: 100%;
  }
  .top-text {
    font-size: 64px;
    color: #ffffff;
    padding-top: 220px;
    padding-left: 60px;
    box-sizing: border-box;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-sub-text {
    width: 600px;
    height: 72px;
    line-height: 36px;
    margin-top: 40px;
    font-size:18px;
    color: #ffffff;
    padding-left: 60px;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-btn {
    padding-left: 60px;
    margin-top: 72px;
    .apply-btn {
      font-size: 18px;
      color: #fff;
      padding: 14px 37px;
      background-color: #007aff;
      border: none;
      border-radius: 25px;
    }
    .coop-btn {
      font-size: 18px;
      color: #0059FF;
      padding: 14px 37px;
      margin-left: 72px;
      border: none;
      border-radius: 25px;
    }
  }
}

.code-item {
  display: flex;
  .code-text {
    background-color: #fff;
    width: 500px;
    .code-title {
      font-size: 36px;
      color: #1F1F1F;
      margin: 40px 0 0 60px;
    }
    .code-content {
      font-size: 16px;
      color: #1F1F1F;
      letter-spacing: 0;
      line-height: 28px;
      width: 400px;
      margin: 20px 0 0 60px;
    }
    .code-btn {
      margin: 58px 0 0 60px;
    }
  }
}

.sence-wrap {
  display: flex;
  flex-wrap: wrap;
}

.apply-btn {
    font-size: 18px;
    color: #2d2d2d;
    padding: 14px 37px;
    background-color: #fff;
    border: 1px solid #2d2d2d;
    border-radius: 25px;
    &:hover {
      border: 1px solid #0d81ff;
      color: #0d81ff;
    }
  }

</style>
