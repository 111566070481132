<template>
  <div>
    <div class="top-bg">
      <Header class="header" />
      <div class="content">
        <div class="top-text">趣味特效</div>
        <div class="top-sub-text">
          包括AR贴纸、3D面具、AI换脸、AI时光机、漫画脸、简笔画等，娱乐互动趣味无穷。
        </div>
        <div class="top-btn">
          <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
          <el-button class="coop-btn" round @click="consultClick">合作咨询</el-button>
        </div>
      </div>
    </div>

    <div class="content item-header">
      <div class="item-title">AR贴纸</div>
      <div class="item-text">智能识别脸部表情和动作呈现互动特效，支持2D、3D贴纸类型，免费提供上千套素材。</div>
    </div>
    <div class="content clear" style="margin-top: 60px">
      <img src="../../img/aiImage/onlineEdu/fun/special_1.png" >
    </div>

    <div class="content item-header">
      <div class="item-title">3D面具</div>
      <div class="item-text">创新结合人脸融合+AR贴纸技术，实时生成生动立体的萌宠、卡通形象。</div>
    </div>
    <div class="content clear" style="margin-top: 60px">
      <img src="../../img/aiImage/onlineEdu/fun/special_2.png" >
    </div>

    <div class="content item-header">
      <div class="item-title">AI换脸</div>
      <div class="item-text">只需一张照片即可实现换发、试装、合影人脸融合效果，支持自定义人脸替换。</div>
    </div>
    <div class="content clear" style="margin-top: 60px">
      <img src="../../img/aiImage/onlineEdu/fun/special_3.png" >
    </div>

    <div class="content item-header">
      <div class="item-title">AI时光机</div>
      <div class="item-text">风靡全网的AI时光魔法特效，让你瞬间变老变童颜。</div>
    </div>
    <div class="content clear" style="margin-top: 60px">
      <img src="../../img/aiImage/onlineEdu/fun/special_4.png" >
    </div>

    <div class="content item-header">
      <div class="item-title">漫画脸</div>
      <div class="item-text">帮助用户生成具有个人特征的漫画头像，多种风格可选。</div>
    </div>
    <div class="content clear" style="margin-top: 60px">
      <img src="../../img/aiImage/onlineEdu/fun/special_5.png" >
    </div>

    <div class="content item-header">
      <div class="item-title">AI简笔画</div>
      <div class="item-text">一键生成图像简笔画，准确抓出人体轮廓及重点细节，线条流畅、形象生动。</div>
    </div>
    <div class="content clear" style="margin-top: 60px">
      <img src="../../img/aiImage/onlineEdu/fun/special_6.png" >
    </div>

    <div class="content item-header" style="margin-bottom: 120px;">
      <contact></contact>
    </div>
    <dialog-consult ref="consult"></dialog-consult>
    <dialog-apply ref="apply"></dialog-apply>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import contact from "./components/contact"
import DialogConsult from './components/dialogConsult.vue';
import DialogApply from './components/dialogApply'
export default {
  components: {
    Header,
    Footer,
    contact,
    DialogConsult,
    DialogApply
  },

  data() {
    return {
    }
  },

  methods: {
    consultClick () {
      this.$refs.consult.show()
    },
    applyClick () {
      this.$refs.apply.show()
    }
  },

  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
.top-bg {
  position: relative;
  background-image: url("../../img/aiImage/onlineEdu/fun/header_2.jpg");
  background-position: center;
  .header {
    position: absolute;
    top: 0;
    z-index: 100;
    height: 64px;
    width: 100%;
  }
  .top-text {
    font-size: 64px;
    color: #ffffff;
    padding-top: 220px;
    padding-left: 60px;
    box-sizing: border-box;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-sub-text {
    width: 600px;
    height: 72px;
    line-height: 36px;
    margin-top: 40px;
    font-size:18px;
    color: #ffffff;
    padding-left: 60px;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-btn {
    padding-left: 60px;
    margin-top: 72px;
    .apply-btn {
      font-size: 18px;
      color: #fff;
      padding: 14px 37px;
      background-color: #007aff;
      border: none;
      border-radius: 25px;
    }
    .coop-btn {
      font-size: 18px;
      color: #0059FF;
      padding: 14px 37px;
      margin-left: 72px;
      border: none;
      border-radius: 25px;
    }
  }
}

</style>
