const apiHead = ""
export const api = {
  newsList: "/news/list",
  newsInfo: "/news/info",
  newsLogin: "/news/login",
  newsAdd: "/news/add",
  newsStatus: "/news/status",
  newsLogout: "/news/logout",
  newsDelete: "/news/delete",
  newsUpdate: "/news/update"
};
for (const key in api) {
  api[key] = apiHead + api[key]
}



export default api;