const zh = {
  home: {
    partner: [
      
    ]
  }
}


export {
  zh
}