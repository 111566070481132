<template>
  <div class="news-box">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="首页新闻" name="1">
        <el-table
          :data="newsList"
          border
          style="width: 100%"
          stripe
          height="70vh"
        >
          <el-table-column prop="date" label="日期" width="180">
          </el-table-column>
          <el-table-column prop="title" label="标题" width="180">
          </el-table-column>
          <el-table-column prop="source" label="来源"> </el-table-column>
          <el-table-column prop="abstr" label="摘要"> </el-table-column>
          <el-table-column prop="sort" label="排序"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button
                @click="viewHandleClick(scope.row)"
                type="text"
                size="small"
                >查看</el-button
              >
              <el-button type="text" size="small" @click="newsEdit(scope.row)"
                >编辑</el-button
              >
              <el-button type="text" size="small" @click="newsDelete(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="新闻列表" name="0">
        <el-table
          :data="newsList"
          border
          style="width: 100%"
          stripe
          height="70vh"
        >
          <el-table-column prop="date" label="日期" width="180">
          </el-table-column>
          <el-table-column prop="title" label="标题" width="180">
          </el-table-column>
          <el-table-column prop="source" label="来源"> </el-table-column>
          <el-table-column prop="abstr" label="摘要"> </el-table-column>
          <el-table-column prop="sort" label="排序"> </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <div class="pagination">
      <el-pagination
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        :current-page="pageNum"
        layout="prev, pager, next, jumper"
        :total="total"
        background
      >
      </el-pagination>
    </div>
    <div class="footer"></div>
    <el-dialog
      title="预览"
      :visible.sync="dialogVisible"
      :before-close="beforeClose"
      width="80%"
    >
      <div v-loading="viewLoading">
        <div
          class="content-box ql-editor ql-line-height"
          v-html="htmlData"
        ></div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import api from "@/server/api";
export default {
  data() {
    return {
      activeName: "1",
      newsList: [],
      total: 0,
      pageSize: 10,
      pageNum: 1,
      dialogVisible: false,
      htmlData: "",
      viewLoading: false
    };
  },
  methods: {
    newsEdit(data) {
      this.$router.push({ path: "/manager/edit", query: { id: data.id } });
    },
    newsDelete(data) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax.post(api.newsDelete, {id: data.id}).then(res => {
          this.pageNum = 1;
          this.getNewsList();
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        });
      })
    },
    /* dialog */
    beforeClose() {
      this.dialogVisible = false;
      this.htmlData = "";
    },
    viewHandleClick(data) {
      this.dialogVisible = true;
      this.getNewsInfo(data.id);
    },
    /* 分页 */
    handleCurrentChange(currentPage) {
      this.pageNum = currentPage;
      this.getNewsList();
    },
    /* tab */
    handleClick(tab, e) {
      this.pageNum = 1;
      const params = { pageNum: 1, type: tab.name };
      this.getNewsList(params);
    },
    /*  */
    getNewsList(reqData = {}) {
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        type: this.activeName,
        ...reqData
      };
      this.$ajax.post(api.newsList, params).then(res => {
        if (res.data.code == 0) {
          this.newsList = res.data.data;
          this.total = res.data.total;
        }
      });
    },
    getNewsInfo(id) {
      this.viewLoading = true;
      this.$ajax.post(api.newsInfo, { id }).then(res => {
        if (res.data.code == 0) {
          this.htmlData = res.data.data.htmlData;
        }
      });
      this.viewLoading = false;
    }
  },
  mounted() {
    this.getNewsList();
  }
};
</script>
<style lang="scss">
.content-box {
  min-height: 50vh;
  p {
    margin-bottom: -6px;
  }
}
.ql-editor.ql-line-height {
  margin: 0 auto;
  overflow: scroll;
  width: 740px;
  line-height: 1.8;
}
</style>
<style lang="scss" scoped>
.news-box {
  background-color: #fff;
  padding: 20px;
  min-height: calc(100vh - 150px);
  .pagination {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
  }
  .footer {
    // height: 0px;
  }
}
</style>
