<template>
  <div>
    <div class="top-bg">
      <Header class="header" />
      <div class="top-text content">
        <div class="img-title">智慧 安全<br />OS 5.0生态服务</div>
        <div class="img-bottom">OS 5.0以安全为基因，创新为骨架，围绕用户需求，革新视觉设计，更迭应用模块，落地用户个性化应用，为泛智能硬件领域提供高可靠的系统性解决方案。</div>
      </div>
    </div>

    <div class="content" style="text-align: center;margin-top: 120px;">
      <img src="../img/ecology/safety.png" />
      <div style="font-size: 36px;font-weight: bold;">全天候多维度保卫手机安全</div>
      <div class="some-msg">360OS针对系统底层进行大量性能优化，响应更迅速，动画更流畅，运行更稳定。<br />同时围绕用户需求，不断深挖各种智慧场景，让用户尽享全场景智慧生活。</div>
    </div>

    <div class="content clear security-wrap" style="margin-top: 52px">
      <div>
        <img src="../img/ecology/31.png" >
      </div>
      <div class="phone-security">
        <div class="img-box">
          <img src="../img/ecology/1.png" alt="">
        </div>
        <div class="security-title">私密系统</div>
        <div class="security-text">开辟独立的私密系统，用户可选择多种加密方式对其加密，同时可通过私密系统的影子系统来保护真实私密系统内的信息安全。</div>
      </div>
    </div>

    <div class="content security-wrap" style="margin-top: 60px">
      <div class="phone-security" style="margin-left: 60px;">
        <div class="img-box">
          <img src="../img/ecology/3.png" alt="">
        </div>
        <div class="security-title">财产系统</div>
        <div class="security-text">基于“沙箱”隔离技术，构建全新的财产系统，将财产类应用数据独立加密存放，构建专属安全网络通道。</div>
      </div>
      <div style="margin-left: 40px;">
        <img src="../img/ecology/29.png" >
      </div>
    </div>

    <div class="content security-wrap" style="margin-top: 60px">
      <div>
        <img src="../img/ecology/30.png" >
      </div>
      <div class="phone-security">
        <div class="img-box">
          <img src="../img/ecology/2.png" alt="">
        </div>
        <div class="security-title">敏感权限提醒</div>
        <div class="security-text">通过系统技术实时监控APP运行状况，如出现应用静默使用摄像头、麦克风等权限获取用户隐私等高危行为时，及时通知提醒用户。</div>
      </div>
    </div>

    <div class="content" style="text-align: center;margin-top: 120px;">
      <img src="../img/ecology/4.png" />
      <div style="font-size: 36px;font-weight: bold;">智慧后台 智能应用全能助手</div>
      <div class="some-msg">360OS针对系统底层进行大量性能优化，响应更迅速，动画更流畅，运行更稳定。</div>
    </div>
    <div class="content security-wrap" style="margin-top: 110px">
      <div class="phone-security" style="margin-left: 60px;">
        <div class="img-box">
          <img src="../img/ecology/assis_1.png" alt="">
        </div>
        <div class="security-title" style="font-size:40px;">Hybrid Engine智慧引擎</div>
        <div class="security-text" style="width:460px;">实现更智能的硬件资源调用机制：智能检测，合理分配 CPU 等资源，保障当前应用的流畅性；根据应用的使用情况进行智能分析，智能分配后台资源平衡性能与功耗，提升续航能力。</div>
      </div>
      <div style="margin-left: 40px;">
        <img src="../img/ecology/36.png" >
      </div>
    </div>
    <div class="content security-wrap" style="margin-top: 60px">
      <div>
        <img src="../img/ecology/35.png" >
      </div>
      <div class="phone-security">
        <div class="img-box">
          <img src="../img/ecology/assis_2.png" alt="">
        </div>
        <div class="security-title" style="font-size:40px;">全局冷藏</div>
        <div class="security-text" style="width:460px;">创新引入AI学习技术，将长时间不用的应用原地冷藏，减少内存占用，避免后台频繁通知和偷跑流量，从根本上解决不常用的应用对系统性能的影响。</div>
      </div>
    </div>
    <div class="content security-wrap" style="margin-top: 60px">
      <div class="phone-security" style="margin-left: 60px;">
        <div class="img-box">
          <img src="../img/ecology/assis_3.png" alt="">
        </div>
        <div class="security-title" style="font-size:40px;">快捷中心</div>
        <div class="security-text" style="width:460px;"> 一键快捷将用户常用功能聚合成快捷方式，轻松便捷，减少用户操作。<br />智慧快捷无需用户动手，自动帮助用户完成快捷指令。</div>
      </div>
      <div style="margin-left: 40px;">
        <img src="../img/ecology/5.png" >
      </div>
    </div>


    <div class="content" style="text-align: center;margin-top: 120px;">
      <img src="../img/ecology/9.png" />
      <div style="font-size: 36px;font-weight: bold;">全新升级的系统UI</div>
      <div class="some-msg">优化界面排版，突出主要内容，打造风格更轻快、交互更轻松、视觉更轻盈、更符合全面屏时代的全新界面美学。</div>
    </div>
    <div class="content">
      <div style="text-align: center;font-size: 40px;margin: 60px 0 24px 0;">全新图标</div>
    </div>
    <div class="content">
      <img src="../img/ecology/10.png" alt="">
    </div>
    <div class="content" style="text-align:center;margin-top:40px;font-size:18px;color: #1F1F1F;line-height: 32px;">
      去除丰富的图像样式，保留基础图形，加强图标的隐喻和识别度，<br/>细节更为严谨，空间层级更为分明，进一步提升屏幕显示质量。
    </div>

    <div class="content">
      <div style="text-align: center;font-size: 40px;margin: 120px 0 24px 0;">去线留白</div>
    </div>
    <div class="content">
      <img src="../img/ecology/28.png" alt="">
    </div>
    <div class="content" style="text-align:center;margin-top:40px;font-size:18px;color: #1F1F1F;line-height: 32px;">
      在视觉和交互上讲究舒适自然，让界面更加轻盈，<br/>更易聚焦内容，解决全面屏时代手机屏幕变长带来的操作困惑，让操作更便捷。
    </div>

    <div class="content" style="margin-top:200px;position: relative;">
      <img src="../img/ecology/ad.jpg">
      <el-button @click="toCommercial" class="play-btn" round plain>立即体验 <i class="el-icon-caret-right"></i></el-button>
    </div>
    <div class="content">
      <div class="title">
        <b>为合作伙伴赋能</b>
      </div>
      <div class="text" style="margin-bottom: 60px;">
        创新技术、个性化服务为行业合作伙伴挖掘全新商业价值。
      </div>
      <div class="ai-img clear" style="margin-bottom: 120px;">
        <div>
          <img src="../img/ecology/12.svg" alt="">
          <div style="font-size: 24px;margin-bottom: 22px;font-weight: bold;">个性化技术</div>
          <div style="font-size: 14px;width:padding: 0 20px;line-height: 24px;">多样化智能应用，让系统更流畅</div>
        </div>
        <div>
          <img src="../img/ecology/13.svg" alt="">
          <div style="font-size: 24px;margin-bottom: 22px;font-weight: bold;">设计专家</div>
          <div style="font-size: 14px;padding: 0 20px;line-height: 24px;">在音效设计、视觉UI领域为终端提供定制化设计服务</div>
        </div>
        <div>
          <img src="../img/ecology/14.svg" alt="">
          <div style="font-size: 24px;margin-bottom: 22px;font-weight: bold;">技术顾问式服务</div>
          <div style="font-size: 14px;padding: 0 20px;line-height: 24px;">利用下沉式服务为用户提供源码级方案、人力支持、技术顾问</div>
        </div>
        <div>
          <img src="../img/ecology/15.svg" alt="">
          <div style="font-size: 24px;margin-bottom: 22px;font-weight: bold;">硬核安全</div>
          <div style="font-size: 14px;padding: 0 20px;line-height: 24px;">汇聚360集团安全基因，领先行业安全设计</div>
        </div>
      </div>
    </div>
   
    <Footer />
    <Video :url="require('../img/industry/os-iot.mp4')" :dialogVisible="videostart" />
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
import Video from '@/components/video'
import Progress from '@/components/progress'

export default {
  components: {
    Header,
    Footer,
    Video,
    Progress
  },
  data() {
    return {
      videostart: false
    }
  },
  methods: {
    toCommercial () {
      window.open('http://qkwanhui.360os.com/qkbrowser/index.html')
    }
  },
  mounted() {
    let html = document.querySelector('html')
    html.scrollTop = 0
  }
}
</script>

<style lang="scss" scoped>
  .security-wrap {
    display: flex;
  }

.play-btn {
  background: #ff6c41;
  color: #fff;
  position: absolute;
  top: 319px;
  left: 59px;
  &:hover {
    background: #ff6c41;
    color: #fff;
    border: none;
  }
}

.ai-img {
  >div {
    background: #fff;
    color: #1F1F1F;
    float: left;
    width: 260px;
    height: 360px;
    text-align: center;
    margin-right: 40px;
    &:last-child {
      margin-right: 0;
    }
    img {
      margin: 60px 0 41px 0;
    }
  }
}


.some-msg {
  width:960px;font-size: 16px;color: #1F1F1F;line-height: 28px;margin-top: 20px;margin-left: auto;margin-right: auto;
}
.phone-security {
  margin-left: 40px;
  width: 500px;
  .security-title {
    margin-top: 23px;
    font-size: 48px;
    color: #000000;
    font-weight: 400;
  }
  .security-text {
    font-size: 18px;
    color: #1F1F1F;
    letter-spacing: 0;
    text-align: justify;
    line-height: 32px;
    font-weight: 400;
    margin-top: 18px;
  }
  .img-box {
   margin-top: 100px;
  }
}

.top-bg {
  position: relative;
  background-image: url('../img/ecology/top_bg.jpg');
  background-position: center;
  .header {
    position: absolute;
    top: 0;
    z-index: 100;
    height: 64px;
    width: 100%;
  }
  .top-text {
    color: #FFFFFF;
    .img-title {
      padding-top: 180px;
      padding-left: 60px;
      font-size: 64px;
    }
    .img-bottom {
      padding-left: 60px;
      max-width: 900px;
      padding-top: 150px;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 36px;
    }
  }
}
.title {
  padding-left: 20px;
  margin-top: 210px;
  margin-bottom: 40px;
  font-size: 36px;
  line-height: 67px;
  color: #000000;
}
.text {
  padding-left: 20px;
  margin-bottom: 150px;
  font-size: 16px;
  color: #1F1F1F;
  letter-spacing: 0;
  line-height: 28px;
}

</style>