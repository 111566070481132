<template>
    <div id="app">
        <div class="header">
            <a href="/"><img src="@/img/logo_blue.svg" alt=""></a>
        </div>
        <div class="text-content">
            <div class="title">第三方SDK目录</div>
            <pre style="margin-bottom:10px;">
                为保证系统相关功能的实现与应用安全稳定的运行，我们可能会接入由第三方提供的软件开发包或其他内容实现相关目的。
            </pre>
            <pre style="margin-bottom:10px;">
                第三方SDK目录如下表所示：
            </pre>
            <table class="table">
                <thead>
                   <tr>
                       <th class="table-header" style="min-width:100px;">第三方SDK名称</th>
                       <th class="table-header" style="min-width:100px;">涉及的个人信息及权限</th>
                       <th class="table-header" style="min-width:100px;">使用目的</th>
                       <th class="table-header">备注（第三方平台隐私政策链接）</th>
                   </tr>
                </thead>
               <tbody class="table-body">
                   <tr>
                       <td>高德SDK</td>
                       <td>设备标识信息、定位信息</td>
                       <td>实现定位</td>
                       <td><a href="https://lbs.amap.com">https://lbs.amap.com</a></td>
                   </tr>
                   <tr>
                       <td>移动安全联盟统一SDK</td>
                       <td>设备信息、访问网络、网络状态</td>
                       <td>个性化推荐</td>
                       <td><a href="http://www.msa-alliance.cn/col.jsp?id=120">http://www.msa-alliance.cn/col.jsp?id=120</a></td>
                   </tr>
                   <tr>
                       <td>炬焰SDK</td>
                       <td>访问网络权限、设备信息、定位信息</td>
                       <td>个性化推荐</td>
                       <td><a href="https://dp.bytedance.com/docs/7759/torchlight-privacy-policy/">https://dp.bytedance.com/docs/7759/torchlight-privacy-policy/</a></td>
                   </tr>
                   <tr>
                       <td>百度网盟SDK</td>
                       <td>访问网络权限、设备信息、定位信息</td>
                       <td>个性化推荐</td>
                       <td><a href="https://union.baidu.com/bqt/#/policies">https://union.baidu.com/bqt/#/policies</a></td>
                   </tr>
                   <tr>
                       <td>快手SDK</td>
                       <td>访问网络权限、设备信息、定位信息</td>
                       <td>个性化推荐</td>
                       <td class="empty">--</td>
                   </tr>
                   <tr>
                       <td>穿山甲SDK</td>
                       <td>访问网络权限、设备信息、定位信息</td>
                       <td>广告投放</td>
                       <td><a href="https://www.pangle.cn/?from=partner.toutiao.com&">https://www.pangle.cn/?from=partner.toutiao.com&</a></td>
                   </tr>
                   <tr>
                       <td>广点通SDK</td>
                       <td>访问网络权限、设备信息、定位信息</td>
                       <td>广告投放</td>
                       <td><a href="https://e.qq.com/ads/">https://e.qq.com/ads/</a></td>
                   </tr>
                   <tr>
                       <td>聚效SDK</td>
                       <td>访问网络权限、设备信息、定位信息</td>
                       <td>广告投放</td>
                       <td><a href="http://pages.juxiao.mediav.com/introduction/">http://pages.juxiao.mediav.com/introduction/</a></td>
                   </tr>
                   <tr>
                       <td>小源科技智能短信SDK</td>
                       <td>设备标识信息</td>
                       <td>实现智能短信功能</td>
                       <td><a href="http://www.bizport.cn/">http://www.bizport.cn/</a></td>
                   </tr>
                   <tr>
                       <td>中国移动智能短信SDK</td>
                       <td>设备标识信息</td>
                       <td>实现智能短信功能</td>
                       <td class="empty">--</td>
                   </tr>
                   <tr>
                       <td>中国电信智能短信SDK</td>
                       <td>设备标识信息</td>
                       <td>实现智能短信功能</td>
                       <td class="empty">--</td>
                   </tr>
                   <tr>
                       <td>中国联通智能短信SDK</td>
                       <td>设备标识信息</td>
                       <td>实现智能短信功能</td>
                       <td class="empty">--</td>
                   </tr>
                   <tr>
                       <td>RCS消息SDK</td>
                       <td>设备标识信息、定位信息</td>
                       <td>提供RCS消息功能</td>
                       <td class="empty">--</td>
                   </tr>
                   <tr>
                       <td>联通陌电识别SDK</td>
                       <td>设备标识信息、定位信息</td>
                       <td>实现陌生来电识别功能</td>
                       <td class="empty">--</td>
                   </tr>
                   <tr>
                       <td>骚扰拦截SDK</td>
                       <td>设备标识信息、短信、电话号码</td>
                       <td>骚扰拦截数据获取</td>
                       <td class="empty">--</td>
                   </tr>
                   <tr>
                       <td>杀毒引擎SDK</td>
                       <td>设备标识信息、定位定位信息、应用列表</td>
                       <td>手机病毒查杀，用于检测手机中安装的病毒</td>
                       <td class="empty">--</td>
                   </tr>
                   <tr>
                       <td>QQ音乐SDK</td>
                       <td class="empty">--</td>
                       <td>QQ音乐控制</td>
                       <td class="empty">--</td>
                   </tr>
                   <tr>
                       <td>讯飞账号SDK</td>
                       <td>联网</td>
                       <td>讯飞账号登录</td>
                       <td><a href="https://doc.iflyos.cn/device/app_sdk/Android.html#%E5%BF%AB%E9%80%9F%E6%8E%A5%E5%85%A5">https://doc.iflyos.cn/device/app_sdk/Android.html#%E5%BF%AB%E9%80%9F%E6%8E%A5%E5%85%A5</a></td>
                   </tr>
                   <tr>
                       <td>360搜索SDK</td>
                       <td>联网、定位信息</td>
                       <td>实现搜索功能</td>
                       <td><a href="http://www.360os.com/public/v1/search">http://www.360os.com/public/v1/search</a></td>
                   </tr>
                   <tr>
                       <td>讯飞离线TTS SDK</td>
                       <td>设备标识信息</td>
                       <td>文字转语音功能</td>
                       <td><a href="https://www.xfyun.cn/?ch=bd05&b_scene_zt=1&renqun_youhua=648371&bd_vid=12160938486662070206">https://www.xfyun.cn/?ch=bd05&b_scene_zt=1&renqun_youhua=648371&bd_vid=12160938486662070206</a></td>
                   </tr>
                   <tr>
                       <td>360地图SDK</td>
                       <td>设备标识信息、定位信息</td>
                       <td>定位解析</td>
                       <td class="empty">--</td>
                   </tr>
                   <tr>
                       <td>360云盘S3服务器SDK</td>
                       <td>设备标识信息</td>
                       <td>下载应用</td>
                       <td class="empty">--</td>
                   </tr>
                   <tr>
                       <td>亚马逊云SDK</td>
                       <td>设备标识信息</td>
                       <td>aws云sdk，海外同步功能</td>
                       <td><a href="https://aws.amazon.com/cn/sdk-for-java/">https://aws.amazon.com/cn/sdk-for-java/</a></td>
                   </tr>
                   <tr>
                       <td>微信小程序SDK</td>
                       <td class="empty">--</td>
                       <td>微信小程序</td>
                       <td><a href="https://developers.weixin.qq.com/">https://developers.weixin.qq.com/</a></td>
                   </tr>
                   <tr>
                       <td>美颜SDK</td>
                       <td class="empty">--</td>
                       <td>提供美颜功能</td>
                       <td class="empty">--</td>
                   </tr>
                   <tr>
                       <td>人脸识别SDK</td>
                       <td class="empty">--</td>
                       <td>实现人脸识别功能</td>
                       <td class="empty">--</td>
                   </tr>
                   <tr>
                       <td>萌颜SDK</td>
                       <td class="empty">--</td>
                       <td>提供萌颜功能</td>
                       <td class="empty">--</td>
                   </tr>
                   <tr>
                       <td>账号管理SDK</td>
                       <td class="empty">--</td>
                       <td>360账号管理</td>
                       <td class="empty">--</td>
                   </tr>
                   <tr>
                       <td>智能清理SDK</td>
                       <td class="empty">--</td>
                       <td>图片智能清理</td>
                       <td class="empty">--</td>
                   </tr>
                   <tr>
                       <td>安全检测sdk</td>
                       <td class="empty">--</td>
                       <td>网页检测</td>
                       <td class="empty">--</td>
                   </tr>
               </tbody>
            </table>
        </div>
        <Footer />
    </div>
</template>

<script>
import Footer from '@/components/footer'

export default {

    components:{
        Footer
    },
    data() {
        return {
        }
    },
}
</script>

<style lang="scss" scoped>
.table {
    border: 1px solid #807e7e;
    border-collapse: collapse;
    margin-bottom: 20px;
    width: 100%;
    .empty {
        text-align: center;
    }
    .table-header {
        padding: 10px 20px;
    }
    .table-body td {
        padding: 5px 10px;
        word-break: break-all;
    }
    td,th {
        border: 1px solid #807e7e;
        font-size: 14px;
    }
    a {
        color: #000;
    }
}
pre {
    white-space: pre-line;
    margin-top: 0;
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 28px;
}
.header {
    width: 1160px;
    margin: 0 auto;
    img {
        padding: 22px 0;
    }
    a {
        display: inline-block;
    }
}
.text-content {
    width: 760px;
    margin: 0 auto;
    color: #1F1F1F;
    .title {
        font-size: 48px;
        text-align: center;
        padding-top: 80px;
        padding-bottom: 60px;
    }
    .title2 {
        font-weight: bold;
        font-size: 16px;
        padding-bottom: 10px;
    }
}
</style>

