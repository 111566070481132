<template>
  <div>
    <div class="top-bg">
      <Header class="header" />
      <div class="content">
        <div class="top-text">超清拍题</div>
        <div class="top-sub-text">
          针对拍题场景研发的全能画质提升方案，解决包括失焦、抖动、噪点、暗光、曝光拖影等问题。
        </div>
        <div class="top-btn">
          <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
          <el-button class="coop-btn" round @click="consultClick">合作咨询</el-button>
        </div>
      </div>
    </div>

    <div class="content item-header">
      <div class="item-title">方案介绍</div>
      <div class="item-text">综合运用防抖、超分、降噪等技术解决拍摄模糊问题</div>
    </div>
    <div class="content" style="text-align: center;margin-top: 60px;">
      <div>
        <div style="display: inline-block;">
          <a class="solution-tab active" name="fun1">增强</a>
          <a class="solution-tab" name="fun2">防抖</a>
          <a class="solution-tab" name="fun3">HDR</a>
          <div class="solution-box-line">
            <div class="solution-line-bg">
              <div class="solution-line-move"></div>
            </div>
          </div>
        </div>
      </div>  
    </div>
    <div class="content" style="margin-top: 60px;">
      <div class="fun1">
        <div class="clear-bg">
          <div class="normal-bg normal-bg1 drag-pic-wrap drag-pic-wrap1">
            <div class="js-drag-pic js-drag-pic1">
              <img style="position: relative;" src="../../img/aiImage/onlineEdu/clear/enhance_after.png" alt="">
            </div>
            <img class="dragger-clear dragger-clear1" src="../../img/aiImage/onlineEdu/clear/dragIcon.png" alt="">
          </div>
        </div>
      </div>
      <div class="fun2" style="width:100%;display:none;">
        <div class="clear-bg">
          <div class="normal-bg normal-bg2 drag-pic-wrap drag-pic-wrap2">
            <div class="js-drag-pic js-drag-pic2">
              <img style="position: relative;" src="../../img/aiImage/onlineEdu/clear/shake_after.png" alt="">
            </div>
            <img class="dragger-clear dragger-clear2" src="../../img/aiImage/onlineEdu/clear/dragIcon.png" alt="">
          </div>
        </div>
      </div>
      <div class="fun3" style="width:100%;display:none;">
        <div class="clear-bg">
          <div class="normal-bg normal-bg3 drag-pic-wrap drag-pic-wrap3">
            <div class="js-drag-pic js-drag-pic3">
              <img style="position: relative;" src="../../img/aiImage/onlineEdu/clear/HDR_after.png" alt="">
            </div>
            <img class="dragger-clear dragger-clear3" src="../../img/aiImage/onlineEdu/clear/dragIcon.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="content item-header">
      <div class="item-title">方案优势</div>
    </div>
    <div class="content clear" style="margin-top: 60px;">
      <div class="advantage">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../../img/aiImage/onlineEdu/clear/clear_1.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">跨平台</div>
            <div class="advantage-text-sub-title">不受硬件及Tuning差异影响</div>
          </div>
        </div>
      </div>
      <div class="advantage" style="margin-left:40px;">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../../img/aiImage/onlineEdu/clear/clear_2.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">多功能</div>
            <div class="advantage-text-sub-title">综合运用多种算法，智能分析匹配最优方案</div>
          </div>
        </div>
      </div>
      <div class="advantage" style="margin-top:40px;">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../../img/aiImage/onlineEdu/clear/clear_3.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">高还原</div>
            <div class="advantage-text-sub-title">有效抑制高亮，处理暗处噪点，还原拍摄物真实色彩</div>
          </div>
        </div>
      </div>
      <div class="advantage" style="margin-left:40px;margin-top:40px;">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../../img/aiImage/onlineEdu/clear/clear_4.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">降成本</div>
            <div class="advantage-text-sub-title">改善抖动影响，节省光学防抖硬件成本</div>
          </div>
        </div>
      </div>
    </div>

    <div class="content item-header">
      <div class="item-title">应用场景</div>
    </div>
    <div class="content clear" style="margin-top: 60px;display:flex;">
      <div class="scene-item">
        <div>
          <img src="../../img/aiImage/onlineEdu/clear/clear_5.jpg" alt="">
        </div>
        <div class="scene-title">拍纸质作业</div>
        <div class="scene-text">适用于拍照搜题、远程作业辅导等场景，减少因拍照模糊问题影响学习效率。</div>
      </div>
      <div class="scene-item" style="margin-left:40px;">
        <div>
          <img src="../../img/aiImage/onlineEdu/clear/clear_6.jpg" alt="">
        </div>
        <div class="scene-title">拍电子课件</div>
        <div class="scene-text">减少拍摄电子屏幕时的光线影响，快速记录有效信息。</div>
      </div>
    </div>

    <div class="content item-header" style="margin-bottom: 120px;">
      <contact></contact>
    </div>
    <dialog-consult ref="consult"></dialog-consult>
    <dialog-apply ref="apply"></dialog-apply>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import contact from "./components/contact"
import DialogConsult from './components/dialogConsult.vue';
import DialogApply from './components/dialogApply'
export default {
  components: {
    Header,
    Footer,
    contact,
    DialogConsult,
    DialogApply
  },

  data() {
    return {
      show: false,
      uiName: 'fun1'
    }
  },

  methods: {
    consultClick () {
      this.$refs.consult.show()
    },
    applyClick () {
      this.$refs.apply.show()
    },
    bindEvent () {
      const _this = this
      $('.solution-tab').click(function() {
        _this.uiName = $(this).attr('name')
        $(this).addClass('active').siblings().removeClass('active')
        _this.uiAnimate1()
      })
    },
    uiAnimate1 () {
      const _this = this
      $('.solution-box-line .solution-line-move').stop().css("width", "0px")
      if (this.uiName == 'fun1') {
        $('.solution-box-line .solution-line-bg').animate({marginLeft: "40px"})
        $('.fun1').fadeIn(1000).siblings().hide()
      } else if (this.uiName == 'fun2') {
        $('.solution-box-line .solution-line-bg').animate({marginLeft: "180px"})
        $('.fun2').fadeIn(1000).siblings().hide()
      } else if (this.uiName == 'fun3') {
        $('.solution-box-line .solution-line-bg').animate({marginLeft: "320px"})
        $('.fun3').fadeIn(1000).siblings().hide()
      }

      $('.solution-box-line .solution-line-move').animate({width: "60px"},6000,function(){
        if (_this.uiName == 'fun1') {
          _this.uiName = 'fun2'
          $('.solution-tab').eq(1).addClass('active').siblings().removeClass('active')
        } else if (_this.uiName == 'fun2') {
          _this.uiName = 'fun3'
          $('.solution-tab').eq(2).addClass('active').siblings().removeClass('active')
        } else if (_this.uiName == 'fun3') {
          _this.uiName = 'fun1'
          $('.solution-tab').eq(0).addClass('active').siblings().removeClass('active')
        }
        _this.uiAnimate1()
      })
    },
    clearAnimate (drag_pic, drag_icon, drag_pic_wrap) {
      const container = $(drag_pic)
      const draggerPic = $(drag_icon)

      let isResizing = false
      let startPosition = container.width()
      let startPositionDragger = container.width() - 33
      $(document).on('mousemove', drag_icon, function(e) {
        e.preventDefault()
        if (!isResizing) return
        const $this = $(this)
        let offsetRight = e.clientX - container.offset().left - container.width()
        startPosition += offsetRight
        startPositionDragger += offsetRight  
        if (startPosition > 0 && startPosition < 1160) {    
            container.css("width", startPosition + "px")
            $this.css('left', startPositionDragger + "px")
        }  
      })
      .on('mousemove', drag_pic_wrap, function (e) {  
        e.preventDefault()
        if (!isResizing) return
        let offsetRight = e.clientX - container.offset().left - container.width() 
        startPosition += offsetRight
        startPositionDragger += offsetRight
        if (startPosition > 0 && startPosition < 1160) {    
            container.css("width", startPosition + "px")  
            draggerPic.css('left', startPositionDragger + "px")
        }  
      })
      .on('mouseup', drag_icon, function (e) {  
          console.log('mouseup')
          e.preventDefault()  
          isResizing = false
      })
      .on('mousedown', drag_icon, function (e) {  
          console.log('mousedown')
          e.preventDefault()
          isResizing = true
      })
    }
  },

  mounted() {
    window.scrollTo(0, 0)
    this.bindEvent()
    this.uiAnimate1()
    this.clearAnimate('.js-drag-pic1', '.dragger-clear1', '.drag-pic-wrap1')
    this.clearAnimate('.js-drag-pic2', '.dragger-clear2', '.drag-pic-wrap2')
    this.clearAnimate('.js-drag-pic3', '.dragger-clear3', '.drag-pic-wrap3')
  }
}
</script>

<style lang="scss" scoped>
.top-bg {
  position: relative;
  background-image: url("../../img/aiImage/onlineEdu/clear/header.jpg");
  background-position: center;
  .header {
    position: absolute;
    top: 0;
    z-index: 100;
    height: 64px;
    width: 100%;
  }
  .top-text {
    font-size: 64px;
    color: #ffffff;
    padding-top: 220px;
    padding-left: 60px;
    box-sizing: border-box;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-sub-text {
    width: 600px;
    height: 72px;
    line-height: 36px;
    margin-top: 40px;
    font-size:18px;
    color: #ffffff;
    padding-left: 60px;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-btn {
    padding-left: 60px;
    margin-top: 72px;
    .apply-btn {
      font-size: 18px;
      color: #fff;
      padding: 14px 37px;
      background-color: #007aff;
      border: none;
      border-radius: 25px;
    }
    .coop-btn {
      font-size: 18px;
      color: #0059FF;
      padding: 14px 37px;
      margin-left: 72px;
      border: none;
      border-radius: 25px;
    }
  }
}

.advantage {
  height: 240px;
  width: 560px;
  background-color: #fff;
  display: inline-block;
  .advantage-wrap {
    display: flex;
    align-items: center;
  }
  .advantage-img {
    >img {
      margin: 60px 40px 60px 60px;
    }
  }
  .advantage-text {
    .advantage-text-title {
      font-size: 24px;
      color: #1F1F1F;
      letter-spacing: 0;
      font-weight: bold;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
    .advantage-text-sub-title {
      font-size: 14px;
      color: #1F1F1F;
      letter-spacing: 0;
      margin-top: 10px;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
  }
}

.scene-item {
  width: 560px;
  height: 530px;
  background-color: #fff;
  .scene-title {
    font-size: 32px;
    color: #1F1F1F;
    letter-spacing: 0;
    text-align: center;
    margin: 38px 0 30px 0;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .scene-text {
    font-size: 16px;
    color: #1F1F1F;
    letter-spacing: 0;
    text-align: center;
    margin: 0 70px;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
}

.solution-tab {
  cursor: pointer;
  font-size: 18px;
  color: #1F1F1F;
  margin: 0 10px;
  line-height: 36px;
  padding-bottom: 20px;
  display: inline-block;
  width: 120px;
  text-align: center;
  font-family: "Microsoft YaHei","PingFangSC-Regular";
}
.solution-tab.active {
  font-size: 24px;
  color: #007AFF;
}
.solution-box-line {
  height: 2px;
  .solution-line-bg {
    height: 2px;
    width: 60px;
    margin-left: 40px;
    background: #99CAFF;
    .solution-line-move {
      height: 2px;
      width: 0;
      background: #007AFF;
    }
  }
}

.clear-bg {
  width: 1160px;
  height: 725px;
}
.normal-bg1 {
    background-image: url('../../img/aiImage/onlineEdu/clear/enhance_before.png');
}
.normal-bg2 {
    background-image: url('../../img/aiImage/onlineEdu/clear/shake_before.png');
}
.normal-bg3 {
    background-image: url('../../img/aiImage/onlineEdu/clear/HDR_before.png');
}
.normal-bg {
  position: relative;
  width: 1160px;
  height: 725px;
  .dragger-clear {
    position: absolute;
    cursor: e-resize;
    left: 547px;
  }
  .js-drag-pic {
    overflow: hidden;
    display: inline-block;
    height: 100%;
    width: 580px;
  }
}

</style>
