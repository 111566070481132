<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="600px"
      top="20vh"
    >
      <div class="wrap">
        <div class="title">合作咨询</div>
        <div><img src="../../../img/aiImage/onlineEdu/weixin_dialog.png" alt=""></div>
        <div class="text">扫描上方微信二维码与我们联系</div>
      </div>
     
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ['title'],
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    show() {
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrap {
    width: 240px;
    margin: 0 auto;
    .title {
      font-size: 24px;
      color: #1F1F1F;
      letter-spacing: 0;
      text-align: center;
      margin: 12px 0 42px 0;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
    .text {
      font-size: 16px;
      color: #1F1F1F;
      letter-spacing: 0;
      text-align: center;
      margin-top: 24px;
      margin-bottom: 42px;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
  }
</style>