import { render, staticRenderFns } from "./iotAnalysis.vue?vue&type=template&id=63a0afee&scoped=true&"
import script from "./iotAnalysis.vue?vue&type=script&lang=js&"
export * from "./iotAnalysis.vue?vue&type=script&lang=js&"
import style0 from "./iotAnalysis.vue?vue&type=style&index=0&id=63a0afee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63a0afee",
  null
  
)

export default component.exports