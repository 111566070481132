<template>
  <div>
    <div class="top-bg">
      <Header class="header" />
      <div class="content">
        <div class="top-text" style="padding-top: 180px;">奇络AI监测</div>
        <div class="top-sub-text">
          基于AIoT OS核心能力应用，<br />
          为工业数字化转型提供安全高效的AI应用
        </div>
        <div class="top-btn">
          <el-button class="contact-btn" round @click="contactUs">联系我们</el-button>
        </div>
      </div>
    </div>
	<div style="display: none;">AI监测，奇络AI，工厂安全生产，制造业数字化，智能监测，行为分析，人员跨越，安全帽，安全带，火苗烟雾，工厂安全管理，工厂HSE，工厂EHS，工业互联网</div>
    <div class="content">
      <div class="part-title">奇络AI监测解决方案</div>
      <div class="part-sub-title">AI监测基于360集团大安全战略，利用AIoT OS技术
优势，将物联网、边缘计算、 大数据、 人工智能等
新技术应用于业务安全和企业赋能， 充分挖掘 “监”
与“测”的智能化，针对企业现有设备进行智能化改
造，通过AI算法最大化地对日常运营数据进行分析，
实时获取精准信息，用于管理决策和效率提升，助力
企业数字化转型。
</div>
    </div>
    <div class="content">
      <img src="../img/aiMonitor/solution1.png" >
    </div>

    <div class="content item-header">
      <div class="reason-title">核心功能</div>
    </div>
    <div class="content" style="margin-top:48px;">
      <div class="code-item">
        <div>
          <img src="../img/aiMonitor/code_1.png" alt="">
        </div>
        <div class="code-text">
          <div class="code-text-title">边缘计算</div>
          <div class="code-text-sub-title">AI边缘计算服务器以视频、图像的分析计算为核心，搭载AI算法，为各类场景提供安全、可靠、稳定的边缘计算服务。</div>
        </div>
      </div>
      <div class="code-item" style="background: #F4F4F4;">
        <div>
          <img src="../img/aiMonitor/code_2.png" alt="">
        </div>
        <div class="code-text">
          <div class="code-text-title">场景算法</div>
          <div class="code-text-sub-title">AI监测涵盖人员出入、人员布控、人员逗留、吸烟行为、工服穿戴、安全帽、口罩佩戴、接打电话、人员跌倒等100+种目标或行为场景算法。</div>
        </div>
      </div>
      <div class="code-item">
        <div>
          <img src="../img/aiMonitor/code_3.png" alt="">
        </div>
        <div class="code-text">
          <div class="code-text-title">云服务</div>
          <div class="code-text-sub-title">AI监测云提供数据服务、文件服务、数据报表、地图显示、语音播报、视频服务等；多用户层级管理、语音告警提示、微信小程序推送等功能。</div>
        </div>
      </div>
      <div class="code-item" style="background: #F4F4F4;">
        <div>
          <img src="../img/aiMonitor/code_4.png" alt="">
        </div>
        <div class="code-text">
          <div class="code-text-title">数据呈现</div>
          <div class="code-text-sub-title">AI监测支持Web大屏展示、Web报表、小程序及语音播报等多种数据呈现方式。</div>
        </div>
      </div>
    </div>

    <div class="content item-header">
      <div class="reason-title">方案优势</div>
    </div>
    <div class="content clear" style="margin-top: 60px;display: flex;">
      <div class="advantage">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../img/aiMonitor/icon-qzfa.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">全栈方案</div>
            <div class="advantage-text-item">
              提供从设备硬件、AIoT OS、算法到SaaS应用的一体化产品方案
            </div>
          </div>
        </div>
      </div>
      <div class="advantage" style="margin-left:40px;">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../img/aiMonitor/icon-sf.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">算法丰富</div>
            <div class="advantage-text-item">
              算法库目前已有百余种算法，且在不断升级，免费选择，满足用户各类场景需求
            </div>
          </div>
        </div>
      </div>
      <div class="advantage" style="margin-left:40px;">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../img/aiMonitor/icon-zysf.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">自研算法</div>
            <div class="advantage-text-item">
              算法库由360OS完全自行研发，拥有核心知识产权，自主持续更新迭代维护
            </div>
          </div>
        </div>
      </div>
    </div>
	<div class="content clear" style="margin-top: 20px;display: flex;">
	  <div class="advantage">
	    <div class="advantage-wrap">
	      <div class="advantage-img">
	        <img src="../img/aiMonitor/icon-ljgz.png" >
	      </div>
	      <div class="advantage-text">
	        <div class="advantage-text-title">利旧改造</div>
	        <div class="advantage-text-item">
	          通过标准协议在原有视频监控系统上进行无损升级，适配所有主流品牌摄像头视频格式
	        </div>
	      </div>
	    </div>
	  </div>
	  <div class="advantage" style="margin-left:40px;">
	    <div class="advantage-wrap">
	      <div class="advantage-img">
	        <img src="../img/aiMonitor/icon-yjbs.png" >
	      </div>
	      <div class="advantage-text">
	        <div class="advantage-text-title">一键部署</div>
	        <div class="advantage-text-item">
	          产品内置多种场景模式，菜单式操作，可以在现场即时部署，快速投入使用
	        </div>
	      </div>
	    </div>
	  </div>
	  <div class="advantage" style="margin-left:40px;">
	    <div class="advantage-wrap">
	      <div class="advantage-img">
	        <img src="../img/aiMonitor/icon-kz.png" >
	      </div>
	      <div class="advantage-text">
	        <div class="advantage-text-title">扩展性强</div>
	        <div class="advantage-text-item">
	          产品提供丰富的对外接口，支持多种设备数据的接入，及云平台的接口开放
	        </div>
	      </div>
	    </div>
	  </div>
	</div>

    <div class="sence-wrap">
      <div class="content">
         <div class="reason-title" style="margin: 93px 0 57px 0;">应用场景展示</div>
      </div>
      <div class="content img-wrap">
        <div class="img">
          <img src="../img/aiMonitor/sence-qczz.png" alt="">
          <div class="text">汽车制造</div>
        </div>
        <div class="img marginLeft">
          <img src="../img/aiMonitor/sence-jzx.png" alt="">
          <div class="text">集装箱</div>
        </div>
        <div class="img marginLeft">
          <img src="../img/aiMonitor/sence-yhbz.png" alt="">
          <div class="text">烟花爆竹</div>
        </div>
        <div class="img marginLeft">
          <img src="../img/aiMonitor/sence-gyhlw.png" alt="">
          <div class="text">工业互联网</div>
        </div>
        <div class="img marginTop">
          <img src="../img/aiMonitor/sence-syhg.png" alt="">
          <div class="text">石油化工</div>
        </div>
        <div class="img marginLeft marginTop">
          <img src="../img/aiMonitor/sence-wlck.png" alt="">
          <div class="text">物流仓储</div>
        </div>
        <div class="img marginLeft marginTop">
          <img src="../img/aiMonitor/sence-bgyq.png" alt="">
          <div class="text">办公园区</div>
        </div>
        <div class="img marginLeft marginTop">
          <img src="../img/aiMonitor/sence-zfwy.png" alt="">
          <div class="text">政府物业</div>
        </div>
      </div>
    </div>

    <div class="content item-header">
      <div class="reason-title">行业应用成功案例</div>
      <div class="reason-sub-title">在产品和服务上进行全面战略升级，为合作伙伴提供优质的行业应用服务和硬件产品</div>
    </div>
    <div class="content case-wrap">
      <div class="case">
        <div class="case-img">
          <img src="../img/aiMonitor/cases-1.png" >
          <!-- <div class="img-text">政府物业</div> -->
        </div>
        <div class="case-text">
          <div class="case-text-title">湘赣地区某大型车企制造车间AI监测</div>
          <div class="case-text-item">
			<span style="height: 50px;width:100%;display: inline-block;"><span style="font-size: 16px;font-weight: 600;">客户：</span>湘赣地区某大型车企，国内前十的汽车制造企业。<br/></span>
			<el-tooltip placement="bottom">
			  <div slot="content">
				<div style="display: inline-flex;margin-bottom: 10px;">
				  <div style="font-size: 16px;font-weight: 600;width: 60px;">客户：</div>
				  <div>湘赣地区某大型车企，国内前十的汽车制造企业。</div>
				</div>
				<div style="display: inline-flex;margin-bottom: 10px;">
				  <div style="font-size: 16px;font-weight: 600;width: 90px;">场景：</div>
				  <div>厂区内3万余名员工，管理难度大，经常因部分员工安全意识淡薄导致安全事故发生。</div>
				</div>
				<div style="display: inline-flex;margin-bottom: 10px;">
				  <div style="font-size: 16px;font-weight: 600;width: 155px;">方案：</div>
				  <div>通过AI监测对安全生产管理系统进行了升级，对员工未戴安全帽、未穿工衣、未走人行道等行为进行监测，并生成相应统计报表，输出班组排名等指标。</div>
				</div>
				<div style="display: inline-flex;margin-bottom: 10px;">
				  <div style="font-size: 16px;font-weight: 600;width: 110px;">效果：</div>
				  <div>通过各车间班组横向对比，员工不规范行为下降70%以上，生产现场秩序得到大幅改善，安全隐患大大降低。</div>
				</div>
			  </div>
			  <span style="font-size: 16px;cursor: pointer;text-align: left;float: right;color: blue;font-weight: 400;">
				  详情<br />
			  </span>
			</el-tooltip>
          </div>
        </div>
      </div>
      <div class="case" style="margin-left:40px;">
        <div class="case-img">
          <img src="../img/aiMonitor/cases-2.png" >
          <!-- <div class="img-text">仓储行业</div> -->
        </div>
        <div class="case-text">
          <div class="case-text-title">闽粤地区某集装箱安全生产监测平台</div>
          <div class="case-text-item">
            <span style="height: 50px;width:100%;display: inline-block;"><span style="font-size: 16px;font-weight: 600;">客户：</span>集装箱行业某知名企业生产基地。<br/></span>
            <el-tooltip placement="bottom">
			  <div slot="content">
				<div style="display: inline-flex;margin-bottom: 10px;">
				  <div style="font-size: 16px;font-weight: 600;width: 60px;">客户：</div>
				  <div>集装箱行业某知名企业生产基地。</div>
				</div>
				<div style="display: inline-flex;margin-bottom: 10px;">
				  <div style="font-size: 16px;font-weight: 600;width: 65px;">场景：</div>
				  <div>集装箱生产环境复杂，多样，员工规模大，综合管理难度大。</div>
				</div>
				<div style="display: inline-flex;margin-bottom: 10px;">
				  <div style="font-size: 16px;font-weight: 600;width: 195px;">方案：</div>
				  <div>通过参考HSE管理规范及客户的实际需求，在生产现场实施了安全帽、集装箱安全带、人员跨越、人跨车道等实时监测，并在后台生成相应统计报表，供厂区及集团作为统筹管理的依据。</div>
				</div>
				<div style="display: inline-flex;margin-bottom: 10px;">
				  <div style="font-size: 16px;font-weight: 600;width: 80px;">效果：</div>
				  <div>现场管理提升明显，各类不安全隐患得到实时监测，安全管理实现了统筹化。</div>
				</div>
			  </div>
              <span style="font-size: 16px;cursor: pointer;text-align: left;float: right;color: blue;font-weight: 400;">
            	  详情<br />
              </span>
            </el-tooltip>
          </div>
        </div>
      </div>
      <div class="case" style="margin-left:40px;">
        <div class="case-img">
          <img src="../img/aiMonitor/cases-3.png" >
          <!-- <div class="img-text">制造行业</div> -->
        </div>
        <div class="case-text">
          <div class="case-text-title">华东某设备修造厂区AI监测</div>
          <div class="case-text-item">
            <span style="height: 50px;width:100%;display: inline-block;"><span style="font-size: 16px;font-weight: 600;">客户：</span>华东某电力设备修造企业。<br/></span>
            <el-tooltip placement="bottom">
			  <div slot="content">
				<div style="display: inline-flex;margin-bottom: 10px;">
				  <div style="font-size: 16px;font-weight: 600;width: 60px;">客户：</div>
				  <div>华东某电力设备修造企业。</div>
				</div>
				<div style="display: inline-flex;margin-bottom: 10px;">
				  <div style="font-size: 16px;font-weight: 600;width: 110px;">场景：</div>
				  <div>企业为大型设备制造商，安全风险隐患点较多，事故频发，因员工安全意识淡薄造成人员死伤和财产损失。</div>
				</div>
				<div style="display: inline-flex;margin-bottom: 10px;">
				  <div style="font-size: 16px;font-weight: 600;width: 290px;">方案：</div>
				  <div>利用AI监测对监控系统升级，在车间、仓库、办公区等关键区域进行安全帽、使用手机、吸烟行为、烟雾火苗、人员逗留、人员出入、黑白名单等AI监测，管理人员可以通过微信小程序对各类不规范行为进行实时管理（可罚款），对于告警信息也可以第一时间做出预警反应。</div>
				</div>
				<div style="display: inline-flex;margin-bottom: 10px;">
				  <div style="font-size: 16px;font-weight: 600;width: 160px;">效果：</div>
				  <div>是之前普遍的不规范操作现象得到遏制，风险隐患响应及时，管理层能够实时掌握现场的安全生产状况，事故率下降了90%，近半年没有出现伤亡事件。</div>
				</div>
			  </div>
              <span style="font-size: 16px;cursor: pointer;text-align: left;float: right;color: blue;font-weight: 400;">
            	  详情<br />
              </span>
            </el-tooltip>
          </div>
        </div>
      </div>
      <div class="case" style="margin-top:40px;">
        <div class="case-img">
          <img src="../img/aiMonitor/cases-4.png" >
          <!-- <div class="img-text">化工行业</div> -->
        </div>
        <div class="case-text">
          <div class="case-text-title">华南某电子工厂安全生产监测</div>
          <div class="case-text-item">
            <span style="height: 50px;width:100%;display: inline-block;"><span style="font-size: 16px;font-weight: 600;">客户：</span>华南某大型上市企业，主营电子产品制造。<br/></span>
            <el-tooltip placement="bottom">
			  <div slot="content">
				<div style="display: inline-flex;margin-bottom: 10px;">
				  <div style="font-size: 16px;font-weight: 600;width: 60px;">客户：</div>
				  <div>华南某大型上市企业，主营电子产品制造。</div>
				</div>
				<div style="display: inline-flex;margin-bottom: 10px;">
				  <div style="font-size: 16px;font-weight: 600;width: 130px;">场景：</div>
				  <div>电子产品生产过程中，静电对现场安全构成一定的威胁，需要重点防控。生产场所内有水池等危险因素，需要防范人员进入。</div>
				</div>
				<div style="display: inline-flex;margin-bottom: 10px;">
				  <div style="font-size: 16px;font-weight: 600;width: 130px;">方案：</div>
				  <div>在关键地点如生产区域入口等设置静电服监测，对未按要求穿静电服的行为和跨越禁区行为进行AI监测，实时告警提醒管理人员处置。</div>
				</div>
				<div style="display: inline-flex;margin-bottom: 10px;">
				  <div style="font-size: 16px;font-weight: 600;width: 90px;">效果：</div>
				  <div>员工未穿静电服进入生产区域，以及违规穿越禁区的行为得到防控，提升了生产安全管理水平。</div>
				</div>
			  </div>
              <span style="font-size: 16px;cursor: pointer;text-align: left;float: right;color: blue;font-weight: 400;">
            	  详情<br />
              </span>
            </el-tooltip>
          </div>
        </div>
      </div>
      <div class="case" style="margin-top:40px;margin-left:40px;">
        <div class="case-img">
          <img src="../img/aiMonitor/cases-5.png" >
          <!-- <div class="img-text">制造行业</div> -->
        </div>
        <div class="case-text">
          <div class="case-text-title">华北某钢铁生产企业AI监测</div>
          <div class="case-text-item">
            <span style="height: 50px;width:100%;display: inline-block;"><span style="font-size: 16px;font-weight: 600;">客户：</span>华北某钢铁生产企业。<br/></span>
            <el-tooltip placement="bottom">
              <div slot="content">
				<div style="display: inline-flex;margin-bottom: 10px;">
				  <div style="font-size: 16px;font-weight: 600;width: 55px;">客户：</div>
				  <div>华北某钢铁生产企业。</div>
				</div>
				<div style="display: inline-flex;margin-bottom: 10px;">
				  <div style="font-size: 16px;font-weight: 600;width: 80px;">场景：</div>
				  <div>钢铁制造现场危险源较多，特点是生产现场复杂，容易发生各类风险事故，造成人员伤亡。</div>
				</div>
				<div style="display: inline-flex;margin-bottom: 10px;">
				  <div style="font-size: 16px;font-weight: 600;width: 105px;">方案：</div>
				  <div>通过将原有监控系统升级，在厂区进行吸烟、安全帽、人员跨越、玩手机等AI监测，对隐患进行实时告警并生成管理报表。</div>
				</div>
				<div style="display: inline-flex;margin-bottom: 10px;">
				  <div style="font-size: 16px;font-weight: 600;width: 70px;">效果：</div>
				  <div>厂区安全管理改善明显，员工安全生产的意识显著提升，事故发生率持续下降。</div>
				</div>
			  </div>
              <span style="font-size: 16px;cursor: pointer;text-align: left;float: right;color: blue;font-weight: 400;">
            	  详情<br />
              </span>
            </el-tooltip>
          </div>
        </div>
      </div>
      <div class="case" style="margin-top:40px;margin-left:40px;">
        <div class="case-img">
          <img src="../img/aiMonitor/cases-6.png" >
          <!-- <div class="img-text" style="padding: 3px 29px;">学校</div> -->
        </div>
        <div class="case-text">
          <div class="case-text-title">华南某钢铁工厂安全帽监测</div>
          <div class="case-text-item">
			<span style="height: 50px;width:100%;display: inline-block;"><span style="font-size: 16px;font-weight: 600;">客户：</span>华南某钢铁生产企业。<br/></span>
            <el-tooltip placement="bottom">
              <div slot="content">
				  <div style="display: inline-flex;margin-bottom: 10px;">
					  <div style="font-size: 16px;font-weight: 600;width: 55px;">客户：</div>
					  <div>华南某钢铁生产企业。</div>
				  </div>
				  <div style="display: inline-flex;margin-bottom: 10px;">
					  <div style="font-size: 16px;font-weight: 600;width: 120px;">场景：</div>
					  <div>为钢铁工厂每天有大量货物搬运，安监局要求所有在场人员必须佩戴安全帽。安全员经常发现有现场未带安全帽的情况。</div>
				  </div>
				  <div style="display: inline-flex;margin-bottom: 10px;">
					  <div style="font-size: 16px;font-weight: 600;width: 105px;">方案：</div>
					  <div>为增强现场安全意识，物业采用AI监测，在车间部署了安全帽监测。当监测未带安全帽时，及时发出告警。</div>
				  </div>
				  <div style="display: inline-flex;margin-bottom: 10px;">
					  <div style="font-size: 16px;font-weight: 600;width: 70px;">效果：</div>
					  <div>安全帽监测明显，满足了安监局的监管需要，员工安全意识得到提升。</div>
				  </div>
              </div>
              <span style="font-size: 16px;cursor: pointer;text-align: left;float: right;color: blue;font-weight: 400;">
				  详情<br />
			  </span>
            </el-tooltip>
          </div>
        </div>
      </div>
	  <div @click="contactUs" style="font-size: 16px;cursor: pointer;color: rgba(31, 31, 31, 1);font-weight: 400;margin-top: 30px;">
	  	  联系我司了解更多案例...
	  </div>
    </div>
    
    <div class="partner">
      <div class="title">工业化转型资讯</div>
      <div class="sub-title">让您的监控系统高效、实用、省钱、省心</div>
      <div class="btn-wrap">
        <el-button class="contact-btn" round @click="contactUs">联系我们</el-button>
      </div>
      
    </div>

    <dialog-consult ref="consult"></dialog-consult>
    <dialog-apply title="联系我们" type="contact"  ref="apply"></dialog-apply>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import contact from "./aiImageDetail/components/contact"
import DialogConsult from './aiImageDetail/components/dialogConsult.vue';
import DialogApply from './aiImageDetail/components/dialogApplyAi'
export default {
  components: {
    Header,
    Footer,
    contact,
    DialogConsult,
    DialogApply
  },

  data() {
    return {
      slideIndex: 1
    }
  },

  methods: {
    contactUs () {
      this.$refs.apply.show()
    }
  },

  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>
<style lang="scss">
.el-tooltip__popper[x-placement^="bottom"] .popper__arrow {   
	border-bottom-color: #f0f0f0 !important;
}
.el-tooltip__popper[x-placement^="bottom"] .popper__arrow:after {  
	border-bottom-color: #f0f0f0 !important;
}
.el-tooltip__popper[x-placement^="top"] .popper__arrow {
	border-bottom-color: #f0f0f0 !important;
}
.el-tooltip__popper[x-placement^="top"] .popper__arrow:after {  
	border-bottom-color: #f0f0f0 !important;
}
.el-tooltip__popper{  
	background: #f0f0f0 !important;  
	box-shadow: 1px 1px 10px 3px #D3D3D6;
	color: #1F1F1F !important;
	line-height: 25px;
	width: 360px !important;
}
</style>
<style lang="scss" scoped>
.contact-btn {
  font-size: 18px;
  color: #fff;
  padding: 15px 38px;
  background-color: #007aff;
  border: none;
  border-radius: 25px;
}

.top-bg {
  position: relative;
  background-image: url("../img/aiMonitor/banner.jpg");
  background-position: center;
  .header {
    position: absolute;
    top: 0;
    z-index: 100;
    height: 64px;
    width: 100%;
  }
  .top-text {
    font-size: 64px;
    color: #ffffff;
    padding-left: 60px;
    box-sizing: border-box;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-sub-text {
    margin-top: 48px;
    line-height: 36px;
    font-size: 24px;
    color: #ffffff;
    padding-left: 60px;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-btn {
    margin-top: 86px;
    padding-left: 60px;
  }
}

.part-title {
  font-family: "Microsoft YaHei","PingFangSC-Regular";
  font-size: 36px;
  color: #000000;
  font-weight: 700;
  margin: 133px 0 48px 0;
}
.part-sub-title {
  font-size: 16px;
  color: #1F1F1F;
  letter-spacing: 0;
  line-height: 28px;
  font-weight: 400;
  font-family: "Microsoft YaHei","PingFangSC-Regular";
  text-align: left;
  margin-bottom: 64px;
}

.code-item {
  width: 100%;
  height: 165px;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
  .code-text {
    margin-left: 20px;
    .code-text-title {
      font-size: 24px;
      color: #1F1F1F;
      letter-spacing: 0;
      font-weight: bold;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
    .code-text-sub-title {
      width: 840px;
      font-size: 16px;
      color: #1F1F1F;
      letter-spacing: 0;
      line-height: 32px;
      margin-top: 15px;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
  }
}

.advantage {
  height: 420px;
  width: 360px;
  background-color: #fff;
  .advantage-wrap {
    align-items: center;
  }
  .advantage-img {
    margin-top: 60px;
    text-align: center;
  }
  .advantage-text {
    .advantage-text-title {
      text-align: center;
      margin-top: 40px;
      margin-bottom: 23px;
      font-size: 24px;
      color: #1F1F1F;
      letter-spacing: 0;
      font-weight: bold;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
    .advantage-text-item {
      margin: 0 40px;
      font-size: 14px;
      color: #1F1F1F;
      letter-spacing: 0;
      line-height: 24px;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
  }
}

.reason-title {
  font-family: "Microsoft YaHei","PingFangSC-Regular";
  font-size: 36px;
  color: #000000;
  font-weight: 700;
  text-align: left;
}
.reason-sub-title {
  font-size: 16px;
  color: #1F1F1F;
  letter-spacing: 0;
  line-height: 28px;
  font-weight: 400;
  font-family: "Microsoft YaHei","PingFangSC-Regular";
  text-align: left;
  margin-top: 48px;
}

.sence-wrap {
  margin-top: 120px;
  padding-bottom: 120px;
  overflow: hidden;
  background-color: #fff;
  .img-wrap {
    display: flex;
    flex-wrap: wrap;
    .img {
      position: relative;
    }
    .marginLeft {
      margin-left: 40px;
    }
    .marginTop {
      margin-top: 40px;
    }
    .text {
      position: absolute;
      bottom: 14px;
      text-align: center;
      font-size: 20px;
      color: #FFFFFF;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
      width: 100%;
    }
  }
}

.case-wrap {
  display: flex;
  flex-wrap: wrap;
  padding-top: 60px;
  .case {
    width: 360px;
    height: 440px;
    background-color: #fff;
    box-shadow: 0 0 14px 0 rgba(0,0,0,0.18);
    .case-img {
      position: relative;
      .img-text {
        position: absolute;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 24px;
        font-family: "Microsoft YaHei","PingFangSC-Regular";
        top: 20px;
        left: 20px;
        padding: 3px 15px;
        border: 1px solid #fff;
        background-color: rgba(37, 37, 37, 0.8);
      }
    }
    .case-text {
      .case-text-title {
        font-family: "Microsoft YaHei","PingFangSC-Regular";
        font-size: 24px;
        color: #1F1F1F;
        font-weight: 700;
        margin: 24px 0 12px 30px;
		height: 60px;
      }
      .case-text-item {
        font-family: "Microsoft YaHei","PingFangSC-Regular";
        font-size: 13px;
        color: #1F1F1F;
        line-height: 24px;
        width: 300px;
        margin-left: 30px;
      }
    }
  }
}

.partner {
  margin-top:120px;
  height: 410px;
  width: 100%;
  background-image: url('../img/aiMonitor/partner.jpg');
  background-position: center top;
  overflow: hidden;
  .btn-wrap {
    text-align: center;
    margin-top: 65px;
  }
  .title {
    font-size: 64px;
    color: #FFFFFF;
    text-align: center;
    font-weight: 200;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
    margin-top: 87px;
  }
  .sub-title {
    font-family: "Microsoft YaHei","PingFangSC-Regular";
    font-size: 24px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    line-height: 36px;
    font-weight: 400;
    margin-top: 18px;
  }
}


</style>
