<template>
  <div>
    <div class="top-bg">
      <Header class="header" />
      <div class="content">
        <div class="top-text">智能辅助分析</div>
        <div class="top-sub-text">
          基于人脸、人体检测框架，为智能设备提供辅助分析能力，提高统计效率，丰富互动形式。
        </div>
        <div class="top-btn">
          <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
          <el-button class="coop-btn" round @click="consultClick">合作咨询</el-button>
        </div>
      </div>
    </div>

    <div class="content item-header">
      <div class="item-title">方案介绍</div>
      <div class="item-text">为智能摄像头、巡检机器人等终端提供侦测能力，包括身份审核、可疑情况侦查、安全事件报警等</div>
    </div>
    <div class="content clear" style="margin-top: 60px">
      <img src="../../img/aiImage/iot/solution_1.png" >
    </div>

    <div class="content item-header">
      <div class="item-title">核心技术</div>
    </div>
    <div class="content clear" style="margin-top: 60px">
     <div class="code-item">
       <div class="code-img">
         <img src="../../img/aiImage/iot/code_asi_1.png" alt="">
       </div>
       <div class="code-text">
         <div class="code-title">人脸姿态识别</div>
         <div class="code-content">智能识别头部角度（例如低头、抬头、歪头、侧脸）及五官动作（例如张嘴、闭眼等），可应用于教育平板学情分析、娱乐设备互动反馈、一体机客情分析等。</div>
         <div class="code-btn">
           <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
         </div>
       </div>
     </div>
    </div>
    <div class="content clear" style="margin-top: 60px">
     <div class="code-item">
       <div class="code-img">
         <img src="../../img/aiImage/iot/code_asi_2.png" alt="">
       </div>
       <div class="code-text">
         <div class="code-title">人体姿态识别</div>
         <div class="code-content">精准定位人体17个肢体关键点，根据人体关键点信息分析人体姿态、运动轨迹、动作角度等，可应用于人体辅助健身、体感游戏互动等场景。</div>
         <div class="code-btn">
           <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
         </div>
       </div>
     </div>
    </div>
    <div class="content clear" style="margin-top: 60px">
     <div class="code-item">
       <div class="code-img">
         <img src="../../img/aiImage/iot/code_asi_3.png" alt="">
       </div>
       <div class="code-text">
         <div class="code-title">人脸属性分析</div>
         <div class="code-content">识别人脸属性信息，包含性别、年龄、表情、是否佩戴眼镜/口罩等，可应用于趣味测试、情绪分析、安防监控等场景。</div>
         <div class="code-btn">
           <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
         </div>
       </div>
     </div>
    </div>
    <div class="content clear" style="margin-top: 60px">
     <div class="code-item">
       <div class="code-img">
         <img src="../../img/aiImage/iot/code_asi_4.png" alt="">
       </div>
       <div class="code-text">
         <div class="code-title">人流量统计</div>
         <div class="code-content">支持动静态人流量识别，统计图像中的人体个数和流动趋势，适用于机场、车站、商场、展会、景区等人群密集场所及各种出入口场景。</div>
         <div class="code-btn">
           <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
         </div>
       </div>
     </div>
    </div>
    <div class="content clear" style="margin-top: 60px">
     <div class="code-item">
       <div class="code-img">
         <img src="../../img/aiImage/iot/code_asi_5.png" alt="">
       </div>
       <div class="code-text">
         <div class="code-title">表情识别</div>
         <div class="code-content">基于表情特征提取与分类技术，实时精准识别愤怒、厌恶、恐惧、高兴、平静、悲伤、惊讶及疑惑共8类表情。</div>
         <div class="code-btn">
           <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
         </div>
       </div>
     </div>
    </div>
    <div class="content clear" style="margin-top: 60px">
     <div class="code-item">
       <div class="code-img">
         <img src="../../img/aiImage/iot/code_asi_6.png" alt="">
       </div>
       <div class="code-text">
         <div class="code-title">疲劳侦测</div>
         <div class="code-content">综合分析人脸及人体姿态，判断打哈欠、瞌睡、趴着等疲劳状态，可应用于行车驾驶、课堂学习等需要保持高专注度的场景。</div>
         <div class="code-btn">
           <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
         </div>
       </div>
     </div>
    </div>


    <div class="content item-header">
      <div class="item-title">应用场景</div>
    </div>
    <div class="content clear" style="margin-top: 60px">
      <div class="sence-wrap">
        <div><img src="../../img/aiImage/iot/sence_asi_1.png" alt=""></div>
        <div style="margin-left: 40px;"><img src="../../img/aiImage/iot/sence_asi_2.png" alt=""></div>
        <div style="margin-left: 40px;"><img src="../../img/aiImage/iot/sence_asi_3.png" alt=""></div>
        <div style="margin-top: 40px;"><img src="../../img/aiImage/iot/sence_asi_4.png" alt=""></div>
        <div style="margin: 40px 0 0 40px;"><img src="../../img/aiImage/iot/sence_asi_5.png" alt=""></div>
        <div style="margin: 40px 0 0 40px;"><img src="../../img/aiImage/iot/sence_asi_6.png" alt=""></div>
      </div>
    </div>

    <div class="content item-header" style="margin-bottom: 120px;">
      <contact></contact>
    </div>
    <dialog-consult ref="consult"></dialog-consult>
    <dialog-apply ref="apply"></dialog-apply>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import contact from "./components/contact"
import DialogConsult from './components/dialogConsult.vue';
import DialogApply from './components/dialogApply'
export default {
  components: {
    Header,
    Footer,
    contact,
    DialogConsult,
    DialogApply
  },

  data() {
    return {
    }
  },

  methods: {
    consultClick () {
      this.$refs.consult.show()
    },
    applyClick () {
      this.$refs.apply.show()
    }
  },

  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
.top-bg {
  position: relative;
  background-image: url("../../img/aiImage/iot/banner_analysis.jpg");
  background-position: center;
  .header {
    position: absolute;
    top: 0;
    z-index: 100;
    height: 64px;
    width: 100%;
  }
  .top-text {
    font-size: 64px;
    color: #ffffff;
    padding-top: 220px;
    padding-left: 60px;
    box-sizing: border-box;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-sub-text {
    width: 600px;
    height: 72px;
    line-height: 36px;
    margin-top: 40px;
    font-size:18px;
    color: #ffffff;
    padding-left: 60px;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-btn {
    padding-left: 60px;
    margin-top: 72px;
    .apply-btn {
      font-size: 18px;
      color: #fff;
      padding: 14px 37px;
      background-color: #007aff;
      border: none;
      border-radius: 25px;
    }
    .coop-btn {
      font-size: 18px;
      color: #0059FF;
      padding: 14px 37px;
      margin-left: 72px;
      border: none;
      border-radius: 25px;
    }
  }
}

.code-item {
  display: flex;
  .code-text {
    background-color: #fff;
    width: 500px;
    .code-title {
      font-size: 36px;
      color: #1F1F1F;
      margin: 40px 0 0 60px;
    }
    .code-content {
      font-size: 16px;
      color: #1F1F1F;
      letter-spacing: 0;
      line-height: 28px;
      width: 400px;
      margin: 20px 0 0 60px;
    }
    .code-btn {
      margin: 58px 0 0 60px;
    }
  }
}

.sence-wrap {
  display: flex;
  flex-wrap: wrap;
}

.apply-btn {
    font-size: 18px;
    color: #2d2d2d;
    padding: 14px 37px;
    background-color: #fff;
    border: 1px solid #2d2d2d;
    border-radius: 25px;
    &:hover {
      border: 1px solid #0d81ff;
      color: #0d81ff;
    }
  }

</style>
