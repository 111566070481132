<template>
  <div class="menu-box">
    <div class="title">后台管理</div>
    <el-menu
      default-active="/manager/list"
      class="menu"
      @open="handleOpen"
      @close="handleClose"
      background-color="#00152A"
      text-color="#fff"
      active-text-color="#108ee9"
      router
    >
      <el-submenu index="1">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>新闻</span>
        </template>
        <el-menu-item-group>
          <!-- <template slot="title">分组一</template> -->
          <el-menu-item index="/manager/list">列表</el-menu-item>
          <el-menu-item index="/manager/edit">新增</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>
<script>
export default {
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  }
};
</script>
<style lang="scss" scoped>
.menu-box {
  min-height: 100vh;
  background-color: #00152a;
  .title {
    height: 60px;
    line-height: 60px;
    box-sizing: border-box;
    text-align: center;
    color: #ffffff;
  }
  .menu {
    width: 200px;
  }
}
</style>
