<template>
    <div id="app">
        <div class="header">
            <a href="/"><img src="@/img/logo_blue.svg" alt=""></a>
        </div>
        <div class="text-content">
            <div class="title">个人隐私保护政策</div>
            <div class="date">
                本版发布日期：2021年2月3日<br>生效日期：2021年2月3日
            </div>
            <div class="title2">序言</div>
            <pre>
                欢迎使用西安欧思奇软件有限公司（以下简称“我们”或“公司”）研发的智能终端操作系统（以下简称“产品”）与相应服务（以下统称“产品与服务”），《个人隐私保护政策》（以下简称“本政策”）作为我们对您个人隐私保护的许诺，我们会严格执行，希望您能感受到我们的安全保障，放心使用我们的产品与服务。

                本隐私政策将帮助您了解以下内容：
                一、 我们如何收集和使用您的个人信息
                二、 我们如何存储您的个人信息
                三、 我们如何共享、转让、披露您的个人信息
                四、 我们提供广告服务时所涉及的隐私政策
                五、 我们如何保护您的个人信息
                六、 您管理个人信息的权利
                七、 未成年人个人信息保护
                八、 隐私政策的适用范围
                九、 隐私政策的变更和修订
                十、 如何联系我们
                十一、 本次更新内容

                请您在使用我们的产品和服务前，务必仔细阅读并了解本隐私政策，在确认充分理解并同意后使用。一旦您开始使用，即表示您已充分理解并同意本政策，并同意我们按照本隐私政策收集、使用、储存和分享您的相关信息。如对本政策内容有任何疑问、意见或建议，您可通过本隐私政策提供的联系方式与我们联系。
            </pre>
            <div class="title2">一、我们如何收集和使用您的个人信息</div>
            <pre>
                您在使用我们为您提供的产品或服务时，可能需要提供个人信息。您并非必须向我们提供个人信息，但一些情况下，如果您选择不提供，我们将无法为您提供产品与服务，也无法回应或解决您所遇到的问题。
                我们仅会出于本政策所述目的收集和使用您的个人信息。下文举例说明了我们可能收集的个人信息：
                （一）您在使用服务或产品过程中我们会收集的信息
                1、与您手机终端设备相关的信息: 为提供基础的设备与系统使用服务，我们会收集IMEI号、IMSI号、MAC地址、序列号码、设备名称、设备型号、设备识别码、系统版本的信息；
                2、您在使用相关服务时产生的相应信息：如您使用骚扰拦截服务时提供的电话号码与短信号码信息，以便我们提供更优质的拦截服务；
                3、<b>您的位置信息</b>（仅适用于特定服务/功能）：我们仅限于提供相关服务才会进行位置信息的收集（如天气服务、地图导航服务、照片地理位置定位、安全防盗服务、紧急求助服务、日历时区变更服务、个性化推荐服务），我们不会将收集到的位置信息与您的账号及身份信息相关联，或记录您的行动轨迹，亦不会将上述信息用作提供相应服务之外的任何用途。 您可以通过系统设置搜索“位置信息”点击关闭相关服务对于位置信息的收集 ，当您不允许我们收集时，我们将停止提供基于位置信息的服务；
                4、您使用服务时产生的日志信息：您在使用一些功能或与网站相关的信息，例如 报错日志、用户主动反馈的信息，以便我们及时根据上述信息进行服务更新和升级；
                5、我们的部分产品及服务中会提供<b>面部识别解锁的功能，会通过摄像系统识别您的面部信息，</b>所产生的数据将通过加密处理后仅保存在您的手机或您的其他终端设备（以下统称为“设备”）中，且仅作为您进行身份验证解锁设备使用，不会在您设备之外进行传输与储存。
                6、当您使用云平台备份服务时，您主动上传的通讯录、短信、通话记录、应用安装列表、日历日程信息、记事本信息以及闹钟重要数据到云端，我们不得不保存您备份的上述数据及记录您使用云平台的行为。
                7、您使用第三方账号登录时，为实现为您提供的服务我们可能需要从第三方获取并收集您的帐号信息，如昵称、头像、账号。如果我们开展业务需要进行的个人信息处理活动超出了您向第三方授权同意的处理范围，我们将在处理您的个人信息前征得您的明示同意。
                （二）非个人信息
                在符合法律法规的前提下，我们可能会将您的个人信息在匿名化、汇总、脱敏和加密处理后，形成统计信息或用户画像（但画像无法识别任何用户个人，以下称“非识别性信息”），收集此类信息的目的在于改善我们向您提供的服务。
                （三）无需征得您的授权同意可获得的个人信息
                如果我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，我们会事先征求您的同意。但根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：
                1、与国家安全、国防安全有关的；
                2、与公共安全、公共卫生、重大公共利益有关的；
                3、与犯罪侦查、起诉、审判和判决执行等有关的；
                4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
                5、所收集的个人信息是您自行向社会公众公开的；
                6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
                7、根据您的要求签订合同所必需的；
                8、用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
                9、为合法的新闻报道所必需的；
                10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
                11、法律法规规定的其他情形。
                （四）访问您的设备上的相关权限
                我们的系统可能会访问您设备上的相关权限用于提升产品服务质量：
                1、访问电话以及手机状态权限，为您的通话或与其他应用之间的顺利转换提供更加便捷的服务；
                2、访问您的通讯录以及通话记录，在您使用相关服务（如骚扰拦截服务）时，为您拦截您已设置为不愿接收的干扰，由此提升您的使用体验；
                3、获取您的短信权限，在您使用短息服务时，为您提供收发短信、彩信以及推送网页资讯或其他信息（例如：相关平台验证码），便于您对相应内容进行查看；
                4、访问您的麦克风，在您使用相机的录像或录音机中录音服务时，我们会访问您设备的麦克风权限，以便更好的为您记录；
                5、指纹使用权限，当您拨打隐私电话或使用设备中隐私系统时，您需要通过指纹认证才可进行相关操作，还请您放心，您的指纹信息储存在您的本地设备中，我们不会收集使用您的此类敏感信息，更不会进行处理、转让或分享；
                6、访问本地存储权限，主要用于您存储照片、视频、音乐或其他您感兴趣的内容；
                7、访问网络权限，主要用于了解手机的网络状态，更好的为您提供优质的服务体验；
                8、访问蓝牙权限，您在传输文件、图片或视频，或在链接蓝牙耳机时，蓝牙功能将带给您更加方便的体验；
                9、访问应用列表权限，为了更好的协助您管理您下载或本地预装的应用，我们会结合您的操作为您释放更多的本地存储空间；
                10、访问相机权限，在您拍照、录像、文件传输或发送彩信时，为您提供高效的服务；
                11、访问悬浮窗权限及通知权限，您在使用系统过程中来电显示、短信通知、通信类应用的最新通知等，为您及时提供通知服务便于您查看相关信息。
                关于系统或应用的后台启动，我们的系统及相关应用程序为了给您更好的服务体验，因此存在后台系统或应用保持启动的模式，请您知悉并理解，后台启动因系统原生技术设计，无法进行关闭（例如：短信启动，为保证您短息往来的畅通及方便阅读，您于锁屏状态时亦可及时获知最新的短信消息，所以无法关闭）。
                如果希望我们停止获取您的前述权限，您可以通过系统设置（系统设置-应用和权限-应用权限管理），对相关权限进行关闭操作。
            </pre>
            <div class="title2">二、我们如何存储您的个人信息</div>
            <pre>
                我们在中华人民共和国境内收集和产生的个人信息将存储在中华人民共和国境内。
                若为处理跨境业务，确需向境外机构传输境内收集的相关个人信息的，我们会按照法律、行政法规和相关监管部门的规定执行。我们会确保您的个人信息得到足够的保护，例如匿名化、加密存储等。
                我们仅在本政策所属目的的所必须期间和法律法规要求的时限内存储您的个人信息。
                如我们停止运营所提供的产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对我们存储的个人信息进行删除或匿名化处理。
            </pre>
            <div class="title2">三、我们如何共享、转让、披露您的个人信息</div>
            <pre>
                （一）共享
                我们不会与任何公司、组织和个人共享您的个人信息，但以下情况除外：
                1、在获取您明确同意的情况下，我们会与其他方共享您的个人信息。
                2、我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
                3、在法律法规允许的范围内，为维护我们、我们的关联方或合作伙伴、您或其他用户或社会公众利益、财产或安全免遭损害而有必要共享您的个人信息。
                4、在我们的关联方向您或向我们提供服务的情况下，我们可能与我们的关联公司共享您的个人信息。但我们仅共享必要的个人信息，且关联方对您信息的处理受本隐私政策的约束。关联公司如要改变您个人信息的处理目的，将再次征求您的授权和同意。
                5、除非获得您的明确授权和同意，否则我们不会与第三方广告商、应用程序开发者、开放平台或其他合作伙伴共享您的个人信息。我们可能会为这些合作伙伴提供汇总后的信息、匿名化后的信息或者其他不会识别到您个人身份的信息。例如，我们可能会告知应用程序开发者有多少人安装过其开发的应用程序。
                6、仅为实现本隐私政策中声明的目的，我们可能会与我们的供应商、服务提供商、顾问或代理人共享您的个人信息，以提供更好的客户服务和用户体验。这些供应商、服务提供商、顾问、代理人可能为我们提供技术基础设施服务，分析我们服务的使用方式，衡量广告和服务的有效性，提供客户服务和支付服务，进行学术研究和调查，或提供法律、财务与技术顾问服务。例如，我们可能会不定期地邀请注册用户参加抽奖活动。如您幸运中奖，我们需要向物流服务提供商提供您的个人信息，以便向您发送我们的奖品。
                7、对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的数据保护协定，要求其按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
                （二）转让
                我们不会将您的个人信息转让给其他任何公司、组织和个人，但以下情形除外：
                随着公司业务的发展，我们及我们的关联公司有可能进行合并、收购、资产转让或其他类似的交易。如相关交易涉及到您的个人信息转让，我们会要求新持有您个人信息的公司、组织和个人继续受此政策约束，否则我们将要求该公司、组织和个人重新征得您的授权同意。
                在获得您明确同意的情形下转让，亦即获得您明确同意后，我们会向其他方转让我们已经获取的您的个人信息。
                （三）披露
                我们仅会在以下情况下，公开披露您的个人信息：
                1、已经获得您明确同意。
                2、在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
                3、法律法规规定的其他情形。
            </pre>
            <div class="title2">四、 我们提供广告服务时所涉及的隐私政策</div>
            <pre>
                （一）个性化广告服务
                在符合相关法律法规规定下，<b>为了让您有更好的体验且为您呈现热门的资讯或广告，我们会基于您在使用核心功能与部分其他功能时所提供的位置信息、设备信息，或是依据第三方的合作需求获取上述信息，</b>以评估与广告有关的统计资料，以便为您推送更符合您需求或您更感兴趣的广告内容。给予您优质的用户体验是我们一直坚守的原则，我们保证仅出于正当、合法、必要、特定、明确的目的共享为您提供服务所必要的个人信息。我们不会将收集到的位置信息用于实现个性化推荐之外的任何用途。
                同时，我们了解有些用户不希望收到依其兴趣或浏览记录投放显示的广告。如您不同意我们的个性化广告推送服务，您可以通过第十条公布的联系方式联系我们，我们会有专人为您处理上述问题。
                （二）第三方广告服务提供方的隐私政策
                基于产品中的第三方广告服务的相关隐私政策，最终以第三方发布的政策条款内容（以下简称“第三方隐私政策”）为准。同样的，您应仔细阅读第三方隐私政策条款，如有任何问题，请通过本政策公开的联系方式联系我们。
                我们将审慎评估第三方使用共享信息的目的，要求这些合作方遵循合作中的法律协议。我们会对合作方获取信息的软件工具开发包（SDK）、应用程序接口（API）进行相应的安全监测，以保护数据安全。
                点击“<a style="color:#000;" href="/index.html#/third-sdk" target="_blank">第三方SDK目录</a>”进行查看。
            </pre>
            <div class="title2">五、我们如何保护您的个人信息</div>
            <pre>
                我们已采取符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。
                （一）我们会对可识别的个人身份信息进行加密传输和存储，以确保数据的保密性。对于您手机设备标识符国际移动设备身份码（IMEI），我们在您的设备端会用MD5算法进行匿名化和加密，并仅收集和上传经过匿名化和加密后的标识符。
                （二）若我们的应用采用了WebView技术，我们会对在WebView中使用的所有file协议进行URL校验且限制访问本应用敏感数据以及SDCard数据，避免在无特殊权限的情况下我们产品的任意私有文件和敏感信息被恶意盗用，从而造成您的个人信息被泄露。
                （三）我们在服务端部署访问控制机制，对可能接触到您个人信息的工作人员采取最小够用授权原则，并定期核查访问人员名单和访问记录。
                （四）我们存储用户个人信息的服务器系统均为安全加固后的操作系统。我们会对服务器操作进行账户审计及监控。如果发现外部公告有安全问题的服务器操作系统，我们会在第一时间会进行服务器安全升级，确保我们所有服务器系统及应用安全。
                （五）我们为工作人员定期举办个人信息保护相关法律法规培训，以加强工作人员的用户个人隐私保护意识。
                （六）如果不幸发生我们的物理、技术或管理防护措施遭到破坏的事件，我们会及时启动应急预案，防止安全事件扩大，按照法律法规的要求上报国家主管机关，并及时采取推送、公告等合理、有效的方式向您告知安全事件的基本情况、可能的影响、已经采取的措施或将要采取的措施等。
            </pre>
            <div class="title2">六、您管理个人信息的权利</div>
            <pre>
                您可以通过关闭设备位置开关而撤回对您相应服务个人信息收集和使用的同意。您可以通过系统设置-应用和权限-应用权限管理对业务功能及权限进行开启或关闭操作。
                请您理解，每项业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理相应的个人信息。但您撤回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
                注销账号，您可以通过邮件的方式申请注销帐号。您知悉并理解，我们产品的账号关联至下述应用（主题、短信、应用锁、云服务、安全防盗），如您在本应用中申请注销账号，将导致您同一账号下的其他关联应用无法正常使用，因前述问题给您造成损失的，我们不承担任何责任。
                当您注销帐号后，我们将停止为您提供产品与服务，并且将删除有关您的相关信息或进行匿名化处理，但法律法规另有规定的除外。若您想再次享受我们的服务，您需要重新注册帐号。如您确需注销账号，请发送邮件至【supportab@126.com】与我们联系。
            </pre>
            <div class="title2">七、未成年人个人信息保护</div>
            <pre>
                <b>未成年人须在监护人的陪同下使用我们的产品与服务，未成年人的监护人应当为未成年人的利益阅读本隐私政策。未成年人如提交个人信息，须取得监护人的同意，并在监护人的指导下完成。若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有疑问时，您可以通过本隐私政策第十节的联系方式与我们联系。</b>
            </pre>
            <div class="title2">八、隐私政策的适用范围</div>
            <pre>
                （一）本政策不适用于我们产品中所含有的第三方产品和服务，当您使用第三方产品或接受第三方提供的服务时，他们可能获取您的信息。因此，我们在此提醒您注意阅读第三方的个人隐私保护政策。
                （二）本政策不适用于在您点击链接后的外部网站收集数据的行为。
            </pre>
            <div class="title2">九、隐私政策的变更和修订</div>
            <pre>
                我们的隐私政策可能变更。未经您明确同意，我们不会限制您按照本隐私政策所应享有的权利。
                对于本隐私政策的重大变更，我们还会提供显著的通知（例如，在软件改版或升级、或您重新登录时，以弹窗形式对您进行及时通知）。
                本政策所指的重大变更包括但不限于：
                ◆ 我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
                ◆ 我们在控制权等方面发生重大变化。如并购重组等引起的所有者变更等；
                ◆ 个人信息共享、转让或公开披露的主要对象发生变化；
                ◆ 您参与个人信息处理方面的权利及其行使方式发生重大变化；
                ◆ 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
                ◆ 个人信息安全影响评估报告表明存在高风险时。
            </pre>
            <div class="title2">十、如何联系我们</div>
            <pre>
                如果您对我们的隐私政策及对您个人信息的处理有任何疑问、意见、建议或投诉，请发送邮件至【supportab@126.com】与我们联系。
                在一般情况下，我们会在15个工作日内对您的需求予以答复。
                我们的注册地址为：陕西省西安市高新区唐延路51号人寿壹中心B座7层713室。
            </pre>
            <div class="title2">十一、本次更新主要内容有：</div>
            <pre>
                （一）信息收集类别的具体化，如产品或服务使用过程中产生的信息和您提供的信息。
                （二）披露了第三方SDK提供方信息。
            </pre>
        </div>
        <Footer />
    </div>
</template>

<script>
import Footer from '@/components/footer'

export default {
    components:{
        Footer
    },
    data() {
        return {
        }
    },
}
</script>

<style lang="scss" scoped>
pre {
    white-space: pre-line;
    margin-top: 0;
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 28px;
}
.header {
    width: 1160px;
    margin: 0 auto;
    img {
        padding: 22px 0;
    }
    a {
        display: inline-block;
    }
}
.text-content {
    width: 760px;
    margin: 0 auto;
    color: #1F1F1F;
    .title {
        font-size: 48px;
        text-align: center;
        padding-top: 80px;
        padding-bottom: 60px;
    }
    .title2 {
        font-weight: bold;
        font-size: 16px;
        padding-bottom: 10px;
    }
    .date {
        color: #A4B9CF;
        font-size: 14px;
        text-align: right;
        line-height: 28px;
        padding-bottom: 20px;
    }
}
</style>

