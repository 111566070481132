<template>
  <div>
    <div class="top-bg">
      <Header class="header" />
      <div class="content">
        <div class="top-text">视频画质</div>
        <div class="top-sub-text">
          包括人像视频虚化、视频HDR、视频降噪、视频夜景等算法SDK和解决方案
        </div>
        <div class="top-btn">
          <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
          <el-button class="coop-btn" round @click="consultClick">合作咨询</el-button>
        </div>
      </div>
    </div>

    <div class="content item-header" style="margin-bottom: 60px">
      <div class="item-title">人像视频虚化</div>
      <div class="item-text">超精度人像分割，0.3-3.5m超远距离分割，识别多种人体姿态</div>
      <div class="video-wrap">
        <my-video :showMask="true" videoUrl="/video_1.mp4?v=20210809" videoWidth="1160" videoHeight="580" style="margin-top:60px;"></my-video>
        <div class="video-text-before">处理前</div>
        <div class="video-text-after">处理后</div>
      </div>
    </div>
    <div class="content item-header" style="margin-bottom: 60px">
      <div class="item-title">视频HDR</div>
      <div class="item-text">还原真实场景，匹配人眼高动态范围，所见即所得</div>
      <div class="video-wrap">
        <my-video :showMask="true" videoUrl="/video_2.mp4?v=20210809" videoWidth="1160" videoHeight="580" style="margin-top:60px;"></my-video>
        <div class="video-text-before">处理前</div>
        <div class="video-text-after">处理后</div>
      </div>
    </div>
    <div class="content item-header" style="margin-bottom: 60px">
      <div class="item-title">视频防抖</div>
      <div class="item-text">替代光学防抖硬件，纯软防抖方案减少运动模糊</div>
      <div class="video-wrap">
        <my-video :showMask="true" videoUrl="/video_3.mp4?v=20210809" videoWidth="1160" videoHeight="580" style="margin-top:60px;"></my-video>
        <div class="video-text-before">处理前</div>
        <div class="video-text-after">处理后</div>
      </div>
    </div>
    <div class="content item-header" style="margin-bottom: 60px">
      <div class="item-title">视频夜景</div>
      <div class="item-text">多重动态曝光，黑夜变白昼，有效抑制噪点、防手抖、去鬼影</div>
      <div class="video-wrap">
        <my-video :showMask="true" videoUrl="/video_4.mp4?v=20210809" videoWidth="1160" videoHeight="580" style="margin-top:60px;"></my-video>
        <div class="video-text-before">处理前</div>
        <div class="video-text-after">处理后</div>
      </div>
    </div>

    <div class="content item-header">
      <div class="item-title">方案优势</div>
    </div>
    <div class="content clear" style="margin-top: 60px;display: flex;">
      <div class="advantage">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../../img/aiImage/phone/video_1.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">跨平台方案</div>
            <div class="advantage-text-sub-title">不依赖特定sensor和ISP</div>
          </div>
        </div>
      </div>
      <div class="advantage" style="margin-left:40px;">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../../img/aiImage/phone/video_2.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">性能优越</div>
            <div class="advantage-text-sub-title">中低端平台性能：1080p@30fps </div>
            <div class="advantage-text-sub-title">高端平台性能：4K@30fps或1080p@60fps</div>
          </div>
        </div>
      </div>
      <div class="advantage" style="margin-left:40px;">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../../img/aiImage/phone/video_3.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">效果领先</div>
            <div class="advantage-text-sub-title">实时高光抑制、暗部提亮、降噪、通透度</div>
            <div class="advantage-text-sub-title">增强，预览和录像同时生效</div>
          </div>
        </div>
      </div>
    </div>

    <div class="content item-header" style="margin-bottom: 120px;">
      <contact></contact>
    </div>
    <dialog-consult ref="consult"></dialog-consult>
    <dialog-apply ref="apply"></dialog-apply>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import contact from "./components/contact"
import DialogConsult from './components/dialogConsult.vue';
import DialogApply from './components/dialogApply'
import MyVideo from './components/myVideo'
export default {
  components: {
    Header,
    Footer,
    contact,
    DialogConsult,
    DialogApply,
    MyVideo
  },

  data() {
    return {
    }
  },

  methods: {
    consultClick () {
      this.$refs.consult.show()
    },
    applyClick () {
      this.$refs.apply.show()
    }
  },

  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
.top-bg {
  position: relative;
  background-image: url("../../img/aiImage/phone/banner_video.jpg");
  background-position: center;
  .header {
    position: absolute;
    top: 0;
    z-index: 100;
    height: 64px;
    width: 100%;
  }
  .top-text {
    font-size: 64px;
    color: #ffffff;
    padding-top: 220px;
    padding-left: 60px;
    box-sizing: border-box;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-sub-text {
    width: 600px;
    height: 72px;
    line-height: 36px;
    margin-top: 40px;
    font-size:18px;
    color: #ffffff;
    padding-left: 60px;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-btn {
    padding-left: 60px;
    margin-top: 72px;
    .apply-btn {
      font-size: 18px;
      color: #fff;
      padding: 14px 37px;
      background-color: #007aff;
      border: none;
      border-radius: 25px;
    }
    .coop-btn {
      font-size: 18px;
      color: #0059FF;
      padding: 14px 37px;
      margin-left: 72px;
      border: none;
      border-radius: 25px;
    }
  }
}

.advantage {
  height: 360px;
  width: 360px;
  background-color: #fff;
  display: inline-block;
  .advantage-wrap {
    align-items: center;
  }
  .advantage-img {
    margin-top: 50px;
    text-align: center;
  }
  .advantage-text {
    .advantage-text-title {
      margin-top: 30px;
      margin-bottom: 10px;
      font-size: 24px;
      color: #1F1F1F;
      letter-spacing: 0;
      text-align: center;
      font-weight: 600;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
    .advantage-text-sub-title {
      font-size: 14px;
      line-height: 24px;
      color: #1F1F1F;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;
      padding: 0 40px;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
  }
}

.video-wrap {
  position: relative;
  .video-text-before {
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    text-shadow: 0 1px 4px rgba(0,0,0,0.40);
    font-weight: 500;
    position: absolute;
    left: 24px;
    top: 24px;
  }
  .video-text-after {
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    text-shadow: 0 1px 4px rgba(0,0,0,0.40);
    font-weight: 500;
    position: absolute;
    left: 604px;
    top: 24px;
  }
}
</style>
