<template>
  <header>
    <div class="content" style="overflow:visible;">
      <img class="logo" @click="$router.push('/')" src="../img/logo-old.svg" width="150" height="30" />
      <ul class="nav">
        <li class="no-select"><a :class="{active: thisPage == 'home' && !isHover}" @click="pageCutover('/')">首页</a></li>
        <li class="no-select"><a target="_blank" href="http://qiteens.360os.com/">奇少年守护</a></li>
        <!-- <li><a :class="{active: thisPage == 'industry'}" @click="pageCutover('/industry')">产业物联网</a></li> -->
        <li style="overflow: visible;height: 64px;">
          <u class="nav-wulian" @mouseenter="navMenuShow" @mouseleave="navMenuHide">
            <li>
              <a :class="{active: thisPage == 'internetThings' && !show && !isHover}" @click="pageCutover('/internetThings')">
              奇络物联<i class="el-icon-arrow-down down-icon"></i>
              </a>
            </li>
            <li>
              <ul class="nav-hide" style="height:0;">
                <li class="nav-bg" style="line-height: 40px;">
                  <a class="nav-item" @click="pageCutover('/industry')">产业互联网</a>
                </li>
                <li class="nav-bg" style="line-height: 40px;">
                  <a class="nav-item" @click="pageCutover('/aiMonitor')">AI监测</a>
                </li>
                <li class="nav-bg" style="line-height: 40px;">
                  <a class="nav-item" @click="pageCutover('/aiZhilian')">AI智控</a>
                </li>
                <li class="nav-bg" style="line-height: 40px;">
                  <a class="nav-item" @click="pageCutover('/aiImage')">AI影像</a>
                </li>
                <li class="nav-bg" style="line-height: 40px;">
                  <a class="nav-item" href="https://evcharge.360os.com" target="_blank">安瓦充</a>
                </li>
              </ul>
            </li>
          </u>
        </li>
        <!-- <li><a :class="{active: thisPage == 'aiMonitor'}" @click="pageCutover('/aiMonitor')">AI监测</a></li> -->
        <li class="no-select"><a :class="{active: thisPage == 'ecology' && !isHover}" @click="pageCutover('/ecology')">OS生态</a></li>
        <!-- <li><a :class="{active: thisPage == 'aiImage' || fullPath.includes('aiImage')}" @click="pageCutover('/aiImage')">AI影像</a></li> -->
        <li class="no-select"><a :class="{active: thisPage == 'about' && !isHover}" @click="pageCutover('/about')">关于我们</a></li>
        <!-- <li><a @click="$router.push('/mall')">OS商城</a></li> -->
        <!-- <li>
          <el-dropdown>
            <el-button size="mini" style="color: #fff;background: none;">
              中文<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>English</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li> -->
      </ul>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      thisPage: '',
      fullPath: '',
      show: false,
      isHover: false
    }
  },
  methods: {
    pageCutover(path) {
      this.$router.push(path)
    },
    navMenuShow () {
      console.log('show')
      this.show = true
      $('.nav-hide').stop().animate({height: '200px'})
    },
    navMenuHide () {
      console.log('hide')
      this.show = false
      $('.nav-hide').stop().animate({height: '0'})
    }
  },
  watch: {
    $route: {
        handler: function (route) {
          this.thisPage = route.name
          this.fullPath = route.fullPath
        },
        immediate: true
    }
  },
  mounted() {
    const _this = this
    $(document)
    .off('mouseenter', '.nav .no-select')
    .on('mouseenter', '.nav .no-select', function() {
      _this.isHover = true
    })
    .off('mouseleave', '.nav .no-select')
    .on('mouseleave', '.nav .no-select', function() {
      _this.isHover = false
    })
  }
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: inherit;
}
.content {
  overflow: hidden;
}
header {
  height: 64px;
  color: rgba(255,255,255,0.7);
  // border-bottom: 1px solid rgba(255,255,255,0.4);
  background-color: rgba(0,0,0,0.4);
}
.logo {
  width: 150px;
  height: 30px;
  float: left;
  padding: 17px 0;
}
.nav {
  float: right;
  li {
    float: left;
    font-size: 14px;
    // margin-left: 20px;
    line-height: 64px;
    a {
      width: 100px;
      display: inline-block;
      cursor: pointer;
      text-align: center;
    }
    a.active {
      color: #fff;
      font-weight: bold;
      background-image: url('../img/nav_active.svg');
      background-repeat: no-repeat;
      background-position: center 60px;
    }
    &:hover {
      color: #fff;
    }
  }
}

.nav-wulian {
  float: none;
  .nav-bg {
    background-image: url('../img/nav-bg1.svg');
  }
  .down-icon {
    font-size: 8px !important;
    margin-left: 3px;
    color: #e0e0e0;
  }
  & a {
    color: rgba(255,255,255,0.6);
    &:hover {
      color: #fff;
      .down-icon {
        color: #fff;
      }
    }
  }
  &>li {
    float: none;
    .nav-item {
      line-height: 40px;
    }
  }
}

  .nav-hide {
    float: none;
    position: absolute;
    overflow: hidden;
    &>li {
      float: none;
      .nav-item {
        line-height: 40px;
        text-decoration: none;
      }
      &>a:hover {
        // background-color: rgba(14,78,181,1);
        background-image: url('../img/nav-bg-hover.svg');
      }
      &>a:active {
        background-color: rgba(2,103,255,1);
      }
      &>a:visited {
        background-color: rgba(2,103,255,1);
      }
    }
  }
</style>