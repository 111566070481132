<template>
  <div>
    <div class="top-bg">
      <Header class="header" />
      <div class="content">
        <div class="top-text">场景识别</div>
        <div class="top-sub-text">
          支持多种人、物、场景识别，针对场景优化图像效果，让人像更美、蓝天更蓝、逆光更清晰......
        </div>
        <div class="top-btn">
          <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
          <el-button class="coop-btn" round @click="consultClick">合作咨询</el-button>
        </div>
      </div>
    </div>

    <div class="content item-header">
      <div class="item-title">方案介绍</div>
      <div class="item-text">智能识别200+场景、16个标签，提供AI场景相机整体解决方案</div>
    </div>
    <div class="content" style="margin-top: 60px">
      <img src="../../img/aiImage/phone/scene_1.png" alt="">
    </div>
   
    <div class="content item-header">
      <div class="item-title">方案优势</div>
    </div>
    <div class="content clear" style="margin-top: 60px">
      <div class="solution-wrap">
        <div class="solution">
          <div class="solution-top">
            <img src="../../img/aiImage/phone/scene_2.png" alt="">
            <div class="top-item">
              <div class="top-title">准确率高</div>
              <div class="top-text">·200+场景</div>
              <div class="top-text">·16个标签</div>
            </div>
          </div>
          <div style="margin-left: 71px;">
            <div class="item" style="margin-top: 38px;">
              <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
              <span class="text">支持食物、宠物、建筑、人像、花卉、蓝天、绿植、日出/日落、</span>
            </div>
            <div class="item" style="margin-left:26px;">
              <span class="text">夜景、逆光、汽车、文本、水果、秋色雪景等16个标签</span>
            </div>
            <div class="item" style="margin-top:10px;">
              <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
              <span class="text">AI深度学习算法+ 传统计算机视觉处理相结合 ，识别准确率平均     </span>
            </div>
            <div class="item" style="margin-left:26px;">
              <span class="text">91.8%</span>
            </div>
          </div>
        </div>

          <div class="solution" style="margin-left:40px;">
           <div class="solution-top">
            <img src="../../img/aiImage/phone/scene_3.png" alt="">
            <div class="top-item">
              <div class="top-title">识别速度快</div>
              <div class="top-text">·小模型</div>
              <div class="top-text">·低内存</div>
            </div>
            </div>
            <div style="margin-left: 71px;">
              <div class="item" style="margin-top: 38px;">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">模型大小：5M</span>
              </div>
              <div class="item" style="margin-top:10px;">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">识别速度：SD660（100ms），MT6750(150ms)</span>
              </div>
              <div class="item" style="margin-top:10px;">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">峰值内存：35M</span>
              </div>
            </div>
          </div>

          <div class="solution" style="margin-top:40px;">
            <div class="solution-top">
            <img src="../../img/aiImage/phone/scene_4.png" alt="">
            <div class="top-item">
              <div class="top-title">整体解决方案</div>
              <div class="top-text">·整体解决方案完善</div>
            </div>
            </div>
            <div style="margin-left: 71px;">
              <div class="item" style="margin-top: 38px;">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">支持MTK平台和QCOM平台AI场景相机APP-Framework-</span>
              </div>
              <div class="item" style="margin-left:26px;">
                <span class="text">HAL-3A整体实现框架</span>
              </div>
              <div class="item" style="margin-top:10px;">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">支持MTK平台和QCOM平台AI场景相机不同场景效果优化方案</span>
              </div>
              <div class="item" style="margin-top:10px;">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">支持预览效果AI实时美化</span>
              </div>
            </div>
          </div>
          <div class="solution" style="margin-top:40px;margin-left: 40px;">
            <div class="solution-top">
            <img src="../../img/aiImage/phone/scene_5.png" alt="">
            <div class="top-item">
              <div class="top-title">兼容性</div>
              <div class="top-text">·兼容性良好</div>
            </div>
            </div>
            <div style="margin-left: 71px;">
              <div class="item" style="margin-top: 38px;">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">基于TensorFlow-lite深度学习加速框架</span>
              </div>
              <div class="item" style="margin-top:10px;">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">不依赖任何硬件加速，如NPU或者、QCOM平台的AIE，也支持</span>
              </div>
              <div class="item" style="margin-left:26px;">
                <span class="text">AIE</span>
              </div>
              <div class="item" style="margin-top:10px;">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">识别算法全平台通用</span>
              </div>
              <div class="item" style="margin-top:10px;">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">同一套算法可用于相册的场景分类</span>
              </div>
            </div>
          </div>
      </div>
    </div>

    <div class="content item-header" style="margin-bottom: 120px;">
      <contact></contact>
    </div>
    <dialog-consult ref="consult"></dialog-consult>
    <dialog-apply ref="apply"></dialog-apply>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import contact from "./components/contact"
import DialogConsult from './components/dialogConsult.vue';
import DialogApply from './components/dialogApply'
export default {
  components: {
    Header,
    Footer,
    contact,
    DialogConsult,
    DialogApply
  },

  data() {
    return {
    }
  },

  methods: {
    consultClick () {
      this.$refs.consult.show()
    },
    applyClick () {
      this.$refs.apply.show()
    }
  },

  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
.top-bg {
  position: relative;
  background-image: url("../../img/aiImage/phone/banner_sence.jpg");
  background-position: center;
  .header {
    position: absolute;
    top: 0;
    z-index: 100;
    height: 64px;
    width: 100%;
  }
  .top-text {
    font-size: 64px;
    color: #ffffff;
    padding-top: 220px;
    padding-left: 60px;
    box-sizing: border-box;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-sub-text {
    width: 600px;
    height: 72px;
    line-height: 36px;
    margin-top: 40px;
    font-size:18px;
    color: #ffffff;
    padding-left: 60px;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-btn {
    padding-left: 60px;
    margin-top: 72px;
    .apply-btn {
      font-size: 18px;
      color: #fff;
      padding: 14px 37px;
      background-color: #007aff;
      border: none;
      border-radius: 25px;
    }
    .coop-btn {
      font-size: 18px;
      color: #0059FF;
      padding: 14px 37px;
      margin-left: 72px;
      border: none;
      border-radius: 25px;
    }
  }
}

.solution-wrap {
  display: flex;
  flex-wrap: wrap;
  .solution {
    background-color: #fff;
    width: 560px;
    height: 420px;
    .solution-top {
      margin-top: 60px;
      margin-left: 60px;
      vertical-align: middle;
      &>img {
        vertical-align: middle;
      }
      .top-item {
        margin-left: 40px;
        display: inline-block;
        vertical-align: middle;
        .top-title {
          font-size: 24px;
          color: #1F1F1F;
          letter-spacing: 0;
          font-weight: 600;
          margin-bottom: 10px;
        }
        .top-text {
          font-size: 16px;
          color: #1F1F1F;
          letter-spacing: 0;
          line-height: 24px;
        }
      }
    }
    .title {
      margin-top: 30px;
      margin-bottom: 10px;
      font-size: 24px;
      color: #1F1F1F;
      letter-spacing: 0;
      text-align: center;
      font-weight: bold;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
    .item {
      line-height: 24px;
      height: 24px;
      vertical-align: middle;
      width: 430px;
      .check-img {
        margin-right: 10px;
        vertical-align: middle;
      }
      .text {
        font-size: 14px;
        color: #1F1F1F;
        letter-spacing: 0;
        line-height: 24px;
        vertical-align: middle;
        font-family: "Microsoft YaHei","PingFangSC-Regular";
      }
    }
  }
}


</style>
