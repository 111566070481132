<template>
  <div>
    <div class="top-bg">
      <Header class="header" />
      <div class="content">
        <div class="top-text">人像美化</div>
        <div class="top-sub-text">
          智能美颜、美体，多种滤镜类型。独家AI补光，不打灯也超美。
        </div>
        <div class="top-btn">
          <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
          <el-button class="coop-btn" round @click="consultClick">合作咨询</el-button>
        </div>
      </div>
    </div>

    <div class="content item-header">
      <div class="item-title">AI美颜</div>
      <div class="item-text">AI检测和传统肤色检测相结合，有效保护眉毛、眼睛、嘴唇等部位的背景和纹理。</div>
    </div>
    <div class="content clear" style="margin-top: 60px">
      <img src="../../img/aiImage/onlineEdu/fun/ai_face.png" >
    </div>

    <div class="content item-header">
      <div class="item-title">AI补光灯</div>
      <div class="item-text">通过肤色检测及光线检测判断补光需求，立体环绕打光，打造通透高级肤感。</div>
    </div>
    <div class="content clear" style="margin-top: 60px">
      <img src="../../img/aiImage/onlineEdu/fun/ai_light.png" >
      <div class="light-item">
        <img class="check-img" src="../../img/aiImage/onlineEdu/fun/check_icon.png" alt="">
        <span class="text">人脸分割</span>
        <img class="check-img" src="../../img/aiImage/onlineEdu/fun/check_icon.png" alt="">
        <span class="text">立体补光</span>
        <img class="check-img" src="../../img/aiImage/onlineEdu/fun/check_icon.png" alt="">
        <span class="text">安全护眼</span>
      </div>
    </div>

    <div class="content item-header">
      <div class="item-title">AI美体</div>
      <div class="item-text">精准人体检测分割，模拟黄金比例一键美体；同时支持不同部位针对性调整，环肥燕瘦各有所美。</div>
    </div>
    <div class="content clear" style="margin-top: 60px">
      <img src="../../img/aiImage/onlineEdu/fun/ai_body.png" >
    </div>

    <div class="content item-header">
      <div class="item-title">静态滤镜</div>
      <div class="item-text">30多种主流滤镜满足不同场景和风格需求，瞬间提升拍摄质感。</div>
    </div>
    <div class="content clear" style="margin-top: 60px">
      <img src="../../img/aiImage/onlineEdu/fun/static_filters.png" >
    </div>

    <div class="content item-header">
      <div class="item-title">动态滤镜</div>
      <div class="item-text">多种炫酷特效，满足当下潮流视频玩法，让普通用户轻松晋升技能玩家。</div>
    </div>
    <div class="content clear" style="margin-top: 60px">
      <img src="../../img/aiImage/onlineEdu/fun/motion_filters.png" >
    </div>

    <div class="content item-header" style="margin-bottom: 120px;">
      <contact></contact>
    </div>
    <dialog-consult ref="consult"></dialog-consult>
    <dialog-apply ref="apply"></dialog-apply>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import contact from "./components/contact"
import DialogConsult from './components/dialogConsult.vue';
import DialogApply from './components/dialogApply'
export default {
  components: {
    Header,
    Footer,
    contact,
    DialogConsult,
    DialogApply
  },

  data() {
    return {
    }
  },

  methods: {
    consultClick () {
      this.$refs.consult.show()
    },
    applyClick () {
      this.$refs.apply.show()
    }
  },

  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
.top-bg {
  position: relative;
  background-image: url("../../img/aiImage/onlineEdu/fun/header_1.jpg");
  background-position: center;
  .header {
    position: absolute;
    top: 0;
    z-index: 100;
    height: 64px;
    width: 100%;
  }
  .top-text {
    font-size: 64px;
    color: #ffffff;
    padding-top: 220px;
    padding-left: 60px;
    box-sizing: border-box;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-sub-text {
    width: 600px;
    height: 72px;
    line-height: 36px;
    margin-top: 40px;
    font-size:18px;
    color: #ffffff;
    padding-left: 60px;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-btn {
    padding-left: 60px;
    margin-top: 72px;
    .apply-btn {
      font-size: 18px;
      color: #fff;
      padding: 14px 37px;
      background-color: #007aff;
      border: none;
      border-radius: 25px;
    }
    .coop-btn {
      font-size: 18px;
      color: #0059FF;
      padding: 14px 37px;
      margin-left: 72px;
      border: none;
      border-radius: 25px;
    }
  }
}

.light-item {
  height: 96px;
  line-height: 96px;
  text-align: center;
  vertical-align: middle;
  .check-img {
    vertical-align: middle;
    margin-right: 8px;
  }
  .text {
    vertical-align: middle;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
    font-size: 20px;
    color: #1F1F1F;
    letter-spacing: 0;
    margin-right: 42px;
  }
}

</style>
