<template>
  <div class="home">
    <div class="banner-box">
      <Header class="header" />
      <el-carousel :height="bannerHeight" :interval="5000" arrow="never" :autoplay="false" indicator-position="none">
        <el-carousel-item class="banner-img img0">
          <div class="main-content" @click="toQiteens">
            <div class="left">
              <div class="button-title">360OS 奇少年</div>
              <div class="big-minder">防沉迷手机</div>
              <div class="small-minder">奇少年手机，为青少年而生</div>
            </div>
          </div>
        </el-carousel-item>
        <!--<el-carousel-item class="banner-img img1">
          <div class="banner-text">
            <div class="banner-tag" style="width:240px;">奇络物联</div>
            <div class="banner-title">以安全基座 深耕应用型AI</div>
            <div class="banner-sub-title">为垂直行业智能升级提供一站式解决方案</div>
          </div>
        </el-carousel-item>
        <el-carousel-item class="banner-img img2">
          <div class="banner-text">
            <div class="banner-tag" style="width:460px;">奇络安全产业物联网</div>
            <div class="banner-title">安全赋能 成就变革</div>
            <div class="banner-sub-title">端到端物联网安全性解决方案</div>
          </div>
        </el-carousel-item>
        <el-carousel-item class="banner-img img3">
          <div class="banner-text">
            <div class="banner-tag" style="width:280px;">奇络AI监测</div>
            <div class="banner-title">丰富算法 助力成长</div>
            <div class="banner-sub-title">端到端一体化AI监测解决方案</div>
          </div>
        </el-carousel-item>
        <el-carousel-item class="banner-img img4">
          <div class="banner-text">
            <div class="banner-tag" style="width:240px;">奇络智联</div>
            <div class="banner-title">安全管理 智慧运维</div>
            <div class="banner-sub-title">“云、边、网”一站式服务与管理</div>
          </div>
        </el-carousel-item>
        <el-carousel-item class="banner-img img5">
          <div class="banner-text">
            <div class="banner-tag" style="width:280px;">奇络AI影像</div>
            <div class="banner-title">聚合算力 AI赋能</div>
            <div class="banner-sub-title">全栈式、一体化AI智能解决方案</div>
          </div>
        </el-carousel-item>-->
      </el-carousel>
    </div>
    <div>
      <div class="content">
        <div class="title">
          引领产业走向智慧物联新时代
        </div>
        <div class="some-msg clear">
          <img class="some-msg-rigth" src="../img/some-msg1.png" />
          <div>
            <h1>奇络物联</h1>
            <h2>以安全为基座，深耕应用型AI</h2>
            <div style="line-height: 1.8;">依靠在智能设备产品领域技术积累以及应用快速落地的优势，聚焦产业物联网市场生产运营智能升级与转型需求，为垂直行业用户提供一站式物联网解决方案。</div>
            <el-button class="more" round @click="$router.push('/internetThings')">了解更多 <i class="el-icon-arrow-right"></i></el-button>
          </div>
        </div>
        <div class="some-msg clear">
          <div class="some-msg-rigth" style="padding-left: 60px;">
            <h1>OS产品技术生态系统</h1>
            <h2>为泛智能硬件领域提供技术平台服务</h2>
            <div style="line-height: 1.8;">为全球智能硬件厂商提供完整OS、OS差异化功能、互联网增值服务等系列完整解决方案。</div>
            <el-button class="more" round @click="$router.push('/ecology')">了解更多 <i class="el-icon-arrow-right"></i></el-button>
          </div>
          <img class="some-msg-rigth" style="margin: 0" src="../img/some-msg2.png" />
        </div>
      </div>
      <div class="content">
        <div class="video">
          <div class="video-text-one">奇络物联人工智能物联网平台</div>
          <div class="video-text-tow">赋能百行百业智能升级，致力成为具有国际竞争力的物联网平台</div>
          <!-- <div class="video-text-botton"><el-button class="video-btn" @click="videostart = !videostart" type="primary" round plain>观看视频 <i class="el-icon-caret-right"></i></el-button></div> -->
        </div>
      </div>
      <div class="content">
        <div class="title">
          我们的领先优势
        </div>
        <div class="odds-msg">
          360OS 作为360集团大安全战略重要成员，依靠其在智能设备领域的深厚技术累积，能够以更创新的市场运营及产品研发体系，<br />
          为百行百业智能转型与升级提供快速、定制化的行业解决方案，加速商业价值的提升。
        </div>

        <div class="odds-img">
          <div class="odds-img-box">
            <img src="../img/home/odds_2.png" />
            <div class="odds-img-box-title">IOT安全监测及防护体系</div>
            <div class="odds-img-box-msg">· 全域防护设备、通信与云端安全</div>
            <div class="odds-img-box-msg">· 雄厚的工业物联网安全技术</div>
          </div>
          <div class="odds-img-box" style="margin-left:40px;">
            <img src="../img/home/odds_1.png" />
            <div class="odds-img-box-title">丰富应用型算法库</div>
            <div class="odds-img-box-msg">· 自研场景化算法</div>
            <div class="odds-img-box-msg">· 一站式AI平台</div>
            <div class="odds-img-box-msg">· 360集团行业领先AI技术</div>
          </div>
          <div class="odds-img-box" style="margin-left:40px;">
            <img src="../img/home/odds_3.png" />
            <div class="odds-img-box-title">丰富的场景化产品及解决方案</div>
            <div class="odds-img-box-msg">· 自研智能化感知终端设备</div>
            <div class="odds-img-box-msg">· 成熟组件化的人工智能物联网平台</div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="title">
          全球化布局
        </div>
        <div class="map-box">
          <img class="map-point" style="top:244px;left:855px;" width="42" height="42" src="../img/home/map_center.png" />
          <img class="map-point" style="top:167px;left:155px;" width="42" height="42" src="../img/home/map_point.png" />
          <img class="map-point" style="top:254px;left:201px;" width="60" height="60" src="../img/home/map_point.png" />
          <img class="map-point" style="top:503px;left:336px;" width="22" height="22" src="../img/home/map_point.png" />
          <img class="map-point" style="top:431px;left:358px;" width="36" height="36" src="../img/home/map_point.png" />
          <img class="map-point" style="top:279px;left:530px;" width="36" height="36" src="../img/home/map_point.png" />
          <img class="map-point" style="top:223px;left:562px;" width="60" height="60" src="../img/home/map_point.png" />
          <img class="map-point" style="top:353px;left:609px;" width="36" height="36" src="../img/home/map_point.png" />
          <img class="map-point" style="top:415px;left:637px;" width="22" height="22" src="../img/home/map_point.png" />
          <img class="map-point" style="top:327px;left:803px;" width="60" height="60" src="../img/home/map_point.png" />
          <img class="map-point" style="top:400px;left:824px;" width="36" height="36" src="../img/home/map_point.png" />
          <img class="map-point" style="top:368px;left:888px;" width="22" height="22" src="../img/home/map_point.png" />
          <img class="map-point" style="top:480px;left:960px;" width="22" height="22" src="../img/home/map_point.png" />
          <img class="map-point" style="top:270px;left:943px;" width="42" height="42" src="../img/home/map_point.png" />
          <img class="map-img" src="../img/home_map.png" />
        </div>
      </div>
      <div style="background: #fff;padding: 90px 0 102px 0">
        <div class="content">
        <div class="title" style="margin:0">
          新闻中心
          <a class="show-all" @click="$router.push('/news')">查看全部 <i class="el-icon-arrow-right" style="font-size: 18px;font-weight: bold;"></i></a>
        </div>
        <div class="news clear">
          <div class="left-img-box">
            <img src="../img/home/news.jpg" />
            <div @click="$router.push({ path: '/newsDetailsA', query: { id: list[0].id }})">
              <template v-if="list.length == 0">
                <div class="center">暂无数据</div>
              </template>
              <template v-else>
                  <div>
                    <div class="news-list-date"><span>{{list[0].date}}</span> <div class="news-from">{{list[0].source}}</div></div>
                  </div>
                  <div class="newsAbstr">
                    {{list[0].title}}
                  </div>
                  <!-- <div style="margin-top:36px;text-align:right;">
                    <img style="cursor: pointer;" src="../img/home/more.png" alt="">
                  </div> -->
              </template>
            </div>
          </div>
          
          <div class="news-msg">
             <template v-for="(item, index) in list">
              <div v-if="index != 0" :key="item.id" @click="$router.push({ path: '/newsDetailsA', query: { id: item.id }})" class="news-list">
                <div class="news-list-date"><span>{{item.date}}</span> <div class="news-from">{{item.source}}</div></div>
                <div class="news-msg-cnt">{{item.title}}</div>
              </div>
            </template>
            <template v-if="list.length < 2">
              <div class="center">暂无数据</div>
            </template>
          </div>
        </div>
        </div>
      </div>
      <div class="content">
        <div class="title">合作伙伴</div>
        <div class="partner">
          <el-carousel height="440px" arrow="never" :interval="4000" indicator-position="outside">
            <el-carousel-item class="partner-item">
              <img src="../img/home/partner/1.png" />
              <img src="../img/home/partner/2.png" />
              <img src="../img/home/partner/3.png" />
              <img src="../img/home/partner/28.png" />
              <img src="../img/home/partner/29.png" />
              <img src="../img/home/partner/23.png" />
              <img src="../img/home/partner/24.png" />
              <img src="../img/home/partner/14.png" />
              <img src="../img/home/partner/26.png" />
              <img src="../img/home/partner/27.png" />
              <img src="../img/home/partner/25.png" />
              <img src="../img/home/partner/15.png" />
              <img src="../img/home/partner/7.png" />
              <img src="../img/home/partner/12.png" />
              <img src="../img/home/partner/9.png" />
            </el-carousel-item>
            <el-carousel-item class="partner-item">
              <img src="../img/home/partner/4.png" />
              <img src="../img/home/partner/5.png" />
              <img src="../img/home/partner/6.png" />
              <img src="../img/home/partner/8.png" />
              <img src="../img/home/partner/10.png" />
              <img src="../img/home/partner/16.png" />
              <img src="../img/home/partner/17.png" />
              <img src="../img/home/partner/18.png" />
              <img src="../img/home/partner/19.png" />
              <img src="../img/home/partner/20.png" />
              <img src="../img/home/partner/11.png" />
              <img src="../img/home/partner/13.png" />
              <img src="../img/home/partner/21.png" />
              <img src="../img/home/partner/22.png" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <Footer />
    <Video :url="require('../img/home/os.mp4')" :dialogVisible="videostart" />
    <img class="go-top" @click="backTop" v-if="scrollTop > scrollHeight" src="../img/home/go_top.png" alt="">
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
import Video from '@/components/video'
import api from "@/server/api"
export default {
  components: {
    Header,
    Footer,
    Video
  },
  data() {
    return {
      // bannerHeight: '680px',
      bannerHeight: '43.895833vw',
      screenWidth: 0,
      videostart: false,
      list: [],
      scrollTop: 0,
      scrollHeight: 0
    }
  },
  watc: {
    screenWidth(val) {
      if (val < 1325) {
        console.log(111)
      } else {
        console.log(222)
      }
    }
  },
  methods: {
    loadNews() {
      this.$ajax.post(api.newsList, {
        pageNum: 1,
        pageSize: 5,
        type: 1
      }).then(
        res => {
          this.list = res.data.data
        }
      )
    },
    // 保存滚动值，这是兼容的写法
    handleScroll () {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    },

    // 滚动条回到顶部
    backTop () {
      document.documentElement.scrollTop = 0
    },
    // 调转奇少年官网
    toQiteens () {
      console.log('调转奇少年官网')
      window.open('http://qiteens.360os.com/')
    }
  },
  mounted() {
    this.loadNews()
    let html = document.querySelector('html')
    window.addEventListener('scroll', this.handleScroll, true)
    html.scrollTop = 0
    this.scrollHeight = html.scrollHeight * 0.4

    // let fun = () => {
    //   let w = window.innerWidth
    //   if (w < 1024) {
    //     this.bannerHeight = '480px'
    //   } else {
    //     this.bannerHeight = '680px'
    //   }
    // }
    // fun()
    // window.onresize = fun
    
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>
<style>
.home .el-carousel__arrow {
  background-color: rgba(0,0,0,0.2);
  height: 60px;
  width: 60px;
  font-size: 22px;
  font-weight: bold;
}
.home .el-carousel__arrow:hover {
  background-color: rgba(288,288,288,0.2);
}
.home .el-carousel__arrow .el-icon-arrow-right {
  font-weight: bold;
}
.home .el-carousel__arrow .el-icon-arrow-left {
  font-weight: bold;
}

.partner .el-carousel__button {
  width: 0;
  height: 0;
  border: 4px solid #43dba2;
  border-radius: 50%;
}
</style>
<style lang="scss" scoped>
.go-top {
  cursor: pointer;
  position: fixed;
  z-index: 1000;
  bottom: 40px;
  right: 40px;
}
.newsAbstr {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  margin-top: 10px;
  font-size: 24px;
}
.center {
  text-align: center;
}
.video-btn {
  background: none;
  border-color: #0e73ff;
  color: #0e73ff;
  font-size: 18px;
  border-radius: 30px;
  padding: 14px 26px;
}
.partner {
  margin: 60px -10px 120px -10px;
  padding: 0 -10px;
  .partner-img {
    height: 120px;
    width: 216px;
  }
  .partner-item {
    img {
      margin: 10px;
      height: 120px;
      width: 216px;
    }
  }
}
.show-all {
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  font-weight: normal;
  float: right;
}
.news {
  margin-top: 30px;
  .left-img-box {
    width: 360px;
    float: left;
    margin-right: 40px;
    >img {
      display: block;
    }
    >div {
      cursor: pointer;
      height: 240px;
      box-sizing: border-box;
      background: #F0F0F0;
      padding: 20px;
    }
    .news-from {
      margin-left: 15px;
      padding-left: 15px;
      display: inline-block;
      border-left: 2px solid #c6defa;
    }
  }
  .news-msg {
    float: left;
    width: 760px;
    color: #2B2B2B;
    font-size: 24px;
    .news-list {
      height: 110px;
      padding-top: 20px;
      padding-left: 20px;
      box-sizing: border-box;
      cursor: pointer;
      border-bottom: 1px solid #ddd;
      &:hover {
        background: #F5F5F5;
      }
      &:last-child {
        border-bottom: none;
      }
    }
    
  }
  .news-from {
    margin-left: 15px;
    padding-left: 15px;
    display: inline-block;
    border-left: 2px solid #c6defa;
  }
  .news-list-date {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
    color: #AEAEAE;
    .news-from {
      font-size: 16px;
    }
    span {
      color: #1F1F1F;
      opacity: 0.4;
      vertical-align: middle;
    }
  }
  .news-msg-cnt {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}
.map-box {
  position: relative;
  .map-img {
    width: 1040px;
    height: 590px;
    display: block;
    margin: 80px auto 116px auto;
  }
  .map-point {
    z-index: 100px;
    display: block;
    position: absolute;
  }
  .map-point:hover {
    
  }
}

.more {
  font-size: 18px;
  margin-top: 45px;
  border-radius: 30px;
  padding: 14px 26px;
  background: #fff;
}
.odds-img {
  vertical-align: middle;
  .odds-img-box {
    color: #1F1F1F;
    font-size: 14px;
    display: inline-block;
    height: 420px;
    width: 360px;
    text-align: center;
    background: #fff;
    vertical-align: middle;
    >img {
      margin-top: 60px;
    }
    .odds-img-box-title {
      margin-top: 40px;
      padding-bottom: 22px;
      font-size: 24px;
      font-weight: bold;
    }
    .odds-img-box-msg {
      line-height: 24px;
      width: 240px;
      margin-left: 60px;
    }
  }
  margin-top: 60px;
}
.odds-msg {
  color: #1F1F1F;
  margin-top: 40px;
  font-size: 16px;
  line-height: 28px;
  padding-left: 20px;
}
.video {
  .video-text-one {
    padding-top: 123px;
    font-size: 48px;
  }
  .video-text-tow {
    padding-top: 20px;
    font-size: 22px;
    opacity: 0.6;
  }
  .video-text-botton {
    padding-top: 72px;
  }
  color: #fff;
  text-align: center;
  margin-top: 120px;
  width: 1160px;
  height: 360px;
  background-image: url('../img/home_center.jpg');
}
.some-msg {
  margin-top: 80px;
  font-size: 16px;
  h1 {
    margin-top: 40px;
    font-size: 36px;
    margin-bottom: 14px;
  }
  h2 {
    font-size: 24px;
    margin-bottom: 24px;
  }
  .some-msg-rigth {
    margin-right: 60px;
  }
  img {
    float: left;
    width: 500px;
    height: 340px;
  }
  > div {
    color: #1F1F1F;
    float: left;
    width: 540px;
  }
}
.banner-box {
  position: relative;
  .header {
    position: absolute;
    z-index: 100;
    height: 64px;
    width: 100%;
    top: 0;
  }
  .banner-img {
    background-position: center;
    color: #fff;
    text-align: center;
    h1 {
      font-size: 60px;
    }
    h2 {
      margin-top: 6px;
      font-size: 30px;
    }
    .banner-text {
      margin-top: 206px;
      margin-left: 246px;
    }
    .banner-tag {
      font-size: 36px;
      color: #FFFFFF;
      line-height: 64px;
      text-align: center;
      background: rgba(0,0,0,0.31);
      border: 1px solid rgba(255,255,255,0.60);
      letter-spacing: 10px;
    }
    .banner-title {
      font-size: 54px;
      color: #FFFFFF;
      letter-spacing: 1.58px;
      font-weight: 200;
      margin-top: 46px;
      text-align: left;
    }
    .banner-sub-title {
      margin-top: 30px;
      font-size: 32px;
      color: #FFFFFF;
      letter-spacing: 0.93px;
      font-weight: 200;
      text-align: left;
    }
  }
  .img0 {
    background: url('../img/home/banner0.png') no-repeat;
    background-size: cover; 
  }
  .main-content {
    box-sizing: border-box;
    display: flex;
    width: 57.395833vw;
    padding-top: 12.1875vw;
    margin: 0 auto;
    cursor: pointer;
  }

  .main-content .left {
    box-sizing: border-box;
  }

  .button-title{
    width: 19.791667vw;
    height: 4.427083vw;
    margin-bottom: 0.989583vw;
    background: linear-gradient(130deg, #4CDE6E 0%, #00C088 100%);
    border-radius: 2.239583vw;
    font-size: 2.5vw;
    color: #FFFFFF;
    line-height: 4.427083vw;
    text-align: center;
    // font-family: OPPOSans-B, OPPOSans;
    font-weight: normal;
  }

  .big-minder {
    height: 4.6875vw;
    font-size: 3.75vw;
    // font-family: OPPOSans-B, OPPOSans;
    font-weight: normal;
    color: #191919;
    line-height: 4.6875vw;
    text-align: center;
    margin-bottom: 0.729167vw;
  }
  .small-minder {
    font-size: 1.25vw;
    // font-family: OPPOSans-M, OPPOSans;
    font-weight: normal;
    color: #333333;
    line-height: 1.875vw;
    padding-left: 0.520833vw;
  }
  .img1 {
    background-image: url('../img/home/banner1.png');
  }
  .img2 {
    background-image: url('../img/home/banner2.png');
  }
  .img3 {
    background-image: url('../img/home/banner3.png');
  }
  .img4 {
    background-image: url('../img/home/banner4.png');
  }
  .img5 {
    background-image: url('../img/home/banner5.png');
  }
}
.title {
  padding-left: 20px;
  margin-top: 120px;
  font-size: 36px;
  font-weight: bold;
}
</style>