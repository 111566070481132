<template>
  <div>
    <div class="top-bg">
      <Header class="header" />
      <div class="top-bg-text content">
        奇络安全产业物联网
        <div>
          <el-button
            @click="videostart = !videostart"
            type="primary"
            round
            plain
            style="background: #007AFF;border-color: #007AFF;color: #fff;font-size: 18px;margin-top: 156px;"
            >理念视频
            <i style="font-weight: bold;" class="el-icon-caret-right"></i
          ></el-button>
        </div>
        <div style="margin-top: 26px;font-size:18px;line-height: 36px;">
          奇络安全产业物联网以行业客户的物联感知、大数据、AI为抓手，基于360安全技术和物联技术构建行业应用，<br />提高企业的运行效率、服务品质和安全保障能力。
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title" style="font-weight: bold;">
        我们的优势
      </div>
      <div class="text">
        在产品和服务上进行全面战略升级，为合作伙伴提供优质的行业应用服务和硬件产品。
      </div>
      <div class="application clear">
        <div>
          <img src="../img/industry/2.svg" alt="" />
          <div class="title">新架构 新技术</div>
          <div class="text">
            采用全新安全系统架构，在客户价值、新技术应用、在线运营、行业经验等方向进行全面革新思考
          </div>
        </div>
        <div>
          <img src="../img/industry/3.svg" alt="" />
          <div class="title">端到端 一体化</div>
          <div class="text">
            提供软硬件端到端一体化的能力，能更快速解决物联网用户真实需求
          </div>
        </div>
        <div>
          <img src="../img/industry/4.svg" alt="" />
          <div class="title">安全基因 全力保障</div>
          <div class="text">
            通过自动化的生产服务链，从系统实现自身构建安全的基础，快速、⾼质量的交付产品，实现用户价值
          </div>
        </div>
      </div>
      <div class="title">
        <b>场景应用展示</b>
      </div>
      <div
        class="text"
        style="margin-bottom: 100px;"
      >
        360安全产业物联网结合360集团全球领先的安全技术，深入贴合用户应用场景，为行业合作伙伴打造定制化应用和硬件终端。
      </div>
      <div class="scenes">
        <div class="img-box img6" name="img10" style="top:73px;left:400px;">
          <img src="../img/industry/6.png" alt="" />
          <div class="img10">公有云 · 私有云 · 混合云 · 多云</div>
        </div>

        <div class="img-box img7" name="img11" style="top:224px;left:300px">
          <img src="../img/industry/7.png" width="74" />
          <div class="img11">卫星通信</div>
        </div>

        <div class="img-box img8" name="img13" style="top:336px;left:543px">
          <img src="../img/industry/8.png" width="74" alt="" />
          <div class="img13">
            无线网络<br />
            2G. 3G. 4G. 5G. <br />
            NB-IoT. WiFi. LoRa
          </div>
          <!-- <img class="img13" src="../img/industry/13.png" style="top:20px;left:93px;position: absolute;display: none;" alt=""> -->
        </div>

        <div class="img-box img9" name="img12" style="top:224px;left:786px">
          <img src="../img/industry/9.png" width="74" alt="" />
          <div class="img12">有线网络</div>
        </div>

        <div class="scenes-text" style="top:542px;left:81px">
          <div>
            <div>智慧电力</div>
          </div>
        </div>
        <div class="scenes-text" style="top:450px;left:281px">
          <div>
            <div>智慧机场</div>
          </div>
        </div>
        <div class="scenes-text" style="top:542px;left:481px">
          <div>
            <div>智慧物流</div>
          </div>
        </div>
        <div class="scenes-text" style="top:450px;left:680px">
          <div>
            <div>智能监控</div>
          </div>
        </div>
        <div class="scenes-text" style="top:542px;left:879px">
          <div>
            <div>智慧调度</div>
          </div>
        </div>
      </div>
      <div class="title" style="margin-bottom: 90px;">
        <b>从硬件设计到SaaS服务端到端支撑</b>
      </div>
      <div class="tower">
        <div class="top active">
          <div style="font-size:36px;padding-top:94px;">企业SaaS</div>
          <div
            style="color:#1F1F1F;font-size:18px;margin-top:10px;"
          >
            物联开放平台 行业SaaS应用
          </div>
          <img class="img17" src="../img/industry/17.png" alt="" />
          <img class="img15" src="../img/industry/15.png" alt="" />
          <img class="img19" src="../img/industry/19.png" alt="" />
        </div>
        <div class="bottom">
          <div style="font-size:36px;padding-top:94px;">行业设备</div>
          <div
            style="color:#1F1F1F;font-size:18px;margin-top:10px;"
          >
            高安全高可靠的物联设备
          </div>
          <img class="img16" src="../img/industry/16.png" alt="" />
          <img class="img18" src="../img/industry/18.png" alt="" />
          <div class="imgs20-28">
            <div>
              <img src="../img/industry/20.png" />
              <div>4G对讲设备</div>
            </div>
            <div>
              <img src="../img/industry/21.png" />
              <div>融合通信</div>
            </div>
            <div>
              <img src="../img/industry/22.png" />
              <div>ADS-B</div>
            </div>
            <div>
              <img src="../img/industry/23.png" />
              <div>LoRa网关</div>
            </div>
            <br />
            <div>
              <img src="../img/industry/24.png" />
              <div>LoRa低功耗网元</div>
            </div>
            <div>
              <img src="../img/industry/25.png" />
              <div>亚米定位</div>
            </div>
            <div>
              <img src="../img/industry/26.png" />
              <div>车载摄像头</div>
            </div>
            <div>
              <img src="../img/industry/27.png" />
              <div>AI摄像头</div>
            </div>
            <div>
              <img src="../img/industry/28.png" />
              <div>其他设备</div>
            </div>
          </div>
        </div>
      </div>
      <div class="title" style="margin-top: 200px;">
        <b>360安全产业物联网架构</b>
      </div>
      <div class="text">
        360以提升企业安全和运营效率为核心目标，打造安全物联网体系，提供端到端软硬件一体化的整体解决方案。
      </div>
      <div class="tables">
        <div class="left-table">
          <div class="header">产业物联网</div>
          <div class="tables-box">
            <div class="name">应用</div>
            <div class="app">
              <div>移动端应用</div>
              <div>web应用</div>
              <div>大屏应用</div>
              <div>集成电力应用</div>
              <div>集成航空应用</div>
              <div>集成各类<br>行业系统应用</div>
            </div>
          </div>
          <div class="tables-box">
            <div class="name">功能服务</div>
            <div class="app small">
              <div>设备管理</div>
              <div>资产管理</div>
              <div>位置服务</div>
              <div class="text-nowrap">安全运检应用</div>
              <div>综合调度<br>管理平台</div>
              <div>全景视频视觉增强监控</div>
              <div>无人值守<br>设备监测</div>
              <div>设备<br>预防性维护</div>
            </div>
          </div>
          <div class="tables-box">
            <div class="name">平台管理</div>
            <div class="app small">
              <div>微服务引擎</div>
              <div>容器引擎</div>
              <div>人工智能</div>
              <div>运营管理</div>
            </div>
          </div>
          <div class="tables-box">
            <div class="name">数据服务</div>
            <div class="app small">
              <div>数据仓库</div>
              <div>数据集市</div>
              <div>大数据分析</div>
              <div class="text-nowrap">数据开放接口</div>
            </div>
          </div>
          <div class="tables-box">
            <div class="name">采集接入</div>
            <div class="app small">
              <div>360自研<br>行业硬件</div>
              <div class="text-nowrap">数据<br>采集网关</div>
              <div class="text-nowrap" >边缘<br>智能网关</div>
              <div>第三方<br>设备接入</div>
            </div>
          </div>
        </div>
        <div class="right-table">
          <div class="header">360安全架构</div>
          <div class="tables-box">
            <div class="name">态势感知</div>
            <div class="app">
              <div>威胁分析</div>
              <div>全流量镜像分析</div>
              <div>规则引擎</div>
              <div>漏洞扫描</div>
              <div>攻击溯源</div>
              <div>智能化预警</div>
            </div>
          </div>

          <div class="tables-box">
            <div class="name">移动安全</div>
            <div class="app">
              <div>应用加固</div>
              <div>验证和授权</div>
              <div>防篡改</div>
              <div>多因素认证</div>
              <div>数据隔离</div>
              <div>数据加密</div>
              <div>加密传输</div>
              <div>消息完整性检查</div>
              <div>OTA升级</div>
              <div>环境检查</div>
              <div>沙箱</div>
              <div>远程擦除</div>
              <div>DDoS保护</div>
              <div>负载均衡</div>
              <div>冗余设计</div>
            </div>
          </div>
          <div class="tables-box">
            <div class="name">服务安全</div>
            <div class="app">
              <div>验证和授权</div>
              <div>数据备份及恢复</div>
              <div>应用防火墙</div>
              <div>熔断和降级</div>
              <div>监控及告警</div>
              <div>消息完整性检查</div>
              <div>禁止未授权访问</div>
              <div>消息完整性检查</div>
              <div>审计</div>
            </div>
          </div>
          <div class="tables-box">
            <div class="name">终端安全</div>
            <div class="app">
              <div>身份验证</div>
              <div>信息完整性检查</div>
              <div>加密传输</div>
              <div>固件/软件防篡改</div>
              <div>防服务冒充</div>
              <div>存储器锁定</div>
              <div>OTA升级</div>
              <div>禁止未授权访问</div>
              <div>电量告警</div>
            </div>
          </div>
        </div>
        <!-- <img style="margin-bottom: 120px;" src="../img/industry/29.png" alt=""> -->
      </div>
    </div>
    <div style="background: #fff;padding-top: 80px;padding-bottom: 120px;">
      <div class="content">
        <div class="title" style="margin-top: 0;">
          <b>高安全、高可靠硬件产品</b>
        </div>
        <div class="text">
          360安全产业物联网已经研发生产数款高安全、高可靠硬件产品，为安全产业物联网的应用提供强有力的产品支撑。
        </div>
        <div class="products">
          <el-row :gutter="20">
            <el-col :span="6">
              <div class="img-box">
                <img src="../img/product/1.png" />
                <div class="img31">
                  <div style="padding: 0 18px;">
                    基于公共差分的高精定位视频一体设备
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="img-box">
                <img src="../img/product/2.png" />
                <div class="img31">
                  <div style="padding: 0 18px;">基于本地基站的高精定位模块</div>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="img-box">
                <img src="../img/product/3.png" />
                <div class="img31">
                  <div style="padding: 0 18px;">无动力设备低功耗追踪器节点</div>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="img-box">
                <img src="../img/product/4.png" />
                <div class="img31">
                  <div style="padding: 0 18px;">综合追踪器HUB</div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <div class="img-box">
                <img src="../img/product/5.png" />
                <div class="img31">
                  <div style="padding: 0 18px;">AI摄像头</div>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="img-box">
                <img src="../img/product/6.png" />
                <div class="img31">
                  <div style="padding: 0 18px;">
                    集群对讲通用融合调度中心网关
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="img-box">
                <img src="../img/product/7.png" />
                <div class="img31">
                  <div style="padding: 0 18px;">防水WiFi摄像头</div>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="img-box">
                <img src="../img/product/8.png" />
                <div class="img31">
                  <div style="padding: 0 18px;">智能后视镜</div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <div class="img-box" style="margin:0">
                <img src="../img/product/9.png" />
                <div class="img31">
                  <div style="padding: 0 18px;">工业摄像头</div>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="img-box" style="margin:0">
                <img src="../img/product/10.png" />
                <div class="img31">
                  <div style="padding: 0 18px;">麦克风阵列监测设备</div>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="img-box" style="margin:0">
                <img src="../img/product/11.png" />
                <div class="img31">
                  <div style="padding: 0 18px;">集装箱追踪器</div>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="img-box" style="margin:0">
                <img src="../img/product/12.png" />
                <div class="img31">
                  <div style="padding: 0 18px;">高压线自取电测高雷达</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <Footer />
    <Video :url="require('../img/home/os.mp4')" :dialogVisible="videostart" />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import Video from "@/components/video";
export default {
  components: {
    Header,
    Footer,
    Video,
  },
  data() {
    return {
      videostart: false,
    };
  },
  mounted() {
    let html = document.querySelector("html");
    html.scrollTop = 0;

    let dom = $(".scenes-text").hover(
      function() {
        $(this).addClass("active");
      },
      function() {
        $(this).removeClass("active");
      }
    );

    $(".tower .top,.tower .bottom").hover(function() {
      $(this)
        .addClass("active")
        .siblings()
        .removeClass("active");
    });

    $(".scenes .img-box").hover(function() {
      let name = $(this).attr("name");
      $("." + name)
        .stop()
        .fadeToggle();
    });

    $(".img31").hide();
    $(".products .img-box").hover(
      function() {
        $(this)
          .find(".img31")
          .show();
      },
      function() {
        $(this)
          .find(".img31")
          .hide();
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.text-nowrap {
  white-space: nowrap;
}
.img10 {
  font-size: 18px;
  box-sizing: border-box;
  width: 320px;
  line-height: 66px;
  text-align: center;
  top: -60px;
  left: 15px;
  position: absolute;
  display: none;
  background: url("../img/industry/10.png");
  color: #007aff;
}
.img11 {
  font-size: 18px;
  box-sizing: border-box;
  width: 140px;
  line-height: 63px;
  top: -80px;
  left: -100px;
  text-align: center;
  position: absolute;
  display: none;
  color: #007aff;
  background: url("../img/industry/11.png");
}
.img12 {
  font-size: 18px;
  box-sizing: border-box;
  width: 140px;
  line-height: 63px;
  top: -80px;
  left: 30px;
  text-align: center;
  position: absolute;
  display: none;
  color: #007aff;
  background: url("../img/industry/12.png");
}
.img13 {
  padding-left: 30px;
  font-size: 18px;
  box-sizing: border-box;
  width: 185px;
  top: 20px;
  left: 93px;
  position: absolute;
  display: none;
  color: #007aff;
  background: url("../img/industry/13.png");
}
.tables {
  margin-bottom: 100px;
  overflow: hidden;
  > div {
    float: left;
    width: 560px;
  }
  .header {
    line-height: 60px;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
  }
  .tables-box {
    margin-bottom: 10px;
    display: flex;
    .name {
      width: 120px;
      font-size: 20px;
      align-items: center;
      display: flex;
    }
    .app {
      width: 413px;
      > div {
        float: left;
        width: 127px;
        font-size: 14px;
        color: #fff;
        text-align: center;
        margin: 5px 0 5px 5px;
        border-radius: 4px;
      }
    }
  }
  .left-table {
    .header {
      background: #007aff;
    }
    .tables-box {
      padding: 15px 15px 15px 20px;
      background: #e2e8ef;
      border: 1px solid #c2d3e6;
      .name {
        color: #6095ce;
      }
      .app {
        > div {
          background: #50a3fe;
          height: 56px;
          padding: 6px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .small {
        > div {
          width: 95px;
          height: 70px;
        }
      }
    }
  }
  .right-table {
    margin-left: 40px;
    .header {
      background: #00c67b;
    }
    .tables-box {
      padding: 6px 10px 5px 20px;
      background: #e6f2ec;
      border: 1px solid #c2e6c9;
      .name {
        color: #3abe5e;
      }
      .app {
        > div {
          background: #52ca7b;
          line-height: 40px;
        }
      }
    }
  }
}
.img31 {
  position: absolute;
  width: 260px;
  height: 260px;
  top: 0;
  left: 0;
  font-size: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  background-image: url("../img/industry/31.png");
  > div {
    width: 100%;
  }
}
.img6,
.img7,
.img8,
.img9 {
  animation-timing-function: linear; // 匀速
  animation-iteration-count: infinite; // 循环播放
  animation-duration: 3s; // 动画执行时间
  // animation-play-state:paused;
}
.img6 {
  animation-name: img6animation;
}
.img7 {
  animation-delay: 700ms;
  animation-name: img7animation;
}
.img8 {
  animation-delay: 1000ms;
  animation-name: img8animation;
}
.img9 {
  animation-delay: 500ms;
  animation-name: img9animation;
}
@keyframes img6animation {
  0% {
    top: 73px;
  }
  50% {
    top: 83px;
  }
  100% {
    top: 73px;
  }
}

@keyframes img7animation {
  0% {
    top: 230px;
  }
  50% {
    top: 240px;
  }
  100% {
    top: 230px;
  }
}

@keyframes img8animation {
  0% {
    top: 335px;
  }
  50% {
    top: 345px;
  }
  100% {
    top: 335px;
  }
}

@keyframes img9animation {
  0% {
    top: 230px;
  }
  50% {
    top: 240px;
  }
  100% {
    top: 230px;
  }
}

.tower {
  color: #000;
  .active {
    border-color: #007aff;
    color: #007aff;
    background: #fff !important;
    .img17 {
      top: -30px;
      right: 142px;
      z-index: 100;
      display: block !important;
    }
    .img18 {
      z-index: 100;
      top: 0;
      right: -64px;
      display: block !important;
    }
  }
  > div {
    height: 280px;
    padding-left: 40px;
    position: relative;
    background: #f1f1f1;
    border: 1px solid #f1f1f1;
  }
  .top {
    img {
      position: absolute;
    }
    .img15 {
      z-index: 99;
      top: -30px;
      right: 142px;
    }
    .img17 {
      display: none;
    }
    .img19 {
      z-index: 100;
      top: 92px;
      right: 246px;
    }
  }
  .bottom {
    margin-top: 20px;
    font-size: 14px;
    .img16,
    .img18 {
      position: absolute;
    }
    .img16 {
      z-index: 99;
      top: 0;
      right: -64px;
    }
    .img18 {
      display: none;
    }
    .imgs20-28 {
      z-index: 100;
      top: 30px;
      right: 64px;
      color: #fff;
      line-height: 32px;
      text-align: center;
      position: absolute;
      > div {
        text-align: center;
        display: inline-block;
        margin-right: 40px;
        > div {
          margin-bottom: 24px;
        }
      }
      > div:last-child,
      > div:nth-child(4) {
        margin: 0;
      }
    }
  }
}
.scenes {
  width: 1160px;
  height: 908px;
  position: relative;
  background-image: url("../img/industry/5.png");
  > img {
    position: absolute;
  }
  > .img-box {
    position: absolute;
  }
  .scenes-text {
    color: #fff;
    text-align: center;
    font-size: 18px;
    width: 198px;
    height: 262px;
    position: absolute;
    display: flex;
    align-items: center;
    // animation: scenes-text-animation 4s infinite;
    background-image: url("../img/industry/30.png");
    &.active {
      background-image: url("../img/industry/14.png");
      opacity: 1 !important;
      > div {
        display: block;
      }
    }
    > div {
      padding: 0 15px;
      width: 100%;
      display: none;
      > div {
        padding-top: 20px;
        line-height: 25px;
      }
      > div:first-child {
        padding-top: 0;
      }
    }
    // @keyframes scenes-text-animation {
    //   0%    {opacity:0;}
    //   50%   {opacity:1;}
    //   100%  {opacity:0;}
    // }
  }
}

.top-bg {
  position: relative;
  background-image: url("../img/industry/1.jpg");
  background-position: center;
  .header {
    position: absolute;
    top: 0;
    z-index: 100;
    height: 64px;
    width: 100%;
  }
  .top-bg-text {
    font-size: 64px;
    color: #ffffff;
    padding-top: 180px;
    padding-left: 60px;
    box-sizing: border-box;
  }
}
.title {
  padding-left: 20px;
  margin-top: 120px;
  margin-bottom: 40px;
  font-size: 48px;
  color: #000000;
}
.text {
  padding-left: 20px;
  margin-bottom: 60px;
  font-size: 16px;
  color: #1f1f1f;
  letter-spacing: 0;
  line-height: 24px;
}
.application {
  text-align: center;
  > div {
    float: left;
    width: 360px;
    height: 420px;
    background: #fff;
    margin-right: 40px;
  }
  > div:last-child {
    margin-right: 0;
  }
  img {
    margin-top: 60px;
    width: 120px;
    height: 122px;
  }
  .title {
    padding: 0;
    margin-top: 41px;
    margin-bottom: 22px;
    font-size: 24px;
    color: #1f1f1f;
    font-weight: bold;
  }
  .text {
    font-size: 14px;
    color: #1f1f1f;
    padding: 0 70px;
    margin: 0;
    text-align: justify;
    text-align-last: center;
  }
}
.products {
  .img-box {
    position: relative;
    margin-bottom: 60px;
  }
  img {
    width: 260px;
    height: 260px;
  }
}
</style>
