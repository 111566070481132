<template>
  <div>
    <div class="top-bg">
      <Header class="header" />
      <div class="content">
        <div class="top-text" style="padding-top: 177px;">奇络物联</div>
        <div class="top-text">为行业提供一站式物联网解决方案</div>
        <div class="top-sub-text">
          360OS以安全为基座，立足应用型AI，推出奇络物联人工智能物联网平台。<br />
          奇络物联以“AI in Industry“为战略，为垂直行业智能转型与升级提供一站式解决方案，<br />
          打造智能、高效、灵活的未来架构解决方案，赋能百行百业智能化，引领产业走向智慧物联新时代。
        </div>
      </div>
    </div>

    <div class="content">
      <div class="part-title">奇络物联的业务矩阵</div>
    </div>
    <div class="content matrix">
      <div class="matrix-item" @click="routerClick('industry')">
         <img class="img" src="../img/internet/matrix_1.png" alt="">
         <div class="text">
           <div class="matrix-title">奇络安全产业物联网</div>
           <div class="matrix-text">奇络安全产业物联聚焦机场和电力两大千亿级市场智能化改造需求，为用户提供安全产业物联网解决方案，帮助用户构建高安全、强性能、定制化的智慧物联网络。 </div>
         </div>
         <div class="matrix-btn">
           <i style="vertical-align: middle;" class="el-icon-arrow-right"></i>
         </div>
      </div>
      <div class="matrix-item" style="margin-left:20px;" @click="routerClick('aiMonitor')">
         <img class="img" src="../img/internet/matrix_2.png" alt="">
         <div class="text">
           <div class="matrix-title">奇络AI监测</div>
           <div class="matrix-text">奇络AI监测聚焦大安防、工业垂直智能化场景改造需求，面向细分场景落地超过30种算法，帮助用户实现现有监测设备智能化。 </div>
         </div>
         <div class="matrix-btn">
           <i style="vertical-align: middle;" class="el-icon-arrow-right"></i>
         </div>
      </div>
      <div class="matrix-item" style="margin-top:20px;" @click="routerClick('aiZhilian')">
         <img class="img" src="../img/internet/matrix_3.png" alt="">
         <div class="text">
           <div class="matrix-title">奇络AI智联</div>
           <div class="matrix-text">奇络AI智联瞄准非生产性通用工业设备智能化转型需求，帮助用户实现生产设备的极简部署与智慧运维，搭建面向未来的智能化生产模式。 </div>
         </div>
         <div class="matrix-btn">
           <i style="vertical-align: middle;" class="el-icon-arrow-right"></i>
         </div>
      </div>
      <div class="matrix-item" style="margin:20px 0 0 20px;" @click="routerClick('aiImage')">
         <img class="img" src="../img/internet/matrix_4.png" alt="">
         <div class="text">
           <div class="matrix-title">奇络AI影像</div>
           <div class="matrix-text">奇络AI影像专注智能手机、IOT、泛文娱领域，为用户提供包括APP、算法、tuning等全栈式、一体化解决方案，具备极高性价比和极短开发周期优势。 </div>
         </div>
         <div class="matrix-btn">
           <i style="vertical-align: middle;" class="el-icon-arrow-right"></i>
         </div>
      </div>
    </div>

    <div class="content item-header">
      <div class="reason-title">为什么选择奇络物联</div>
      <div class="reason-sub-title">奇络物联在人工智能物联网市场领域的技术具有行业壁垒性</div>
    </div>
    <div class="content clear" style="margin-top: 60px;display: flex;">
      <div class="advantage">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../img/internet/advantage_1.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">IOT安全监测及防护体系</div>
            <div class="advantage-text-item">
              <div class="item">
                <span class="text">· 全域防护设备、通信与云端安全</span>
              </div>
              <div class="item">
                <span class="text">· 雄厚的工业物联网安全技术</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="advantage" style="margin-left:37px;">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../img/internet/advantage_2.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">丰富应用型算法库</div>
            <div class="advantage-text-item">
              <div class="item">
                <span class="text">· 自研场景化算法</span>
              </div>
              <div class="item">
                <span class="text">· 一站式AI平台</span>
              </div>
              <div class="item">
                <span class="text">· 360集团行业领先AI技术</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="advantage" style="margin-left:37px;">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../img/internet/advantage_3.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">丰富的场景化产品及解决方案</div>
            <div class="advantage-text-item">
              <div class="item">
                <span class="text">· 自研智能化感知终端设备</span>
              </div>
              <div class="item">
                <span class="text">· 成熟组件化的人工智能物联网平台</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="solution">
      <div class="content" >
        <div class="part-title" style="margin: 93px 0 0 0;">成功案例</div>
        <div class="reason-sub-title" style="">奇络物联人工智能物联网解决方案已经在机场交通、能源电力、生产研发制造、泛安防等领域实现了规模部署与应用。</div>
        <div style="position: relative;">
          <div class="slide-wrap">
            <div class="slide-item item1" :class="[computeSlide('item1', slideIndex)]" @click="clickSlide(1)">
              <div>
                <div class="slide-content" v-if="slideIndex === 1">
                  <div class="slide-title">国内某机场打造智慧机场</div>
                  <div class="slide-text">围绕民航“四型”机场建设需求，从应用场景切入，通过系列化智能终端和边缘AI设备实现多种感知数据全面采集，结合AI算法深入分析，打造机场智能车辆人员调度平台、机场机坪定位管理平台、智慧卫生间、智慧能源管理等。</div>
                </div>
              </div>
            </div>
            <div class="slide-item item2" :class="[computeSlide('item2', slideIndex)]" @click="clickSlide(2)">
              <div>
                <div class="slide-content" v-if="slideIndex === 2">
                  <div class="slide-title">国内变电站智能化改造</div>
                  <div class="slide-text">围绕变电站智能化需求，部署360度高清全景视频监控，并结合智能工业级摄像头、声纹监测、低成本红外测温等智能终端，采集变压器、电容器和电抗器及各类仪表的运行状态，打造在线智能运检系统，保障电网安全运行。</div>
                </div>
              </div>
            </div>
            <div class="slide-item item3" :class="[computeSlide('item3', slideIndex)]" @click="clickSlide(3)">
              <div>
                <div class="slide-content" v-if="slideIndex === 3">
                  <div class="slide-title">国内某大型车企实现智能化生产监测</div>
                  <div class="slide-text">奇络物联端到端、一体化AI监测解决方案对员工玩手机、吸烟、未戴安全帽、未穿工衣、未走人行道等违规行为进行监测，有效规范员工行为，防范安全隐患，助力企业实现安全生产。</div>
                </div>
              </div>
            </div>
            <div class="slide-item item4" :class="[computeSlide('item4', slideIndex)]" @click="clickSlide(4)">
              <div>
                <div class="slide-content" v-if="slideIndex === 4">
                  <div class="slide-title">国内某大型会展中心设备智能化部署</div>
                  <div class="slide-text">奇络物联通用工业设备物联网解决方案，将设备维保、资产管理、能耗管理以及人员管理等复杂业务迁移至线上系统并整合，利用线上管理模块实现项目管理的电子化、流程化与标准化，节省运营成本，提高业务效率。</div>
                </div>
              </div>
            </div>
          </div>
          <div class="slide-btn">
            <div class="pre-btn" @click="slidePre"></div>
            <div class="next-btn" @click="slideNext"></div>
          </div>
        </div>
      </div>
    </div>

    <dialog-consult ref="consult"></dialog-consult>
    <dialog-apply ref="apply"></dialog-apply>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import contact from "./aiImageDetail/components/contact"
import DialogConsult from './aiImageDetail/components/dialogConsult.vue';
import DialogApply from './aiImageDetail/components/dialogApply'
export default {
  components: {
    Header,
    Footer,
    contact,
    DialogConsult,
    DialogApply
  },

  data() {
    return {
      slideIndex: 1
    }
  },

  methods: {
    routerClick (val) {
      this.$router.push('/' + val)
    },
    consultClick () {
      this.$refs.consult.show()
    },
    applyClick () {
      this.$refs.apply.show()
    },
    bindEvent () {
      $('.matrix-item').hover(function() {
        $(this).find('.text').stop().animate({top: 48})
        $(this).find('.matrix-btn').show(500)
        $(this).find('.img').removeClass('normal').addClass('big')
        // $(this).stop().animate({marginTop: -20}, 300)
      }, function() {
        $(this).find('.text').stop().animate({top: 88})
        $(this).find('.matrix-btn').hide(500)
        $(this).find('.img').removeClass('big').addClass('normal')
        // $(this).stop().animate({marginTop: 0}, 300)
      })
    },
    computeSlide (item, index) {
      if (index == 1) {
        if (item === 'item1') {
          return 'slide1'
        } else if (item === 'item2') {
          return 'slide2'
        } else if (item === 'item3') {
          return 'slide3'
        } else {
          return 'slide-other'
        }
      } else if (index === 2) {
        if (item === 'item2') {
          return 'slide1'
        } else if (item === 'item3') {
          return 'slide2'
        } else if (item === 'item4') {
          return 'slide3'
        } else {
          return 'slide-other'
        }
      } else if (index === 3) {
        if (item === 'item3') {
          return 'slide1'
        } else if (item === 'item4') {
          return 'slide2'
        } else if (item === 'item1') {
          return 'slide3'
        } else {
          return 'slide-other'
        }
      } else if (index === 4) {
        if (item === 'item4') {
          return 'slide1'
        } else if (item === 'item1') {
          return 'slide2'
        } else if (item === 'item2') {
          return 'slide3'
        } else {
          return 'slide-other'
        }
      }
    },
    clickSlide (index) {
      this.slideIndex = index
    },
    slidePre () {
      if (this.slideIndex === 1) {
        this.slideIndex = 4
      } else if (this.slideIndex === 2) {
        this.slideIndex = 1
      } else if (this.slideIndex === 3) {
        this.slideIndex = 2
      } else if (this.slideIndex === 4) {
        this.slideIndex = 3
      }
    },
    slideNext () {
      if (this.slideIndex === 1) {
        this.slideIndex = 2
      } else if (this.slideIndex === 2) {
        this.slideIndex = 3
      } else if (this.slideIndex === 3) {
        this.slideIndex = 4
      } else if (this.slideIndex === 4) {
        this.slideIndex = 1
      }
    }
  },

  mounted() {
    window.scrollTo(0, 0)
    this.bindEvent()
  }
}
</script>

<style lang="scss" scoped>
.top-bg {
  position: relative;
  background-image: url("../img/internet/banner.jpg");
  background-position: center;
  .header {
    position: absolute;
    top: 0;
    z-index: 100;
    height: 64px;
    width: 100%;
  }
  .top-text {
    font-size: 64px;
    color: #ffffff;
    padding-left: 60px;
    box-sizing: border-box;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-sub-text {
    height: 108px;
    line-height: 36px;
    margin-top: 120px;
    font-size:18px;
    color: #ffffff;
    padding-left: 60px;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
}

.part-title {
  font-family: "Microsoft YaHei","PingFangSC-Regular";
  font-size: 36px;
  color: #000000;
  font-weight: 700;
  margin: 133px 0 79px 0;
}

.advantage {
  height: 420px;
  width: 360px;
  background-color: #fff;
  .advantage-wrap {
    align-items: center;
  }
  .advantage-img {
    margin-top: 60px;
    text-align: center;
  }
  .advantage-text {
    .advantage-text-title {
      text-align: center;
      margin-top: 40px;
      margin-bottom: 23px;
      font-size: 24px;
      color: #1F1F1F;
      letter-spacing: 0;
      font-weight: bold;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
    .advantage-text-item {
      width: 288px;
      margin-left: 36px;
    }
    .item {
      line-height: 24px;
      vertical-align: middle;
      text-align: center;
      .text {
        font-size: 14px;
        color: #1F1F1F;
        letter-spacing: 0;
        line-height: 24px;
        vertical-align: middle;
        font-family: "Microsoft YaHei","PingFangSC-Regular";
      }
    }
  }
}

.reason-title {
  font-family: "Microsoft YaHei","PingFangSC-Regular";
  font-size: 36px;
  color: #000000;
  font-weight: 700;
  text-align: left;
}
.reason-sub-title {
  font-size: 16px;
  color: #1F1F1F;
  letter-spacing: 0;
  line-height: 28px;
  font-weight: 400;
  font-family: "Microsoft YaHei","PingFangSC-Regular";
  text-align: left;
  margin-top: 48px;
}

.matrix {
  display: flex;
  flex-wrap: wrap;
  .matrix-item {
    width: 570px;
    height: 320px;
    position: relative;
    cursor: pointer;
    .big {
      transform: scale(1.03);
      transition-duration: 1s;
      box-shadow: 0 2px 12px 0 rgb(0 0 0 / 40%);
    }
    .normal {
      transition-duration: 1s;
      transform: scale(1);
    }
    .matrix-btn {
      position: absolute;
      bottom: 36px;
      left: 60px;
      font-size: 24px;
      font-weight: bold;
      color: #fff;
      width: 62px;
      height: 36px;
      vertical-align: middle;
      text-align: center;
      border: 1px solid #fff;
      border-radius: 20px;
      cursor: pointer;
      display: none;
    }
    .text {
      width: 320px;
      position: absolute;
      top: 88px;
      left: 60px;
      .matrix-title {
        font-size: 24px;
        color: #FFFFFF;
        font-weight: bold;
        font-family: "Microsoft YaHei","PingFangSC-Regular";
      }
      .matrix-text {
        font-size: 14px;
        color: rgba(255,255,255,0.90);
        line-height: 24px;
        margin-top: 29px;
      }
    }
  }
}

.solution {
  margin-top:120px;
  background-color:#fff;
  overflow: hidden;
  padding-bottom: 120px;
}

.slide-wrap {
  width: 908px;
  height: 512px;
  position: relative;
  margin-top: 60px;
  .slide-item {
    position: absolute;
    top:0;
    left: 0;
    width: 760px;
    height: 512px;
    &>div {
      height: 100%;
      width: 100%;
      background-position:center;
      background-repeat:no-repeat;
      background-size: 100% 100%;
      position: relative;
      .slide-content {
        position: absolute;
        bottom: 0;
        height: 206px;
        width:100%;
        .slide-title {
          margin-top: 37px;
          margin-left: 36px;
          font-size: 24px;
          font-weight: bold;
          color: #FFFFFF;
        }
        .slide-text {
          margin-top: 17px;
          margin-left: 36px;
          width: 688px;
          font-size: 14px;
          color: #FFFFFF;
          letter-spacing: 0;
          line-height: 28px;
        }
      }
    }
  }
  .item1 {
    background-image: url('../img/internet/solution_1.png');
  }
  .item2 {
    background-image: url('../img/internet/solution_2.png');
  }
  .item3 {
    background-image: url('../img/internet/solution_3.png');
  }
  .item4 {
    background-image: url('../img/internet/solution_4.png');
  }
  .slide1 {
    z-index: 100;
    transition: 330ms;
  }
  .slide2 {
    z-index: 99;
    transform: translateX(143px) scale(0.84) !important;
    transform-origin: center bottom !important;
    transition: 330ms;
    &>div {
      background-image: url('../img/internet/solution_over.png');
    }
  }
  .slide3 {
    z-index: 98;
    transform: translateX(258px) scale(0.71) !important;
    transform-origin: center bottom !important;
    transition: 330ms;
    &>div {
      background-image: url('../img/internet/solution_over.png');
    }
  }
  .slide-other {
    z-index: 97;
    transform: translateX(200px) scale(0.6) !important;
    transform-origin: center bottom !important;
    transition: 330ms;
  }
}

.slide-btn {
  position: absolute;
  top: 0;
  right: 0;
  .pre-btn {
    display: inline-block;
    height: 60px;
    width: 60px;
    background-image: url('../img/internet/pre_normal.png');
    cursor: pointer;
    &:hover {
      background-image: url('../img/internet/pre_active.png');
    }
  }
  .next-btn {
    display: inline-block;
    height: 60px;
    width: 60px;
    background-image: url('../img/internet/next_normal.png');
    cursor: pointer;
    margin-left: 20px;
    &:hover {
      background-image: url('../img/internet/next_active.png');
    }
  }
}
</style>
