import Vue from 'vue'
import Apps from './zh.vue'
import router from '@/router'
import 'element-ui/lib/theme-chalk/index.css'
import '../main.scss'
import './zh.scss'
import Element from 'element-ui'
import {zh} from '../language.js'
import jq from 'jquery'
import ajax from 'axios'

Vue.config.productionTip = false

Vue.prototype.$lang = zh
Vue.prototype.$ajax = ajax
window.$ = jq
Vue.prototype.$root = './'

Vue.use(Element)

// let store = {
//   user: sessionStorage.getItem('user')
// }

new Vue({
  router,
  el:'#index_zh',
  // data: {},
  render: h => h(Apps)
})
