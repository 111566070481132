<template>
  <div>
    <div class="top-bg">
      <Header class="header" />
      <div class="content">
        <div class="top-bg-text">保持激情 以创新不断前行<br>敬畏之心 用发展驱动未来</div>
      </div>
    </div>
    <div class="content">
      <div class="title" style="margin-top: 93px;">公司简介</div>
      <div class="introduction">
        360OS以安全为基座，立足应用型AI，推出奇络物联人工智能物联网平台。奇络物联依靠在智能设备产品领域技术积累以及应用快速落地的优势，聚焦产业物联网市场生产运营智能升级与转型需求，为垂直行业用户提供一站式物联网解决方案，打造智能、高效、灵活的未来架构解决方案，帮助用户实现智能化价值。
        <div style="margin-top: 20px;">目前，360OS拥有数百人的技术研发团队，汇聚来自AI、产业物联、大安全等领域的人才，其产品及解决方案已经在交通、能源、电力、汽车制造、泛安防等领域实现了规模部署与应用。</div>
      </div>
    </div>
    <div class="img2">
      <div class="content">
        <div style="font-size:36px;padding-top:93px;padding-bottom:65px;font-weight: bold;">企业文化</div>
        <div class="culture">
          <div class="tit">我们的愿景</div>
          <div class="msg">打造具有国际竞争力的物联网平台。</div>
          <div  class="tit">我们的使命</div>
          <div class="msg">用技术力量加速数字化转型进程，帮助合作伙伴挖掘全新商业价值。</div>
          <div  class="tit">我们的战略</div>
          <div class="msg">以「 AI in Industry 」为战略，赋能产业智能化转型升级，引领各产业走向智慧物联新时代。</div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title" style="margin: 93px 0 72px 0;">发展历程</div>
        <div class="date-line clear">
        <div class="date some item-box">
        </div>
        <div class="some item-box">
          <img src="../img/about/202108.png" />
          <div class="box-block">
            <div class="some-title box-block_title">面向青少年群体推出“奇少年”品牌，首创学生手机</div>
            <div class="text">为青少年而生</div>
            <img src="../img/about/child.png" />
          </div>
        </div>
      </div>

      <div class="date-line clear">
        <div class="date some">
          <div>
            <div class="some-title">推出奇络物联人工智能物联网平台</div>
            <div class="text">未来架构解决方案</div>
            <img style="float: right;" src="../img/about/202011.png" />
          </div>
          <img src="../img/about/202011_time.svg" />
        </div>
        <div class="some">
        </div>
      </div>

      <div class="date-line clear">
        <div class="date some">
        </div>
        <div class="some">
          <img src="../img/about/3.svg" />
          <div>
            <div class="some-title">安全产业物联网上线应用</div>
            <div class="text">垂直行业首发试用</div>
            <img src="../img/about/8_new.png" />
          </div>
        </div>
      </div>

      <div class="date-line clear">
        <div class="date some">
          <div>
            <div class="some-title">布局安全产业物联网</div>
            <div class="text">推出端到端平台化解决方案</div>
            <img style="float: right;" src="../img/about/9.png" />
          </div>
          <img src="../img/about/4.svg" />
        </div>
        <div class="some">
        </div>
      </div>

      <div class="date-line clear">
        <div class="date some">
        </div>
        <div class="some">
          <img src="../img/about/5.svg" />
          <div>
            <div class="some-title">OS出货量过千万</div>
            <div class="text">用户数破亿</div>
            <img src="../img/about/10.png" />
          </div>
        </div>
      </div>

      <div class="date-line clear">
        <div class="date some">
          <div>
            <div class="some-title">搭建全球分发网络</div>
            <div class="text">服务全球智能硬件厂商</div>
            <img style="float: right;" src="../img/about/11.png" />
          </div>
          <img src="../img/about/6.svg" />
        </div>
        <div class="some">
        </div>
      </div>

      <div class="date-line clear">
        <div class="date some">
        </div>
        <div class="some">
          <img src="../img/about/7.svg" />
          <div>
            <div class="some-title">推出首款应用系统</div>
            <div class="text">OS 1.0上线发布</div>
            <img src="../img/about/12.png" />
          </div>
        </div>
      </div>

      <div class="date-line clear" style="height: 80px;margin-bottom: 130px;">
        <div class="date" style="border:none;min-height: auto;">
          <div style="color:#007AFF;font-size: 24px;letter-spacing: -0.5px;margin-right: -60px;margin-top: 15px;">2014.12</div>
          <div style="font-size: 28px;color: #1F1F1F;margin-right: -87px;">360OS成立</div>  
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
export default {
  components: {
    Header,
    Footer
  },
  mounted() {
    let html = document.querySelector('html')
    html.scrollTop = 0
  }
}
</script>

<style lang="scss" scoped>
.introduction {
  width: 880px;
  line-height: 28px;
  text-align: justify;
  margin-top: 27px;
  font-size: 16px;
}
.img2 {
  margin-top: 104px;
  height: 706px;
  background-image: url('../img/about/2.jpg');
  background-position: center top;
}
.top-bg {
  position: relative;
  // height: 680px;
  background-image: url('../img/about/1.png');
  background-position: center;
  .header {
    position: absolute;
    top: 0;
    z-index: 100;
    height: 64px;
    width: 100%;
  }
  .top-bg-text {
    font-size: 64px;
    color: #fff;
    line-height: 110px;
    padding-top: 252px;
    padding-left: 40px;
  }
}
.content {
  .title {
    font-size: 36px;
    line-height: 67px;
    color: #000000;
    font-weight: bold;
  }
  .text {
    font-size: 16px;
  }
}
.culture {
  .msg {
    background: url('../img/about/13.png') no-repeat;
    padding-left: 40px;
    font-size: 18px;
    color: #1F1F1F;
    letter-spacing: 0;
    line-height: 48px;
  }
  .tit {
    font-size: 20px;
    color: #007AFF;
    letter-spacing: 0;
    line-height: 48px;
    margin-top:20px;
  }
}
.date-line {
  >div {
    min-width: 540px;
    min-height: 280px;
  }
  img {
    display: block;
  }
  .date {
    text-align: right;
    position: relative;
    margin-right: 20px;
    float: left;
    font-size: 36px;
    padding-right: 20px;
    // height: 250px;
    border-right: 1px dashed #336fb5;
    &:after {
      content: ' ';
      padding: 4px;
      border-radius: 4px;
      background: #0e73ff;
      position: absolute;
      top: 0;
      right: -5px;
    }
    >img {
      margin-left: 30px;
      margin-right: 0 !important;
    }
  }
  .some {
    height: 282px;
    float: left;
    >* {
      display: inline-block;
      vertical-align: top;
    }
    >img {
      margin-right: 30px;
    }
    .some-title {
      font-size: 24px;
      margin-bottom: 10px;
      line-height: 40px;
    }
    .text {
      font-size: 18px;
      margin-bottom: 20px;
      line-height: 26px;
    }
    &.item-box{
      height: 315px;
      .box-block{
        width: 400px;
        .box-block_title{
          width: 100%;
        }
      }
    }
  }
}
</style>