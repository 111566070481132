<template>
  <div class="box">
    <div class="edit-header">
      <el-divider content-position="left"
        ><h3 class="header-title">{{ title }}</h3></el-divider
      >
      <el-form
        class="flex-box"
        ref="form"
        :rules="rules"
        :model="form"
        size="small"
        label-position="right"
        label-width="80px"
      >
        <el-row :gutter="20" class="form-box">
          <el-col :span="12">
            <el-form-item label="标题" prop="title">
              <el-input v-model="form.title"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期" prop="date">
              <el-date-picker
                v-model="form.date"
                type="date"
                value-format="yyyy.MM.dd"
                placeholder="选择日期"
                class="width-style"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="来源" prop="source">
              <el-input v-model="form.source"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="首页展示">
              <el-select
                v-model="form.type"
                class="width-style"
                @change="formTypeChange"
              >
                <el-option :value="1" label="展示"></el-option>
                <el-option :value="0" label="不展示"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="摘要">
              <el-input type="textarea" :rows="4" v-model="form.abstr">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.type == 1 ? true : false">
            <el-form-item label="首页位置">
              <el-select v-model="form.display" class="width-style">
                <el-option :value="1" label="图片位"></el-option>
                <el-option :value="0" label="非图片位"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            :span="12"
            v-if="form.display == 1 && form.type == 1 ? true : false"
          >
            <el-form-item label="首页图片">
              <el-upload
                class="upload-demo"
                action="/news/upload"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :limit="1"
                :on-exceed="handleExceed"
                :file-list="fileList"
                list-type="picture"
                with-credentials
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-row class="edit-box flex-box">
      <el-col :span="24">
        <div class="news-title">新闻内容</div>
        <Edit @change="onEditorChange" :htmlData="content" />
      </el-col>
    </el-row>
    <el-dialog title="预览" :visible.sync="dialogVisible" width="80%">
      <div
        class="content-box ql-editor ql-line-height"
        v-html="form.htmlData"
      ></div>
    </el-dialog>
    <el-row>
      <el-col class="flex-box btn-group" :span="24">
        <el-button type="success" @click="() => (dialogVisible = true)"
          >预览</el-button
        >
        <el-button :loading="loading" type="primary" @click="submit"
          >提交</el-button
        >
        <el-button @click="$router.push('/news')">返回新闻</el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Edit from "./edit";
import api from "@/server/api";

export default {
  components: {
    Edit
  },
  data() {
    return {
      title: "新增新闻",
      content: "",
      newsID: '',
      form: {
        htmlData: "",
        type: 0,
        display: 0,
        cover: "",
        title: "",
        source: "",
        abstr: "",
        date: ""
      },
      rules: {
        title: [{ required: true, message: "必填", trigger: "blur" }],
        date: [{ required: true, message: "必填", trigger: "blur" }],
        source: [{ required: true, message: "必填", trigger: "blur" }],
        abstr: [{ required: true, message: "必填", trigger: "blur" }]
      },
      loading: false,
      dialogVisible: false,
      fileList: [
        {
          name: "food.jpeg",
          url:
            "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100"
        }
      ]
    };
  },
  watch: {
    "$route.query": {
      handler(val) {
        const { id } = val;
        if (id) {
          this.title = "编辑新闻";
          this.newsID = id;
          this.getNewsInfo(id);
        } else {
          this.title = "新增新闻";
          this.content = "";
          this.form = {
            htmlData: "",
            type: 0,
            display: 0,
            cover: "",
            title: "",
            source: "",
            abstr: "",
            date: ""
          };
        }
      },
      immediate: true
    }
  },
  methods: {
    /* 初始化状态 */
    formTypeChange(value) {
      if (!value) {
        this.form.display = 0;
        this.form.cover = "";
        this.fileList = [];
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true;
          if (!this.form.htmlData) {
            this.$message({
              message: "必须填写文章内容",
              type: "error"
            });
            this.loading = false;
            return;
          }
          let url = this.title == "新增新闻" ? api.newsAdd : api.newsUpdate
          if (this.title != "新增新闻") {
            this.form.id = this.newsID
          }
          this.$ajax.post(url, this.form).then(res => {
            if (res.data.code == 0) {
              this.$message({
                message: "成功",
                type: "success"
              });
            } else if (res.data.code == 2) {
              this.$message({
                message: "请登录",
                type: "error"
              });
              this.$router.push("/login");
            } else {
              this.$message({
                message: "失败" + res.data.message,
                type: "error"
              });
            }
          });
          this.loading = false;
        }
      });
    },
    onEditorChange({ quill, html, text }) {
      this.form.htmlData = html;
    },
    getNewsInfo(id) {
      this.$ajax.post(api.newsInfo, { id }).then(res => {
        if (res.data.code == 0) {
          const { title, date, source, abstr, htmlData, display } = res.data.data;
          this.form.title = title;
          this.form.date = date;
          this.form.source = source;
          this.form.abstr = abstr;
          this.form.display = display;
          this.content = htmlData;
          if (display == 1) {
            this.form.type = 1
          }
        }
      });
    }
  }
  // mounted() {
  //   const { id } = this.$route.query;
  //   if (id) {
  //     this.title = "编辑新闻";
  //     this.getNewsInfo(id);
  //   }
  //   console.log("this.$route: ", this.$route);
  // }
};
</script>
<style lang="scss">
.content-box {
  p {
    margin-bottom: -6px;
  }
}
</style>
<style lang="scss" scoped>
.news-title {
  padding: 0 50px;
  font-size: 14px;
  color: #606266;
}
.form-box {
  padding: 30px;

  .width-style {
    width: 100%;
  }
}
.ql-editor.ql-line-height {
  margin: 0 auto;
  overflow: scroll;
  width: 740px;
  line-height: 1.8;
}
.box {
  height: 100vh;
  padding: 10px;
  padding-bottom: 250px;
  background: #fff;
  .edit-header {
    padding: 10px;
    .header-title {
      padding: 10px 0;
    }
  }
  .edit-box {
    height: 60vh;
  }
  .flex-box {
    display: flex;
    justify-content: center;
  }
  .btn-group {
    margin-top: 20px;
  }
  .btn {
    min-width: 80px;
    text-align: center;
    padding: 5px;
    border-radius: 2px;
    display: inline-block;
    cursor: pointer;
    &.btn-primary {
      background: #108ee9;
      color: #ffffff;
    }
  }
}
</style>
