<template>
  <div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="600px"
      top="10vh"
    > 
      <div class="apply-title">业务联系</div>
      <el-form ref="form" 
        class="apply-form-item" 
        :model="form" 
        :rules="rules"
        :hide-required-asterisk="true"
        label-width="180px">
        <el-form-item label="联系电话:">
          <span>13828810957（潘先生）</span>
        </el-form-item>
		<el-form-item label="微信号码:">
		  <span>zspanhuaxin</span>
		</el-form-item>
      </el-form>
	  <div>
		  <div align="center">
			  <img src="../../../img/aiMonitor/weChat.jpg" />
		  </div>
		  <div style="text-align: center;margin-top: 10px;">扫描上方微信二维码与我们联系</div>
	  </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ['title', 'type'],
  data() {
    const res = /^[1][3,4,5,7,8,9][0-9]{9}$/
    const checkPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入手机号'))
      } else if (!res.test(value)) {
        callback(new Error('请输入正确的手机号码'))
      } else {
        callback()
      }
    }

    const resEmail = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
    const checkEmail = (rule, value, callback) => {
      if (value && !resEmail.test(value)) {
        callback(new Error('请输入正确的邮箱'))
      } else {
        callback()
      }
    }
    return {
      rules: {
        userName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        phone: [
          { validator: checkPhone, trigger: 'blur' }
        ],
        company: [
          { required: true, message: '请输入公司', trigger: 'blur' }
        ],
        city: [
          { required: true, message: '请输入公司所在城市', trigger: 'blur' }
        ],
        email: [
          { validator: checkEmail, trigger: 'blur' }
        ]
      },
      dialogVisible: false,
      form: {
        userName: '',
        phone: '',
        company: '',
        email: '',
        remark: '',
        city: '',
        mod: ''
      }
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.type === 'contact') {
            this.form.mod = 'contactUs'
          } else {
            this.form.mod = 'trial'
          }
          this.$ajax.post('/news/ai/user/create', this.form).then(res => {
            if (res.data.code === 0) {
              this.$message({
                message: '提交成功！',
                type: 'success'
              })
              this.$refs.form.resetFields()
              this.dialogVisible = false
            } else {
              this.$message({
                message: res.data.message,
                type: 'warning'
              })
            }
          })
        }
      })
    },
    show() {
      this.dialogVisible = true
    }
  },
  computed: {
    btnType () {
      if (this.form.userName || this.form.phone || this.form.company) {
        return 'primary'
      } else {
        return 'info'
      }
    }
  }
}
</script>

<style lang="scss">
  .el-dialog__headerbtn .el-dialog__close {
    color: #000;
    font-weight: bold;
  }
  .apply-title {
    font-family: "Microsoft YaHei","PingFangSC-Regular";
    font-size: 24px;
    color: #1F1F1F;
    letter-spacing: 0;
    text-align: center;
    margin: 12px 0 42px 0;
  }
  .apply-form-item {
    padding-left: 70px;
    .input-item {
      width: 300px;
    }
    .el-form-item__label{
      font-size: 16px;
    }
    .el-form-item__content {
      text-align: left;
    }
  }
  .submit-btn {
    padding: 14px 159px;
    font-size: 16px;
    margin-bottom: 56px;
  }
  .dialog-footer {
    text-align: center;
  }
</style>