<template>
  <div class="news">
    <div class="top-bg">
      <Header class="header" />
      <div class="top-bg-text">新闻中心</div>
    </div>
    <div class="content">
      <div class="breadcrumb">
        <span @click="$router.push('/news')">新闻中心</span>
        <img src="../img/news/2.svg" alt="" />
        <!-- <i class="el-icon-arrow-right"></i> -->
        <span style="color: #1F1F1F;">{{ data.title }}</span>
      </div>
      <div class="cnt-box">
        <div class="msgs">
          <div style="font-size: 32px;margin-bottom: 40px;text-align: center;">
            {{ data.title }}
          </div>
          <div class="date-form">
            <div class="date">{{ data.date }}</div>
            <div class="form">
              <span>{{ data.source }}</span>
            </div>
          </div>
          <div
            v-html="htmlData"
            class="content-box ql-editor ql-line-height"
          ></div>
          <div v-if="!htmlData">
            <template v-for="(item, index) in data.contents">
              <div :key="index">
                <div
                  v-if="item.subTitle"
                  style="font-size: 16px;font-weight: bold;margin-top:30px;"
                >
                  {{ item.subTitle }}
                </div>
                <div v-if="item.content" class="contentsText">
                  <pre>{{ item.content }}</pre>
                </div>
                <div v-if="item.pictures[0].pictureId" style="margin-top:30px;">
                  <img
                    class="contentsImg"
                    :src="item.pictures[0].pictureUrl"
                    alt=""
                  />
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="right-box">
          <div class="right-title">近期文章</div>
          <div
            v-for="item in list"
            :key="item.id"
            @click="checkNwe(item.id)"
            class="right-cnt"
          >
            <div class="msg">{{ item.title }}</div>
            <div class="date">{{ item.date }}</div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import Header from "@/components/header";
import Footer from "@/components/footer";
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      data: {},
      htmlData: "<div>数据加载中...</div>",
      list: [],
      newID: ""
    };
  },
  methods: {
    loadData() {
      this.$ajax
        .post("/news/info", {
          id: this.newID
        })
        .then(res => {
          this.data = res.data.data;
          this.htmlData = res.data.data.htmlData;
        });
    },
    loadNews() {
      this.$ajax
        .post("/news/list", {
          pageNum: 1,
          pageSize: 3,
          type: 0
        })
        .then(res => {
          this.list = res.data.data;
        });
    },
    checkNwe(id) {
      this.newID = id;
      this.loadData();
    }
  },
  mounted() {
    this.newID = this.$route.query.id;
    this.loadData();
    this.loadNews();
    let html = document.querySelector("html");
    html.scrollTop = 0;
  }
};
</script>
<style lang="scss">
.content-box {
  p {
    margin-bottom: -6px;
  }
}
</style>
<style lang="scss" scoped>
.cnt-box {
  overflow: hidden;
  > div {
    float: left;
  }
}
.right-box {
  width: 280px;
  padding: 40px;
  background: #fff;
  color: #1f1f1f;
  .right-title {
    font-size: 24px;
  }
  .right-cnt {
    margin-top: 30px;
    cursor: pointer;
    .msg {
      font-size: 16px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      &:hover {
        color: #007aff;
      }
    }
    .date {
      margin-top: 10px;
      font-size: 14px;
      opacity: 0.4;
    }
  }
}
.content-box {
  overflow: hidden;
  &.ql-editor.ql-line-height {
    line-height: 1.8;
  }
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
.contentsText {
  margin-top: 20px;
  line-height: 1.8;
  font-size: 14px;
  text-indent: 28px;
}
.contentsImg {
  max-width: 410px;
}
.date-form {
  padding-left: 15px;
  .date {
    font-size: 18px;
    opacity: 0.4;
  }
  .form {
    font-size: 16px;
    padding-left: 10px;
    margin-left: 10px;
    border-left: 2px solid rgba(0, 122, 255, 0.2);
    span {
      opacity: 0.4;
    }
  }
  div {
    display: inline-block;
    line-height: 28px;
    vertical-align: middle;
  }
}
.msgs {
  margin-bottom: 120px;
  background: #fff;
  width: 760px;
  padding: 60px;
  margin-right: 40px;
  color: #1f1f1f;
  box-sizing: border-box;
}
.breadcrumb {
  font-size: 16px;
  color: #aeaeae;
  line-height: 22px;
  margin-top: 40px;
  margin-bottom: 18px;
  span {
    cursor: pointer;
    &:first-child {
      margin-left: 20px;
    }
  }
  img {
    vertical-align: bottom;
  }
}
.news {
  background: #f8f8f8;
}
.top-bg {
  height: 560px;
  position: relative;
  background-image: url("../img/news/1.jpg");
  background-position: center;
  .header {
    position: absolute;
    top: 0;
    z-index: 100;
    height: 64px;
    width: 100%;
  }
  .top-bg-text {
    padding-top: 235px;
    text-align: center;
    font-size: 64px;
    color: #ffffff;
    letter-spacing: 20px;
  }
}
</style>
