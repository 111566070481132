<template>
  <div>
    <div class="top-bg">
      <Header class="header" />
      <div class="content">
        <div class="top-text">人脸考勤</div>
        <div class="top-sub-text">
          10亿级人脸识别数据库，精准快速识别比对，提升考勤效率和准确率。
        </div>
        <div class="top-btn">
          <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
          <el-button class="coop-btn" round @click="consultClick">合作咨询</el-button>
        </div>
      </div>
    </div>

    <div class="content item-header">
      <div class="item-title">方案介绍</div>
    </div>
    <div class="content clear solution" style="margin-top: 60px">
      <div class="solution-wrap">
          <div class="solution-img" style="margin-left: 42px;">
            <img src="../../img/aiImage/onlineEdu/face/solution_1.png" alt="">
            <div class="title">建立人脸库</div>
            <div style="margin-left: 80px;">
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">人脸采集</span>
              </div>
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">建立人脸库</span>
              </div>
            </div>
          </div>
          <div class="sulution-arrow">
            <img src="../../img/aiImage/onlineEdu/face/arrow.png" alt="">
          </div>
          <div class="solution-img">
            <img src="../../img/aiImage/onlineEdu/face/solution_2.png" alt="">
            <div class="title">无感考勤</div>
            <div style="margin-left:45px;">
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">人脸检测</span>
                <img style="margin-left:35px;" class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">人脸跟踪</span>
              </div>
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">活体检测</span>
                <img style="margin-left:35px;" class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">人脸对比</span>
              </div>
            </div>
          </div>
          <div class="sulution-arrow">
            <img src="../../img/aiImage/onlineEdu/face/arrow.png" alt="">
          </div>
          <div class="solution-img">
            <img src="../../img/aiImage/onlineEdu/face/solution_3.png" alt="">
            <div class="title">考勤记录</div>
            <div style="margin-left: 92px;">
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">验证结果</span>
              </div>
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">考勤记录</span>
              </div>
            </div>
          </div>
      </div>
    </div>

    <div class="content item-header">
      <div class="item-title">方案优势</div>
    </div>
    <div class="content clear" style="margin-top: 60px;display: flex;">
      <div class="advantage">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../../img/aiImage/onlineEdu/face/advantage_1.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">极速</div>
            <div style="margin-left: 125px;">
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">100-200ms</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="advantage" style="margin-left:37px;">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../../img/aiImage/onlineEdu/face/advantage_2.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">易用</div>
            <div style="margin-left: 110px;">
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">误识率≤0.01%</span>
              </div>
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">拒识率≤0.1%</span>
              </div>
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">不受日常妆容变化影响</span>
              </div>
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">暗光和逆光下解锁</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="advantage" style="margin-left:37px;">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../../img/aiImage/onlineEdu/face/advantage_3.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">安全</div>
            <div style="margin-left: 90px;">
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">生物活体防伪检测</span>
              </div>
              <div class="item">
                <img class="check-img" src="../../img/aiImage/onlineEdu/face/check.png" alt="">
                <span class="text">有效防止电子照片、视频或冲</span>
              </div>
              <div class="item"><span class="text" style="margin-left: 27px;">印照片的攻击</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content item-header">
      <div class="item-title">应用场景</div>
    </div>
    <div class="content clear" style="margin-top: 60px;display:flex;">
      <div class="scene-item">
        <div>
          <img src="../../img/aiImage/onlineEdu/face/scene_1.jpg" alt="">
        </div>
        <div class="scene-title">线下多人考勤</div>
        <div class="scene-text">一个摄像头对多人实时考勤，节省老师点名时间，方便回溯到课情况。</div>
      </div>
      <div class="scene-item" style="margin-left:40px;">
        <div>
          <img src="../../img/aiImage/onlineEdu/face/scene_2.jpg" alt="">
        </div>
        <div class="scene-title">在线考勤防作弊</div>
        <div class="scene-text">提高考勤效率，防止代上课、假照片等考勤作弊行为。</div>
      </div>
    </div>

    <div class="content item-header" style="margin-bottom: 120px;">
      <contact></contact>
    </div>
    <dialog-consult ref="consult"></dialog-consult>
    <dialog-apply ref="apply"></dialog-apply>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import contact from "./components/contact"
import DialogConsult from './components/dialogConsult.vue';
import DialogApply from './components/dialogApply'
export default {
  components: {
    Header,
    Footer,
    contact,
    DialogConsult,
    DialogApply
  },

  data() {
    return {
    }
  },

  methods: {
    consultClick () {
      this.$refs.consult.show()
    },
    applyClick () {
      this.$refs.apply.show()
    }
  },

  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
.top-bg {
  position: relative;
  background-image: url("../../img/aiImage/onlineEdu/face/header.jpg");
  background-position: center;
  .header {
    position: absolute;
    top: 0;
    z-index: 100;
    height: 64px;
    width: 100%;
  }
  .top-text {
    font-size: 64px;
    color: #ffffff;
    padding-top: 220px;
    padding-left: 60px;
    box-sizing: border-box;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-sub-text {
    width: 600px;
    height: 72px;
    line-height: 36px;
    margin-top: 40px;
    font-size:18px;
    color: #ffffff;
    padding-left: 60px;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-btn {
    padding-left: 60px;
    margin-top: 72px;
    .apply-btn {
      font-size: 18px;
      color: #fff;
      padding: 14px 37px;
      background-color: #007aff;
      border: none;
      border-radius: 25px;
    }
    .coop-btn {
      font-size: 18px;
      color: #0059FF;
      padding: 14px 37px;
      margin-left: 72px;
      border: none;
      border-radius: 25px;
    }
  }
}

.solution {
  background-color: #fff;
  width: 1160px;
  height: 520px;
  .solution-wrap {
    display: flex;
    .solution-img {
      width: 280px;
      height: 458px;
      padding-top: 62px;
      .title {
        margin: 34px 0 10px 0;
        font-size: 24px;
        color: #1F1F1F;
        letter-spacing: 0;
        text-align: center;
        font-weight: bold;
        font-family: "Microsoft YaHei","PingFangSC-Regular";
      }
      .item {
        line-height: 24px;
        height: 24px;
        vertical-align: middle;
        .check-img {
          margin-right: 10px;
          vertical-align: middle;
        }
        .text {
          font-size: 14px;
          color: #1F1F1F;
          letter-spacing: 0;
          line-height: 24px;
          vertical-align: middle;
          font-family: "Microsoft YaHei","PingFangSC-Regular";
        }
      }
    }
    .sulution-arrow {
      width: 78px;
      height: 339px;
      padding: 181px 0 0 37px;
    }
  }
}

.advantage {
  height: 360px;
  width: 360px;
  background-color: #fff;
  .advantage-wrap {
    align-items: center;
  }
  .advantage-img {
    margin-top: 50px;
    text-align: center;
  }
  .advantage-text {
    .advantage-text-title {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 10px;
      font-size: 24px;
      color: #1F1F1F;
      letter-spacing: 0;
      font-weight: bold;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
    .item {
      line-height: 24px;
      height: 24px;
      vertical-align: middle;
      .check-img {
        margin-right: 10px;
        vertical-align: middle;
      }
      .text {
        font-size: 14px;
        color: #1F1F1F;
        letter-spacing: 0;
        line-height: 24px;
        vertical-align: middle;
        font-family: "Microsoft YaHei","PingFangSC-Regular";
      }
    }
  }
}

.scene-item {
  width: 560px;
  height: 530px;
  background-color: #fff;
  .scene-title {
    font-size: 32px;
    color: #1F1F1F;
    letter-spacing: 0;
    text-align: center;
    margin: 38px 0 30px 0;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .scene-text {
    font-size: 16px;
    color: #1F1F1F;
    letter-spacing: 0;
    text-align: center;
    margin: 0 70px;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
}

</style>
