<template>
  <div>
    <el-form v-show="!preview" class="form" ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="日期" prop="date">
          <el-date-picker
            v-model="form.date"
            type="date"
            value-format="yyyy.MM.dd"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="来源" prop="source">
          <el-input v-model="form.source"></el-input>
        </el-form-item>
        <el-form-item label="摘要">
          <el-input
              type="textarea"
              :rows="2"
              v-model="form.abstr">
            </el-input>
        </el-form-item>
        <div class="contents" v-for="(item, index) in form.contents" :key="index">
          <!-- :prop="'contents.' + index + '.subTitle'"
          :rules="{
            required: true, message: '必填', trigger: 'blur'
          }" -->
          <el-form-item label="子标题" 
          :key="'items.' + index"
          >
            <el-input v-model="item.subTitle"></el-input>
          </el-form-item>
          <el-form-item label="内容">
            <el-input
              type="textarea"
              :rows="2"
              v-model="item.content">
            </el-input>
          </el-form-item>
          <el-form-item label="图片">
            <el-upload
              :ref="index + 'upload'"
              action="/news/upload"
              accept=".jpg,.png"
              list-type="picture-card"
              name="file"
              :file-list="item.fileList"
              :on-success="success"
              :on-progress="progress"
              >
                <i @click="fileIndexFun(index)" slot="default" style="padding: 59px;" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url" alt=""
                  >
                </div>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </el-form-item>

          
        </div>

        <el-form-item label="">
          <el-button @click="addMore" type="primary" icon="el-icon-circle-plus-outline" circle></el-button>
        </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmit">提交</el-button>
        <el-button @click="$router.push('/news')">返回新闻</el-button>
      </el-form-item>
    </el-form>
    <!-- <div style="text-align: center;"><el-button @click="preview = !preview" >{{ preview ? '关闭预览' : '预览' }}</el-button></div> -->



    <div v-show="preview" class="msgs">
      <div style="font-size: 32px;margin-bottom: 40px;">360OS逆势上扬 开拓更广阔“智能”赛道</div>
      <div class="date-form">
        <div class="date">2019. 09</div>
        <div class="form"><span>TechWeb.com.cn</span></div>
      </div>
      <div style="margin-top:22px;line-height:20px;font-size: 14px;text-indent: 28px;">
        当智能终端市场竞争更加激烈，产业链上中下游厂商需要越来越迫切的生存与转型。面对竞争日益激烈的市场环境，360OS在2019实现逆势上扬，新增数十个合作伙伴，上百个项目，通过OS业务的升级，在拥挤的赛道上跑出了“向上”的生态。
      </div>
      <div style="margin-top:30px;">
        <img src="../img/news/5.png" alt="">
      </div>
      <div style="font-size: 16px;font-weight: bold;margin-top:30px;">
        升级转型，挖掘市场价值
      </div>
      <div style="margin-top:20px;line-height:20px;font-size: 14px;text-indent: 28px;">
          作为智能手机操作系统领域领先提供商，360OS见证了国内智能手机品牌的兴起和发展。截至目前，360OS凭借OS领域领先技术力服务了国内包括酷派、海信、波导、康佳等在内的上百家合作伙伴。当前，如何更好的抢占智能终端下沉市场份额，挖掘人口红利，满足用户日益多元化的业务需求成为摆在360OS与行业合作伙伴面前共同的难题。
          基于此，360OS在2019年对业务上进行了全线转型升级。一方面，OS 5.0蓄势待发，开发了更多针对细分用户需求的个性化应用；另一方面，360OS正在通过定制化的设计和技术顾问式的服务，为行业开创全新、更富有价值的业务合作模式。
      </div>
      <div style="margin-top:30px;">
        <img src="../img/news/6.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/server/api"
export default {
  data() {
    return {
      preview: false,
      form: {
        contents: []
      },
      fileIndex: 0,
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      rules: {
        title: [{ required: true, message: '必填', trigger: 'blur' }],
        date: [{ required: true, message: '必填', trigger: 'blur' }],
        source: [{ required: true, message: '必填', trigger: 'blur' }],
        abstr: [{ required: true, message: '必填', trigger: 'blur' }]
      }
    }
  },
  methods: {
    addMore() {
      this.form.contents.push({
        subTitle: '',
        content: '',
        fileList: [],
        pictures: [
          {
            pictureId: '',
            pictureUrl: ''
          }
        ]
      })
    },
    success(res, file) {
      this.form.contents[this.fileIndex].pictures[0].pictureId = res.data.fileId
      this.form.contents[this.fileIndex].pictures[0].pictureUrl = res.data.url
      // console.log(res)
      // this.imageUrl = URL.createObjectURL(file.raw);
    },
    progress(event, file, fileList) {
      this.form.contents[this.fileIndex].fileList = fileList.slice(-1);
    },
    fileIndexFun(index) {
      this.fileIndex = index
      // this.fileList = fileList.slice(-3);
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$ajax.post(api.newsAdd, this.form).then(
            ref => {
              this.$router.push('/news')
            }
          )
        } 
      })
    },
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.form {
  margin-top: 40px;
  margin-left: 40px;
  width: 50%;
  .contents {
    border: 1px solid #ccc;
    padding: 20px;
  }
}


.date-form {
  .date {
    font-size: 20px;
    opacity: 0.4;
  }
  .form {
    font-size: 16px;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 2px solid rgba(0,122,255,0.2);
    span {
      opacity: 0.4;
    }
  }
  div {
    display: inline-block;
    line-height: 28px;
  }
}
.msgs {
  margin-bottom: 120px;
  background: #fff;
  width: 860px;
  padding: 60px;
  color: #1F1F1F;
  box-sizing: border-box;
}
.breadcrumb {
  font-size: 16px;
  font-size: 16px;
  color: #AEAEAE;
  line-height: 52px;
  span {
    margin: 0 20px;
    cursor: pointer;
  }
}
.news {
  background: #F8F8F8;
}
.top-bg {
  height: 560px;
  position: relative;
  background-image: url('../img/news/1.jpg');
  background-position: center;
  .header {
    position: absolute;
    top: 0;
    z-index: 100;
    height: 64px;
    width: 100%;
  }
  .top-bg-text {
    padding-top: 235px;
    text-align: center;
    font-size: 64px;
    color: #FFFFFF;
    letter-spacing: 20px;
  }
}
</style>