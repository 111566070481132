<template>
  <div>
    <div class="top-bg">
      <Header class="header" />
      <div class="content">
        <div class="top-text">智慧多帧画质</div>
        <div class="top-sub-text">
          包括多帧降噪、超级夜景、防抖、HDR、超分辨率等算法SDK和解决方案
        </div>
        <div class="top-btn">
          <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
          <el-button class="coop-btn" round @click="consultClick">合作咨询</el-button>
        </div>
      </div>
    </div>

    <!-- <div class="content item-header">
      <div class="item-title">多帧降噪</div>
      <div class="item-text">可变光圈，支持F1.0-有效抑制彩色噪点和亮度噪点，细节高保真，色彩还原真实</div>
    </div>
    <div class="content" style="margin-top: 60px">
      <img src="../../img/aiImage/phone/frame_1.png" alt="">
    </div> -->

    <div class="content item-header">
      <div class="item-title">防抖</div>
      <div class="item-text">替代光学防抖硬件，纯软防抖方案减少运动模糊</div>
    </div>
    <div class="content" style="margin-top: 60px">
      <img src="../../img/aiImage/phone/frame_2.png" alt="">
    </div>

    <div class="content item-header">
      <div class="item-title">HDR</div>
      <div class="item-text">针对逆光场景拍摄高动态范围照片，高质感人像，逆光更清晰</div>
    </div>
    <div class="content" style="margin-top: 60px">
      <img src="../../img/aiImage/phone/frame_3.png" alt="">
    </div>

    <div class="content item-header">
      <div class="item-title">超级夜景</div>
      <div class="item-text">多重动态曝光，黑夜变白昼，有效抑制噪点、防手抖、去鬼影</div>
    </div>
    <div class="content" style="margin-top: 60px">
      <img src="../../img/aiImage/phone/frame_4.png" alt="">
    </div>

    <div class="content item-header">
      <div class="item-title">超分辨率</div>
      <div class="item-text">模拟还原真实细节，极大提升图像清晰度，修复老照片及废片</div>
    </div>
    <div class="content" style="margin-top: 60px">
      <img src="../../img/aiImage/phone/frame_5.png" alt="">
    </div>

    <div class="content item-header">
      <div class="item-title">方案优势</div>
    </div>
    <div class="content clear solution" style="margin-top: 60px">
      <div class="solution-wrap">
        <div class="solution-img">
          <img class="img-top" src="../../img/aiImage/phone/frame_6.png" alt="">
          <div class="title">丰富算法</div>
          <div class="text" style="width:254px;">包含超级夜景、人像HDR、MFNR、前置夜景、超分、防抖去模糊等算法</div>
        </div>
        <div class="solution-img" style="margin-left:40px;">
          <img class="img-top" src="../../img/aiImage/phone/frame_7.png" alt="">
          <div class="title">全场景覆盖</div>
          <div class="text" style="width:280px;">实现逆光、低光、高光、正常光照、人、物、广角、高中低端配置等全场景覆盖</div>
        </div>
        <div class="solution-img" style="margin-left:40px;">
          <img class="img-top" src="../../img/aiImage/phone/frame_8.png" alt="">
          <div class="title">效果领先</div>
          <div class="text" style="width:258px;">纹理细节增强、高动态范围、3D降噪、去鬼影、更清晰更通透、色彩高保真</div>
        </div>
      </div>
    </div>

    <div class="content item-header" style="margin-bottom: 120px;">
      <contact></contact>
    </div>
    <dialog-consult ref="consult"></dialog-consult>
    <dialog-apply ref="apply"></dialog-apply>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import contact from "./components/contact"
import DialogConsult from './components/dialogConsult.vue';
import DialogApply from './components/dialogApply'
export default {
  components: {
    Header,
    Footer,
    contact,
    DialogConsult,
    DialogApply
  },

  data() {
    return {
    }
  },

  methods: {
    consultClick () {
      this.$refs.consult.show()
    },
    applyClick () {
      this.$refs.apply.show()
    }
  },

  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
.top-bg {
  position: relative;
  background-image: url("../../img/aiImage/phone/banner_frame.jpg");
  background-position: center;
  .header {
    position: absolute;
    top: 0;
    z-index: 100;
    height: 64px;
    width: 100%;
  }
  .top-text {
    font-size: 64px;
    color: #ffffff;
    padding-top: 220px;
    padding-left: 60px;
    box-sizing: border-box;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-sub-text {
    width: 600px;
    height: 72px;
    line-height: 36px;
    margin-top: 40px;
    font-size:18px;
    color: #ffffff;
    padding-left: 60px;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-btn {
    padding-left: 60px;
    margin-top: 72px;
    .apply-btn {
      font-size: 18px;
      color: #fff;
      padding: 14px 37px;
      background-color: #007aff;
      border: none;
      border-radius: 25px;
    }
    .coop-btn {
      font-size: 18px;
      color: #0059FF;
      padding: 14px 37px;
      margin-left: 72px;
      border: none;
      border-radius: 25px;
    }
  }
}

.solution {
  .solution-wrap {
    display: flex;
    .solution-img {
      background-color: #fff;
      width: 360px;
      height: 360px;
      .img-top {
        margin: 50px 0 0 120px;
      }
      .title {
        margin-top: 30px;
        margin-bottom: 10px;
        font-size: 24px;
        color: #1F1F1F;
        letter-spacing: 0;
        text-align: center;
        font-weight: bold;
        font-family: "Microsoft YaHei","PingFangSC-Regular";
      }
      .text {
        font-size: 14px;
        color: #1F1F1F;
        letter-spacing: 0;
        line-height: 24px;
        vertical-align: middle;
        font-family: "Microsoft YaHei","PingFangSC-Regular";
        text-align: center;
        margin: 0 auto;
      }
    }
  }
}

</style>
