<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
export default {
    components:{

    },
    data() {
        return {
        }
    },
}
</script>

<style scoped>

</style>

