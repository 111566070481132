<template>
  <div>
    <div class="top-bg">
      <Header class="header" />
      <div class="content">
        <div class="top-text">沉浸式课件</div>
        <div class="top-sub-text">
          实时人像分割，将人像与课件背景融为一体，呈现沉浸式教学效果。
        </div>
        <div class="top-btn">
          <el-button class="apply-btn" round @click="applyClick">试用申请</el-button>
          <el-button class="coop-btn" round @click="consultClick">合作咨询</el-button>
        </div>
      </div>
    </div>

    <div class="content item-header" style="margin-bottom: 60px">
      <div class="item-title">方案介绍</div>
      <div class="item-text">利用人像分割技术，将背景替换成动态课件，节省绿幕直播间搭建成本，随时随地开启录播/直播课。</div>
      <my-video videoUrl="/immerse.mp4" videoWidth="1160" videoHeight="725" style="margin-top:60px;"></my-video>
    </div>

    <div class="content item-header">
      <div class="item-title">方案优势</div>
    </div>
    <div class="content clear" style="margin-top: 60px;">
      <div class="advantage">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../../img/aiImage/onlineEdu/immerse/low.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">低延时</div>
            <div class="advantage-text-sub-title">高速实时人像分割，无延时感</div>
          </div>
        </div>
      </div>
      <div class="advantage" style="margin-left:40px;">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../../img/aiImage/onlineEdu/immerse/accurate.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">精准分割</div>
            <div class="advantage-text-sub-title">超高精度，超远距离，边缘融合效果自然</div>
          </div>
        </div>
      </div>
      <div class="advantage" style="margin-top:40px;">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../../img/aiImage/onlineEdu/immerse/more.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">多格式</div>
            <div class="advantage-text-sub-title">支持动静态课件背景，包括jpg/mp4等常用图片/视频格式</div>
          </div>
        </div>
      </div>
      <div class="advantage" style="margin-left:40px;margin-top:40px;">
        <div class="advantage-wrap">
          <div class="advantage-img">
            <img src="../../img/aiImage/onlineEdu/immerse/intelligence.png" >
          </div>
          <div class="advantage-text">
            <div class="advantage-text-title">智能操控</div>
            <div class="advantage-text-sub-title">手势识别操控课件播放、暂停、翻页等操作</div>
          </div>
        </div>
      </div>
    </div>

    <div class="content item-header">
      <div class="item-title">应用场景</div>
    </div>
    <div class="content clear" style="margin-top: 120px;">
      <div class="scene-item">
        <div class="scene-info">
          <div class="scene-title">制作录播课</div>
          <div class="scene-text">替代传统录播设备，降低老师录课成本和空间限制，简单高效制作录播课程。</div>
        </div>
        <div>
          <img src="../../img/aiImage/onlineEdu/immerse/scene_1.png" alt="">
        </div>
      </div>
      <div class="scene-item" style="margin-top: 70px;">
        <div>
          <img src="../../img/aiImage/onlineEdu/immerse/scene_2.png" alt="">
        </div>
        <div class="scene-info">
          <div class="scene-title">直播课互动</div>
          <div class="scene-text">将老师置身课件情景当中，结合内容趣味互动，提升课程吸引力。</div>
        </div>
      </div>
    </div>

    <div class="content item-header" style="margin-bottom: 120px;">
      <contact></contact>
    </div>
    <dialog-consult ref="consult"></dialog-consult>
    <dialog-apply ref="apply"></dialog-apply>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import contact from "./components/contact"
import DialogConsult from './components/dialogConsult.vue';
import DialogApply from './components/dialogApply'
import MyVideo from './components/myVideo'
export default {
  components: {
    Header,
    Footer,
    contact,
    DialogConsult,
    DialogApply,
    MyVideo
  },

  data() {
    return {
    }
  },

  methods: {
    consultClick () {
      this.$refs.consult.show()
    },
    applyClick () {
      this.$refs.apply.show()
    }
  },

  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
.top-bg {
  position: relative;
  background-image: url("../../img/aiImage/onlineEdu/immerse/header.jpg");
  background-position: center;
  .header {
    position: absolute;
    top: 0;
    z-index: 100;
    height: 64px;
    width: 100%;
  }
  .top-text {
    font-size: 64px;
    color: #ffffff;
    padding-top: 220px;
    padding-left: 60px;
    box-sizing: border-box;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-sub-text {
    width: 600px;
    height: 72px;
    line-height: 36px;
    margin-top: 40px;
    font-size:18px;
    color: #ffffff;
    padding-left: 60px;
    font-family: "Microsoft YaHei","PingFangSC-Regular";
  }
  .top-btn {
    padding-left: 60px;
    margin-top: 72px;
    .apply-btn {
      font-size: 18px;
      color: #fff;
      padding: 14px 37px;
      background-color: #007aff;
      border: none;
      border-radius: 25px;
    }
    .coop-btn {
      font-size: 18px;
      color: #0059FF;
      padding: 14px 37px;
      margin-left: 72px;
      border: none;
      border-radius: 25px;
    }
  }
}

.advantage {
  height: 240px;
  width: 560px;
  background-color: #fff;
  display: inline-block;
  .advantage-wrap {
    display: flex;
    align-items: center;
  }
  .advantage-img {
    >img {
      margin: 60px 40px 60px 60px;
    }
  }
  .advantage-text {
    .advantage-text-title {
      font-size: 24px;
      color: #1F1F1F;
      letter-spacing: 0;
      font-weight: bold;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
    .advantage-text-sub-title {
      font-size: 14px;
      line-height: 24px;
      color: #1F1F1F;
      letter-spacing: 0;
      margin-top: 10px;
      margin-right: 40px;
      font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
  }
}

.scene-item {
  display: flex;
  width: 1160px;
  height: 400px;
  .scene-info {
    width: 500px;
    height: 400px;
    .scene-title {
        font-size: 32px;
        color: #1F1F1F;
        letter-spacing: 0;
        text-align: center;
        margin: 143px 0 30px 0;
        font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
    .scene-text {
        font-size: 16px;
        color: #1F1F1F;
        letter-spacing: 0;
        text-align: center;
        margin: 0 40px;
        font-family: "Microsoft YaHei","PingFangSC-Regular";
    }
  }
}

</style>
