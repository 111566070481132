<template>
  <div id="app">
    <div class="header">
      <a href="/"><img src="@/img/logo_blue.svg" alt=""/></a>
    </div>
    <div class="text-content">
      <div class="title">用户许可及使用协议</div>
      <pre>
          尊敬的用户您好：
          本协议是用户（或称“您”）与西安欧思奇软件有限公司（下称“公司”或“我们”）之间关于智能移动终端的操作系统（以下简称“本系统”或“操作系统”）使用、更新的法律协议。
          在您使用本系统之前，请您务必认真阅读和理解《用户许可及使用协议》（以下简称“本协议”或“协议”）中规定的所有条款，特别是免除或者限制责任的条款、争议解决和法律适用条款，<b>免除或者限制责任的条款可能将以加粗字体显示，您应重点阅读。</b>您在经阅读后可以选择接受或不接受本协议项下所有条款（未成年人应在法定监护人陪同下阅读），如您实际使用我们提供的操作系统，即表示您已充分阅读、理解并接受本协议的全部内容，并与我们达成协议。
      </pre>
      <div class="title2">一、一般规定</div>
      <pre>
        （一）本协议中所述的操作系统是指：由西安欧思奇软件有限公司开发并向用户提供的功能组件、系统服务及软件，但并不包含本协议中第一条第（二）项中定义的开源软件。
        （二）本系统以及在本系统其更新、升级后可能含有受第三方版权保护的由第三方授权并拥有开源软件许可证的软件（以下简称“开源软件”）。通过本系统承载设备上的许可证信息便可区分此类开源软件。因此，当您使用此类开源软件时，还应遵守适用于开源软件许可证的条款及相关条件。若本协议中所述的许可类相关条款与开源软件许可证中有关开源软件的内容存在任何冲突，应当以开源软件许可证中内容为准。
      </pre>
      <div class="title2">二、系统的许可使用</div>
      <pre>
          （一）在遵守本协议项下所有条款的前提下，您可以于系统承载设备上以有限的、非独家的方式使用本系统，不得复制、出售、转让或是用于其他任何商业用途。
          （二）除非另有我们书面的明示许可，您同意不得且不会促使其他任何个人、竞品公司或其他组织对本系统或是内置软件进行拆解、复制、反向工程、反向编译、反汇编亦或创作预置软件的衍生作品。同样，对于本系统中的源代码，您同意不得且不会促使其他任何人进行或试图包括但不限于导出、解码、修改或其他有损本系统的行为。
          （三）您同意不会出租、出借、再分发亦或是再许可本系统给任何第三方。但您可就本系统智能终端载体所有权的合法转让，而将您根据本协议对本系统的使用许可一次性且永久转让给另一方，该使用许可转移须满足以下条件:
          1、本系统涉及软件，包括系统所有组成部分、开源软件及本协议，须与终端操作载体的所有权一并转让；
          2、您须删除关于本系统及相关预置软件的全部或部分复制本，包括储存于计算机或其它介质的复制本。
          （四）您同意不使用本系统、软件及前两者相关更新内容从事以下行为：
          1、在本协议授权使用的范围之外使用、复制或转让本系统的全部或部分内容；
          2、在没有公司书面许可的前提下，提供第三方、或允许第三方使用本系统的全部或部分（包括但不局限于本系统下运营的应用、主题、代码、源代码及相关服务等）；
          3、以欺骗性目的或是以欺骗性方式使用本系统；
          4、删除本系统内包含的任何一个使用版权声明或提示；
          5、作废、改变、破坏或逃避与本系统相关的或者属于本系统组成的全部或部分数字版权管理部分；
          6、其他不当或违反法律法规、行业规定的行为。
          （五）您在使用本系统进行存储或复制素材或材料时，您需要取得相关权利人的合法授权。本系统提供的素材或材料的复制功能仅限于复制没有相关版权保护的素材或材料，对于有版权保护的素材或材料是受著作权法或其他知识产权法律和条约的保护，并需要遵守材料或素材提供方的使用协议或相关条款。除另有约定，本协议的授权无法包含此等素材或材料，亦无法保证您可继续正常使用该类材料，我们无法关于此类状况承担法律责任。
      </pre>
      <div class="title2">三、第三方账号</div>
      <pre>
          由于本系统的部分功能和/或软件的使用，可能需要您进行账号注册。您可以根据本系统的开机指引进行注册，此账号一般由我们所合作的第三方进行提供和维护，您同意遵守第三方账号注册的要求和条款提供相应信息和使用账号相关服务。您了解并同意，您于本系统中注册成功，您即可以获得第三方服务账号。
      </pre>
      <div class="title2">四、隐私政策</div>
      <pre>
          《个人隐私保护政策》（以下简称“隐私政策”）提供了与本系统收集的数据以及我们使用这些数据的方式相关的信息。当您在设备上使用本系统时，我们将依据本协议和隐私政策的规定从您的终端设备上收集相关数据，隐私政策与本协议有同等法律效力且为不可分割的一部分。
      </pre>
      <div class="title2">五、账号管理</div>
      <pre>
          （一）由于本系统的部分功能和/或软件的使用，可能需要您通过第三方账号登录并享受相关服务。
          （二）帐号注销
          在需要终止使用本系统的帐号服务时，符合以下条件的，您可以申请注销您的帐号（申请注销账号的方式如隐私政策中所公布的为准）：
          1、您仅能申请注销您本人的帐号，并依照本系统的流程进行注销；
          2、您仍应对您在注销帐号前且使用本系统服务期间的行为承担相应责任；
          3、注销成功后，帐号记录、功能等将无法恢复或提供。
          如您需要注销您的帐号，请您通过本协议第十二条公布的联系方式联系我们，我们将有专人为您提供账号注销服务。
          （三）您理解并承诺，您所设置的帐号不得违反国家法律法规及本系统中的相关规则，您的帐号名称、头像和简介等注册信息及其他个人信息中不得出现违法和不良信息，未经他人许可不得用他人名义（包括但不限于冒用他人姓名、名称、字号、头像等或采取其他足以让人引起混淆的方式）开设帐号，不得恶意注册帐号（包括但不限于频繁注册、批量注册帐号等行为）。您在帐号注册及使用过程中需遵守相关法律法规，不得实施任何侵害国家利益、损害其他公民合法权益，有害社会道德风尚的行为。我们有权对您提交的注册信息进行审核。
          （四）您在本系统中的注册帐号仅限于您本人使用，未经公司书面同意，禁止以任何形式赠与、借用、出租、转让、售卖或以其他方式许可他人使用该帐号。如果公司发现或者有合理理由认为使用者并非帐号初始注册人，为保障帐号安全，我们有权立即暂停或终止向该注册帐号提供服务，并有权永久禁用该帐号。
          （五）您有责任维护个人帐号、密码的安全性与保密性，并对您以注册帐号名义所从事的活动承担全部法律责任，包括但不限于您在本系统中相关服务上进行的任何数据修改、言论发表、款项支付等操作行为可能引起的一切法律责任。您应高度重视对帐号与密码的保密，在任何情况下不向他人透露帐号及密码。若发现他人未经许可使用您的帐号或发生其他任何安全漏洞问题时，您应当立即通知我们。
      </pre>
      <div class="title2">六、使用规范</div>
      <pre>
          （一） 信息发布规范
          1、您可以使用本系统发表与上传信息，该信息内容应保证具有相应的知识产权或其已获得合法的授权，且使用本系统以及相关服务的任何行为未侵犯任何第三方的合法权益。
          2、您在使用本系统时不得从事以下行为，包括但不限于：制作、复制、发布、传播或储存违反国家法律法规的内容，从事以下行为：
          （1）反对宪法所确定的基本原则的；
          （2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
          （3）损害国家荣誉和利益的；
          （4）煽动民族仇恨、民族歧视，破坏民族团结的；
          （5）破坏国家宗教政策，宣扬邪教和封建迷信的；
          （6）散布谣言，扰乱社会秩序，破坏社会稳定的；
          （7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
          （8）侮辱或者诽谤他人，侵害他人合法权益的；
          （9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；
          （10）以非法民间组织名义活动的；
          （11）含有法律、行政法规禁止的其他内容的；
          （12）发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；
          （13）虚构事实、隐瞒真相以误导、欺骗他人；
          （14）发表、传送、传播广告信息及垃圾信息；
          （15）从事其他违反国家法律法规的其他行为。
          （二） 用户理解并同意：
          1、我们会对您的使用行为是否违反上述规范进行认定，并依据认定结果对您采取中止或终止的限制使用措施；
          2、对于您使用本系统时发布的涉嫌违法、涉嫌侵犯他人合法权益或违反本协议约定的信息，我们将直接删除；
          3、若您违反上述使用规范并对第三方造成损害，您需以个人名义独立承担相关的法律责任，若因此使我们遭受损失、受到第三方的索赔亦或是受到行政管理机关处罚，我们有权对此类损失以及产生的相关合理费用（如律师费、诉讼费等）要求您进行赔偿。
      </pre>
      <div class="title2">七、第三方软件服务说明</div>
      <pre>
          （一）您理解并同意，本系统中可能包含由关联方或第三方合作伙伴提供的软件及相关服务，我们仅为您提供更加便捷的操作而在本系统中提供相关功能，或是提供第三方软件以及相关服务的使用入口，您在使用该软件或是服务的同时须遵守该方的用户协议及隐私政策。
          （二）不论预置于本系统中的第三方软件或服务，还是您自行开通或下载的软件，您均须理解并同意，我们无法监督或监控第三方软件/服务行为，无法全面的对第三方软件或其服务行为的安全性、合法性、有效性进行保证，亦无法承担针对第三方软件或是服务的法律责任。
          （三）若您与第三方软件或是服务的提供方之间发生的任何争议、纠纷，您可与该方依照其许可协议自行协商解决或进入法律程序，我们无法承担任何责任。
      </pre>
      <div class="title2">八、使用风险及免责声明</div>
      <pre>
          <b>（一）我们仅可对本系统的问题承担相关法律责任，但以目前我们技术所提供的服务支持，无法完全保证本系统服务在操作中不发生任何错误或是临时中断，亦无法保证本系统能充分满足您的所有要求,我们无法承担此类状况的全部法律责任。
          （二）若您因第三方问题如技术、通讯、网络、手机硬件设备故障、质量以及其他不可抗力原因而遭受的一切损失，我们以及相关合作方无法承担该类责任,就该部分损失您可向第三方主张赔偿。
          （三）本系统所涉及的内容仅为便利您的使用供您参考，不论从何种角度看均不代表我们的言论，亦不足以构成我们对任何专业领域的建议。若您需要解决相关具体问题，还请咨询相关领域的专业人士。
          （四）我们无法保证通过本系统所获得的第三方提供的内容的合规性、合法性、真实性、准确性，我们仅能于法律法规的规定下,对此类信息内容实施监督和管理。
          （五）本系统与大多数互联网公司提供的系统与软件服务是类似的，受包括但不限于通讯环境、网络服务质量、社会环境、第三方资料获取等因素的影响，可能受到各种安全问题的侵扰，您需要加强信息安全的自我保护意识，要注意加强密码保护，以免遭受损失。
          （六）您需要我们提供定制服务时，本系统的相关服务可能会使用第三方系统或软件的相关服务支持您的使用，该结果由服务方提供，因此您除了需要遵守本协议项下的条款下，还应遵守第三方的协议以及相关使用规则。您须理解并同意，在使用第三方服务时，您的使用数据会被第三方读取，我们无法保证通过第三方系统或软件产生结果的安全性、准确性、有效性，亦无法对其他未知风险作出保证，您需要审慎判断，若因此引发的任何争议及损害，我们无法承担该类责任。
          （七）若您因以下情况所引发的有关人身伤害或者是直接的、间接的经济损害赔偿，包括但不限于金钱损失、业务中断的相关损害赔偿或者是其他的损害赔偿，还需您自行承担：
          1、使用未被许可授权的软件；
          2、您对第三方软件的错误使用；
          3、非因本系统的原因引起的其他损失。
          （八）您知晓且同意我们有权基于司法、监管部门、监督机构的要求，暂停、中断或者是终止向您提供全部或者部分相关服务，对此我们无法承担该类责任。
          （九）您同意并理解，我们将会尽技术及商业中最大努力保障您在本系统中的数据存储安全，您须理解，我们无法对第三方、用户个人原因或是不可抗力原因产生的数据删除、储存、备份的失败承担责任。</b>
      </pre>
      <div class="title2">九、知识产权声明</div>
      <pre>
          （一）除第三方产品或服务外，本系统中所有内容，包括但不限于著作、图片、档案、资讯、资料、架构、页面设计，均由本公司或本公司关联公司依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。
          （二）您理解并知晓非经我们的书面同意，任何人不得擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表本系统上程序或内容。
          （三）尊重知识产权是我们与您应尽的义务，如有违反，双方依据法律规定各自承担风险及责任。
      </pre>
      <div class="title2">十、未成年人使用条款</div>
      <pre>
          <b>未达国家法定年龄的未成年人，应在监护人监护、指导并获得监护人同意情况下阅读本协议和使用本系统。青少年使用本系统应在合理范围内正确合理的使用网络，养成良好上网习惯，遵守《全国青少年网络文明公约》。</b>
      </pre>
      <div class="title2">十一、法律适用与管辖</div>
      <pre>
          本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律，如无相关法律规定的，则应参照通用国际商业惯例和（或）行业惯例。本协议由您与本公司于本公司住所地：陕西省西安市高新区签署。因本协议产生或与本协议有关的任何争议，您与本公司应先友好协商解决，协商不成的，您与本公司均有权向协议签署地有管辖权的人民法院诉讼，通过诉讼解决。
      </pre>
      <div class="title2">十二、如何联系我们</div>
      <pre>
          如果您对本系统的使用有任何疑问、意见、建议或投诉，请发送邮件至【supportab@126.com】与我们联系。
          在一般情况下，我们会在15个工作日内对您的请求予以答复。
      </pre>
      <div class="title2">十三、其他</div>
      <pre>
          （一）我们的用户协议在国家法律、法规、规章及制度变更或是公司业务方向作调整以及其他必要的前提条件下可能进行变更或修改。未经您明确同意，我们不会限制您按照本用户协议所应享有的权利。
          对于本用户协议的重大变更或修改，我们还会提供显著的通知（例如，在系统版本升级或您重新登录时，以弹窗形式对您进行及时通知）。本协议所指的重大变更包括但不限于：我们的服务模式发生重大变化；我们在控制权等方面发生重大变化，如并购重组等引起的所有者变更等；法律法规的修改或是其他同类事项。
          （二）关于个性化广告推送：给予您优质的用户体验是我们一直坚守的原则，因此我们拒绝了众多恶意广告、骚扰广告，并期望根据您的喜好为您提供更多实用的广告内容。同时，我们了解有些用户不希望收到依其兴趣或浏览记录投放显示的广告。您可以通过系统设置（系统设置-应用和权限-应用权限管理）关闭对于位置信息的收集。如您拒绝提供位置信息仅会使您无法享受基于位置的广告推荐，不会影响您正常使用本款产品的其他功能。
          （三）本协议的所有标题仅是为了醒目及阅读方便，本身并没有实际涵义，不能作为解释本协议涵义的依据。
          （四）我们与关联方均享有、保留对系统的所有权利和使用的资格，并保留本协议内未明确授予您的其他权利。
          （五）本协议各条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方均具有约束力。
      </pre>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/footer";
export default {
  components: {
    Footer
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
pre {
  white-space: pre-line;
  margin-top: 0;
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 28px;
}
.header {
  width: 1160px;
  margin: 0 auto;
  img {
    padding: 22px 0;
  }
  a {
    display: inline-block;
  }
}
.text-content {
  width: 760px;
  margin: 0 auto;
  color: #1f1f1f;
  .title {
    font-size: 48px;
    text-align: center;
    padding-top: 80px;
    padding-bottom: 60px;
  }
  .title2 {
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 10px;
  }
  .date {
    color: #a4b9cf;
    font-size: 14px;
    text-align: right;
    line-height: 28px;
    padding-bottom: 20px;
  }
}
</style>
